<template>
  <div>
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">
        <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
          <div class="me-7 mb-4">
            <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
              <img :src="user.avatar ? user.avatar : '/assets/media/avatars/blank.png'" alt="image" referrerpolicy="no-referrer" style="object-fit: cover;"/>
              <div class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"
                  data-bs-toggle="tooltip" data-bs-custom-class="tooltip-dark" data-bs-trigger="hover" data-bs-offset="0,5" data-bs-dismiss-="click" 
                  :title="user.availability_status">
              </div>
            </div>
          </div>
          <div class="flex-grow-1">
            <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center mb-2">
                  <a href="#" class="text-gray-900 text-hover-primary fs-2 fw-bold me-1">{{ user.first_name }} {{ user.last_name }}</a>
                  <a href="#" class="btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3" v-if="user.role">{{ user.role.name }}</a>
                </div>
                <div class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                  <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2" v-if="user.positions.length > 0">
                  <span class="svg-icon svg-icon-4 me-1" v-if="user.positions.length > 0">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z" fill="currentColor" />
                      <path d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z" fill="currentColor" />
                      <rect x="7" y="6" width="4" height="4" rx="2" fill="currentColor" />
                    </svg>
                  </span>{{ user.positions.filter(x => x.position).map( x => x.position.title ).join(', ') }}</a>
                  <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                  <span class="svg-icon svg-icon-4 me-1" v-if="user.address">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z" fill="currentColor" />
                      <path d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z" fill="currentColor" />
                    </svg>
                  </span>{{ user.address }}</a>
                  <a href="#" class="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                  <span class="svg-icon svg-icon-4 me-1">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor" />
                      <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor" />
                    </svg>
                  </span>{{ user.email }}</a>
                </div>
              </div>
            </div>
            <!-- <div class="d-flex flex-wrap flex-stack">
              <div class="d-flex flex-column flex-grow-1 pe-8">
                <div class="d-flex flex-wrap">
                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div class="d-flex align-items-center">
                      <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                          <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
                        </svg>
                      </span>
                      <div class="fs-2 fw-bold" data-kt-countup="true" data-kt-countup-value="4500" data-kt-countup-prefix="$">0</div>
                    </div>
                    <div class="fw-semibold fs-6 text-gray-400">Earnings</div>
                  </div>
                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div class="d-flex align-items-center">
                      <span class="svg-icon svg-icon-3 svg-icon-danger me-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor" />
                          <path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="currentColor" />
                        </svg>
                      </span>
                      <div class="fs-2 fw-bold" data-kt-countup="true" data-kt-countup-value="75">0</div>
                    </div>
                    <div class="fw-semibold fs-6 text-gray-400">Projects</div>
                  </div>
                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div class="d-flex align-items-center">
                      <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                          <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
                        </svg>
                      </span>
                      <div class="fs-2 fw-bold" data-kt-countup="true" data-kt-countup-value="60" data-kt-countup-prefix="%">0</div>
                    </div>
                    <div class="fw-semibold fs-6 text-gray-400">Success Rate</div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
          <li class="nav-item mt-2">
            <router-link to="/profile/overview" class="nav-link text-active-primary ms-0 me-10 py-5 cursor-pointer" :class="{ active: $route.params.tab == 'overview' }">
              Overview
            </router-link>
          </li>
          <li class="nav-item mt-2" @click="getMe()">
            <router-link to="/profile/setting" class="nav-link text-active-primary ms-0 me-10 py-5 cursor-pointer" :class="{ active: $route.params.tab == 'setting' }">
              Settings
            </router-link>
          </li>
          <li class="nav-item mt-2">
            <router-link to="/profile/statement" class="nav-link text-active-primary ms-0 me-10 py-5 cursor-pointer" :class="{ active: $route.params.tab == 'statement' }">
              Statements
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="card mb-5 mb-xl-10" v-if="$route.params.tab == 'overview'">
      <div class="card-header cursor-pointer">
        <div class="card-title m-0">
          <h3 class="fw-bold m-0">Profile Details</h3>
        </div>
        <router-link to="/profile/setting" class="btn btn-sm btn-primary align-self-center">Edit Profile</router-link>
      </div>
      <div class="card-body p-9">
        <div class="row mb-7">
          <label class="col-lg-4 fw-semibold text-muted">NIK</label>
          <div class="col-lg-8">
            <span class="fw-bold fs-6 text-gray-800">{{ user.nik }}</span>
          </div>
        </div>
        <div class="row mb-7">
          <label class="col-lg-4 fw-semibold text-muted">Full Name</label>
          <div class="col-lg-8">
            <span class="fw-bold fs-6 text-gray-800">{{ user.first_name }} {{ user.last_name }}</span>
          </div>
        </div>
        <div class="row mb-7">
          <label class="col-lg-4 fw-semibold text-muted">Email</label>
          <div class="col-lg-8">
            <span class="fw-bold fs-6 text-gray-800">{{ user.email }}</span>
          </div>
        </div>
        <div class="row mb-7">
          <label class="col-lg-4 fw-semibold text-muted">Phone Number</label>
          <div class="col-lg-8">
            <span class="fw-bold fs-6 text-gray-800">{{ user.phone_number }}</span>
          </div>
        </div>
        <div class="row mb-7">
          <label class="col-lg-4 fw-semibold text-muted">Address</label>
          <div class="col-lg-8">
            <span class="fw-bold fs-6 text-gray-800">{{ user.address }}</span>
          </div>
        </div>
        <div class="row mb-7">
          <label class="col-lg-4 fw-semibold text-muted">Role</label>
          <div class="col-lg-8">
            <span class="fw-bold fs-6 text-gray-800">{{ user.role ? user.role.name : '-' }}</span>
          </div>
        </div>
        <div class="row mb-7">
          <label class="col-lg-4 fw-semibold text-muted">Positions</label>
          <div class="col-lg-8">
            <span class="fw-bold fs-6 text-gray-800">{{ user.positions.length > 0 ? user.positions.filter( x => x.position ).map( x => x.position.title ).join(', ') : '-' }}</span>
          </div>
        </div>
        <div class="row mb-7">
          <label class="col-lg-4 fw-semibold text-muted">Joined Date</label>
          <div class="col-lg-8">
            <span class="fw-bold fs-6 text-gray-800">{{ user.join_date | moment('DD MMM YYYY') }}</span>
          </div>
        </div>
        <div class="row mb-7">
          <label class="col-lg-4 fw-semibold text-muted">Last Active</label>
          <div class="col-lg-8">
            <span class="fw-bold fs-6 text-gray-800" v-if="user.tokens">{{ user.tokens.last_used_at | moment('DD MMM YYYY, hh:mm a') }}</span>
            <span class="fw-bold fs-6 text-gray-800" v-else>-</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-5 mb-xl-10" v-if="$route.params.tab == 'setting'">
      <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
        <div class="card-title m-0">
          <h3 class="fw-bold m-0">Profile Details</h3>
        </div>
      </div>
      <div id="kt_account_settings_profile_details" class="collapse show">
        <ValidationObserver ref="observer">
          <form class="form" @submit.prevent="updateUser()">
            <div class="card-body border-top p-9">
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label fw-semibold fs-6">Avatar</label>
                <div class="col-lg-8">
                  <ValidationProvider name="Avatar" v-slot="{ errors }" :rules="{ }" ref="avatar">
                    <div class="image-input image-input-outline image-input-placeholder" data-kt-image-input="true">
                      <div class="image-input-wrapper w-125px h-125px" :style="`background-image: url(${request.avatar ? request.avatar : '/assets/media/avatars/blank.png'}); object-fit: cover; background-position:center;`"></div>
                      <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change image">
                        <i class="bi bi-pencil-fill fs-7"></i>
                        <input type="file" class="hidden" id="upload" v-on:change="handleUpload" :accept="'.' + allowedExtension.join(', .').toLowerCase()">
                        <input type="hidden" name="avatar_remove" />
                      </label>
                      <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove image" 
                        v-if="request.avatar" @click="request.avatar = null">
                        <i class="bi bi-x fs-2"></i>
                      </span>
                    </div>
                    <div class="form-text">Maximum file size: {{ maxFileSize }} Mb. ({{ '.' + allowedExtension.join(' .').toUpperCase() }}).</div>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-semibold fs-6">Full Name</label>
                <div class="col-lg-8 fv-row">
                  <ValidationProvider name="Full name" v-slot="{ errors }" :rules="{ required: true }" ref="full_name">
                    <input type="text" class="form-control form-control-lg form-control-solid" v-model="request.full_name" />
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-semibold fs-6">Email</label>
                <div class="col-lg-8 fv-row">
                  <ValidationProvider name="Email" v-slot="{ errors }" :rules="{ required: true, email: true }" ref="email">
                    <input type="text" class="form-control form-control-lg form-control-solid" v-model="request.email" disabled/>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-semibold fs-6">Phone Number</label>
                <div class="col-lg-8 fv-row">
                  <ValidationProvider name="Phone number" v-slot="{ errors }" :rules="{ required: true, min: 8, max: 15 }" ref="phone_number">
                    <input type="text" class="form-control form-control-lg form-control-solid" v-model="request.phone_number"  @keypress="numbersOnly($event)" />
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label fw-semibold fs-6">Address</label>
                <div class="col-lg-8 fv-row">
                  <ValidationProvider name="Address" v-slot="{ errors }" :rules="{ }" ref="address">
                    <textarea class="form-control form-control-lg form-control-solid" name="" id="" cols="30" rows="5" v-model="request.address"></textarea>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label fw-semibold fs-6 required">Joined Date</label>
                <div class="col-lg-8 fv-row">
                  <ValidationProvider name="Joined date" v-slot="{ errors }" :rules="{ required: true }" ref="join_date">
                    <date-picker class="form-control form-control-solid" v-model="request.join_date" :config="joinDatepickerOptions" placeholder="Pick a date"></date-picker>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <button class="btn btn-light btn-active-light-primary me-2" @click="$router.push('/profile/overview')">Discard</button>
              <button type="submit" class="btn btn-primary">
                <span class="indicator-label" v-if="!loading">Save Changes</span>
                <span class="indicator-progress" v-else>
                  Please wait...
                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <div class="card mb-5 mb-xl-10" v-if="$route.params.tab == 'statement'">
      <div class="card-header card-header-stretch">
        <div class="card-title">
          <h3 class="m-0 text-gray-800">Statement</h3>
        </div>
        <div class="card-toolbar m-0">
          <ul class="nav nav-stretch fs-5 fw-semibold nav-line-tabs border-transparent" role="tablist">
            <li class="nav-item" role="presentation">
              <a id="kt_referrals_year_tab" class="nav-link text-active-gray-800 active" data-bs-toggle="tab" role="tab" href="#kt_referrals_1">This Year</a>
            </li>
            <li class="nav-item" role="presentation">
              <a id="kt_referrals_2019_tab" class="nav-link text-active-gray-800 me-4" data-bs-toggle="tab" role="tab" href="#kt_referrals_2">2021</a>
            </li>
            <li class="nav-item" role="presentation">
              <a id="kt_referrals_2018_tab" class="nav-link text-active-gray-800 me-4" data-bs-toggle="tab" role="tab" href="#kt_referrals_3">2020</a>
            </li>
            <li class="nav-item" role="presentation">
              <a id="kt_referrals_2017_tab" class="nav-link text-active-gray-800 ms-8" data-bs-toggle="tab" role="tab" href="#kt_referrals_4">2019</a>
            </li>
          </ul>
        </div>
      </div>
      <div id="kt_referred_users_tab_content" class="tab-content">
        <div id="kt_referrals_1" class="card-body p-0 tab-pane fade show active" role="tabpanel">
          <UnderConstruction />
        </div>
        <div id="kt_referrals_2" class="card-body p-0 tab-pane fade" role="tabpanel">
          <UnderConstruction />
        </div>
        <div id="kt_referrals_3" class="card-body p-0 tab-pane fade" role="tabpanel">
          <UnderConstruction />
        </div>
        <div id="kt_referrals_4" class="card-body p-0 tab-pane fade" role="tabpanel">
          <UnderConstruction />
          <!-- <div class="table-responsive">
            <table class="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9">
              <thead class="border-gray-200 fs-5 fw-semibold bg-lighten">
                <tr>
                  <th class="min-w-175px ps-9">Date</th>
                  <th class="min-w-150px px-0">Order ID</th>
                  <th class="min-w-350px">Details</th>
                  <th class="min-w-125px">Amount</th>
                  <th class="min-w-125px text-center">Invoice</th>
                </tr>
              </thead>
              <tbody class="fs-6 fw-semibold text-gray-600">
                <tr>
                  <td class="ps-9">Nov 01, 2020</td>
                  <td class="ps-0">102445788</td>
                  <td>Darknight transparency 36 Icons Pack</td>
                  <td class="text-success">$38.00</td>
                  <td class="text-center">
                    <button class="btn btn-light btn-sm btn-active-light-primary">Download</button>
                  </td>
                </tr>
                <tr>
                  <td class="ps-9">Oct 24, 2020</td>
                  <td class="ps-0">423445721</td>
                  <td>Seller Fee</td>
                  <td class="text-danger">$-2.60</td>
                  <td class="text-center">
                    <button class="btn btn-light btn-sm btn-active-light-primary">Download</button>
                  </td>
                </tr>
                <tr>
                  <td class="ps-9">Nov 01, 2020</td>
                  <td class="ps-0">102445788</td>
                  <td>Darknight transparency 36 Icons Pack</td>
                  <td class="text-success">$38.00</td>
                  <td class="text-center">
                    <button class="btn btn-light btn-sm btn-active-light-primary">Download</button>
                  </td>
                </tr>
                <tr>
                  <td class="ps-9">Oct 24, 2020</td>
                  <td class="ps-0">423445721</td>
                  <td>Seller Fee</td>
                  <td class="text-danger">$-2.60</td>
                  <td class="text-center">
                    <button class="btn btn-light btn-sm btn-active-light-primary">Download</button>
                  </td>
                </tr>
                <tr>
                  <td class="ps-9">Feb 09, 2020</td>
                  <td class="ps-0">426445943</td>
                  <td>Visual Design Illustration</td>
                  <td class="text-success">$31.00</td>
                  <td class="text-center">
                    <button class="btn btn-light btn-sm btn-active-light-primary">Download</button>
                  </td>
                </tr>
                <tr>
                  <td class="ps-9">Nov 01, 2020</td>
                  <td class="ps-0">984445943</td>
                  <td>Abstract Vusial Pack</td>
                  <td class="text-success">$52.00</td>
                  <td class="text-center">
                    <button class="btn btn-light btn-sm btn-active-light-primary">Download</button>
                  </td>
                </tr>
                <tr>
                  <td class="ps-9">Jan 04, 2020</td>
                  <td class="ps-0">324442313</td>
                  <td>Seller Fee</td>
                  <td class="text-danger">$-0.80</td>
                  <td class="text-center">
                    <button class="btn btn-light btn-sm btn-active-light-primary">Download</button>
                  </td>
                </tr>
                <tr>
                  <td class="ps-9">Oct 08, 2020</td>
                  <td class="ps-0">312445984</td>
                  <td>Cartoon Mobile Emoji Phone Pack</td>
                  <td class="text-success">$76.00</td>
                  <td class="text-center">
                    <button class="btn btn-light btn-sm btn-active-light-primary">Download</button>
                  </td>
                </tr>
                <tr>
                  <td class="ps-9">Oct 08, 2020</td>
                  <td class="ps-0">312445984</td>
                  <td>Cartoon Mobile Emoji Phone Pack</td>
                  <td class="text-success">$76.00</td>
                  <td class="text-center">
                    <button class="btn btn-light btn-sm btn-active-light-primary">Download</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UnderConstruction from '@/components/UnderConstruction.vue'
export default {
  components: {
    UnderConstruction
  },
  data() {
    return {
      user: {
        positions: []
      },
      request: {
        full_name: null,
        role_id: null,
        phone_number: null,
        positions: [],
        address: null,
        email: null,
        // password: null,
        // password_confirmation: null,
        join_date: null,
        // skill: null,
        avatar: null,
      },
      loading: false,
      maxFileSize: 2,
      allowedExtension: ['jpg', 'jpeg', 'png'],
      joinDatepickerOptions: {
        maxDate: this.$moment().format('YYYY-MM-DD'),
        format: 'YYYY-MM-DD',
        useCurrent: true,
      },
    }
  },
  computed: {
    requestBody: function() {
      let _ = this
      return {
        full_name: _.request.full_name,
        role_id: _.request.role_id,
        phone_number: _.request.phone_number,
        positions: _.request.positions,
        address: _.request.address,
        email: _.request.email,
        join_date: _.request.join_date,
        avatar: _.request.avatar,
      }
    }
  },
  methods: {
    getMe(){
      let _ = this
      _.axios.get('/auth/me')
        .then( resp => {
          _.user = resp.data.data

          _.request = {
            ..._.user,
            full_name: _.user.first_name + ' ' + _.user.last_name,
            positions: _.user.positions.map(x => x.position_id)
          }

          _.$store.dispatch('update_user', _.user)
          _.$root.$emit('updateProfile')

          setTimeout(() => {
            KTToggle.createInstances();
            KTMenu.createInstances();
            $(document).ready(function() {
              $('[data-bs-toggle="tooltip"]').tooltip();
            });
          }, 500);
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    async handleUpload(event){
      let _ = this
      let file = event.target.files[0]

      const uploadedFile = await _.upload( file, _.allowedExtension, _.maxFileSize, 'avatar' )

      if ( uploadedFile ) {
        _.request.avatar = _.$apiBaseUrl + '/storage/' + uploadedFile
      }
    },
    async updateUser() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      _.loading = true
      _.axios.put('/v1/user/' + _.user.id, _.requestBody)
        .then(() => {
          _.loading = false
          _.getMe()
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully edit profile',
            text: `You have successfully edit profile`
          })
        })
        .catch( err => {
          if ( typeof err.response.data.errors === 'object' && err.response.data.errors !== null ) {
            Object.keys(err.response.data.errors).forEach(elm => {
              _.$refs[elm].applyResult({
                errors: [err.response.data.errors[elm][0]],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
          _.loading = false
        })
    },
    async reset() {
      let _ = this
      _.request = {
        ..._.user,
        full_name: _.user.first_name + ' ' + _.user.last_name,
        positions: _.user.positions.map(x => x.position_id)
      }
      await this.$refs.observer.reset()
    },
  },
  mounted() {
    let _ = this

    _.getMe()

    $("#kt_daterangepicker_3").daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        maxDate: _.$moment(),
        autoApply: true
      }, function(start, end, label) {
        _.request.join_date = _.$moment(start).format('YYYY-MM-DD')
      });
  },
}
</script>