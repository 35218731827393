<template>
  <div>
    <div class="row">
      <div class="col-4">
        <div class="card">
          <div class="card-header mt-6">
            <div class="card-title flex-column">
              <h3 class="fw-bold mb-1">Latest Files</h3>
              <div class="fs-6 text-gray-400">Total {{ table.data.length }} files</div>
              <a :href="'https://drive.google.com/drive/u/0/folders/' + project.files_folder" target="_blank" class="fs-8 mb-2">Open Drive</a>
            </div>
            <div class="card-toolbar align-self-start">
              <button @click="removeAllFileFromQueues()" type="button" data-bs-toggle="modal" data-bs-target="#kt_modal_upload" class="btn btn-primary btn-sm"><span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"></path><path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z" fill="currentColor"></path><path opacity="0.3" d="M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z" fill="currentColor"></path></svg></span>Upload Files</button>
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex align-items-center mb-5" 
              v-for="document in table.data" :key="document.id">
              <div class="symbol symbol-30px me-5">
                <img alt="Icon" src="/icon/doc.png" v-if="['doc', 'docx'].includes(getFileExtension(document.title))">
                <img alt="Icon" src="/icon/ppt.png" v-else-if="['ppt', 'pptx'].includes(getFileExtension(document.title))">
                <img alt="Icon" src="/icon/xls.png" v-else-if="['xls', 'xlsx'].includes(getFileExtension(document.title))">
                <img alt="Icon" src="/icon/pdf.png" v-else-if="['pdf'].includes(getFileExtension(document.title))">
                <img alt="Icon" src="/icon/file.png" v-else>
              </div>
              <div class="fw-semibold" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                <a class="fs-6 fw-bold text-dark text-hover-primary" href="#" @click="activeDocument = document">{{ document.title }}</a>
                <div class="text-gray-400">{{ diffForHuman(document.created_at) }}
                  by <a href="#">{{ document.lastModifiedBy }}</a>
                </div>
              </div>
              <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto" @click="deleteFile(document)">
                <span class="svg-icon svg-icon-3"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path><path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path><path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path></svg></span>
              </button>
            </div>

            <div class="d-flex flex-column flex-center" v-if="table.data.length == 0">
              <img src="/assets/media/illustrations/sigma-1/21.png" class="mw-250px">
              <div class="fw-bolder text-dark mb-4">No Files Found.</div>
              <div class="fs-8 text-center">Start uploading new files!</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="card">
          <div v-if="activeDocument">
            <div class="card-header">
              <div class="card-title flex-column w-100">
                <div class="d-flex align-items-center w-100 justify-content-between">
                  <div style="overflow-wrap: anywhere;">
                    <h3 class="fw-bold mb-1">{{ activeDocument.title }}</h3>
                    <div class="fs-6 text-gray-400">Last modified at {{ activeDocument.created_at | moment('LLL') }} by <a href="#">{{ activeDocument.lastModifiedBy }}</a></div>
                  </div>
                  <div style="white-space: nowrap;">
                    <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto" @click="copy(activeDocument.file_link)"
                      data-bs-toggle="tooltip" title="Copy to clipboard">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.5" d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z" fill="currentColor"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z" fill="currentColor"/>
                      </svg>
                    </button>
                    <a :href="activeDocument.file_link" target="_blank" class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto" data-bs-toggle="tooltip" title="Open on new tab">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z" fill="currentColor"/>
                        <rect x="21.9497" y="3.46448" width="13" height="2" rx="1" transform="rotate(135 21.9497 3.46448)" fill="currentColor"/>
                        <path d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z" fill="currentColor"/>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center py-10" v-if="iframeLoading">
              <span class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </span>
            </div>

            <iframe id="myIframe" 
              v-if="['docx', 'pptx', 'xlsx', 'doc', 'ppt', 'drawing'].includes(getFileExtension(activeDocument.title))"
              :src="activeDocument.file_link" 
              width="100%" 
              :height="iframeLoading ? '0px' : '865px'">
            </iframe>
            <div class="d-flex flex-column flex-center" v-else>
              <img src="/assets/media/illustrations/sigma-1/20.png" class="img-fluid theme-light-show col-5">
              <img src="/assets/media/illustrations/sigma-1/20-dark.png" class="img-fluid theme-dark-show col-5">
              <div class="fs-1 fw-bolder text-dark mb-4">File extension not supported.</div>
              <div class="fs-6 mb-4 text-center">Supported extensions are .docx, .pptx, .xlsx., .drawing<br>
                <a :href="activeDocument.file_link" target="_blank">Click here </a>to download
              </div>
            </div>
          </div>
          <div class="card-body" v-else>
            <div class="d-flex flex-column flex-center">
              <img src="/assets/media/illustrations/sigma-1/15.png" class="img-fluid theme-light-show col-5">
              <img src="/assets/media/illustrations/sigma-1/15-dark.png" class="img-fluid theme-dark-show col-5">
              <div class="fs-1 fw-bolder text-dark mb-4">No File Selected.</div>
              <div class="fs-6 mb-4">Please select one of files</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="kt_modal_upload" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
          <form class="form" action="none" id="kt_modal_upload_form">
            <div class="modal-header">
              <h2 class="fw-bold">Upload files</h2>
              <div class="btn btn-icon btn-sm btn-active-icon-primary" id="closeModalUpload" data-bs-dismiss="modal">
                <span class="svg-icon svg-icon-1">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                  </svg>
                </span>
              </div>
            </div>
            <div class="modal-body pt-10 pb-15 px-lg-17">
              <div class="form-group">
                <div class="dropzone dropzone-queue mb-2">
                  <div class="mb-4">
                    <input type="file" class="d-none" id="attachFile" multiple v-on:change="addToQueue">
                    <label for="attachFile">
                      <a class="btn btn-sm btn-primary me-2">Attach files</a>
                    </label>
                    <button class="btn btn-sm btn-light-primary me-2" v-if="fileQueues.filter( x => !x.isUploaded).length > 0" @click="uploadAllFileFromQueues()" :disabled="loading">Upload All</button>
                    <button class="btn btn-sm btn-light-primary" v-if="fileQueues.filter( x => !x.isUploaded).length > 0" @click="removeAllFileFromQueues()" :disabled="loading">Remove All</button>
                  </div>
                  <div class="dropzone-items wm-200px">
                    <div class="dropzone-item p-5" v-for="(queue, index) in fileQueues.filter( x => !x.isUploaded)" :key="index">
                      <div class="dropzone-file">
                        <div class="dropzone-filename text-dark" :title="queue.file.name" style="overflow-wrap: anywhere;">
                          <span>{{ queue.file.name }}</span>
                          <strong> ({{formatBytes(queue.file.size)}})</strong>
                        </div>
                        <div class="dropzone-error mt-0" v-if="!queue.isValid">{{queue.invalidMessage}}</div>
                      </div>
                      <div class="dropzone-progress" v-if="queue.isValid && queue.progress < 100">
                        <div class="progress bg-light-primary">
                          <div class="progress-bar bg-primary" role="progressbar" aria-valuemin="0" aria-valuemax="100" :aria-valuenow="0" :style="'width: '+ queue.progress +'%'"></div>
                        </div>
                      </div>
                      <div class="dropzone-toolbar">
                        <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto" v-if="queue.isValid && queue.progress < 100" @click="uploadFile(queue.id)" :disabled="loading">
                          <i class="bi bi-play-fill fs-3"></i>
                        </button>
                        <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto" v-if="queue.progress == 0 || !queue.isValid" @click="removeFileFromQueues(queue.id)" :disabled="loading">
                          <i class="bi bi-x fs-1"></i>
                        </button>
                        <span class="dropzone-check" v-if="queue.progress == 100">
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="form-text fs-6 text-muted">Max file size is {{ formatBytes(maxFileSize, 0) }} per file.</span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueDocPreview from 'vue-doc-preview'
export default {
  components: {
    VueDocPreview
  },
  props: [
    'project'
  ],
  data() {
    return {
      table: {
        data: [],
        meta: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
          total: 0,
        },
        params: {
          search: '',
          sort_by: 'updated_at.DESC',
        }
      },
      activeDocument: null,
      fileQueues: [],
      maxFileSize: 5000000,
      iframeLoading: false,
      loading: false
    }
  },
  methods: {
    get() {
      let _ = this
      _.axios.get('/v1/setting/drive/list/' + _.project.files_folder)
        .then( resp => {
          if ( resp.data.data ) {
            _.table.data = resp.data.data.map( x => {
              return {
                id: x.id,
                title: x.mimeType == 'application/vnd.google-apps.drawing' ? x.name + '.drawing' : x.name,
                file_link: x.webViewLink,
                created_at: x.modifiedTime,
                lastModifiedBy: x.lastModifyingUser.displayName,
              }
            })
          } else {
            _.table.data = []
          }
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    async addToQueue(event){
      let _ = this
      let files = event.target.files
      let fileId = _.fileQueues.length

      Array.from(files).forEach(file => {
        const isValid = file.size <= _.maxFileSize
        let invalidMessage = null
        
        if ( !isValid ) {
          invalidMessage = `File is too big (${_.formatBytes(file.size)}). Max filesize: ${_.formatBytes(_.maxFileSize, 0)}.`
        }
        
        _.fileQueues.push({
          id: fileId,
          isValid: isValid,
          invalidMessage: invalidMessage,
          progress: 0,
          file: file,
          isUploaded: false
        })

        fileId += 1
      });

      $("#attachFile").val(null);
    },
    removeFileFromQueues( fileId ) {
      let _ = this
      const searchFile = _.fileQueues.map( x => x.id ).indexOf( fileId )
      if ( searchFile != -1 ) {
        _.fileQueues.splice(searchFile, 1)
      }
    },
    removeAllFileFromQueues() {
      let _ = this
      _.fileQueues = []
    },
    async uploadFile( fileId, update = true ) {
      let _ = this
      
      const searchFile = _.fileQueues.map( x => x.id ).indexOf( fileId )
      const index = searchFile

      let formData = new FormData()
      formData.append('file', _.fileQueues[index].file)
      formData.append('parent', _.project.files_folder)

      _.loading = true
      await _.axios.post('/v1/setting/drive/upload-file', formData, 
        { 
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: function( progressEvent ) {
            const percent = ( progressEvent.loaded / progressEvent.total ) * 100
            _.fileQueues[index].progress = percent
            _.$forceUpdate()
          }.bind(_)
        })
        .then(() => {
          _.fileQueues[index].isUploaded = true
          _.$forceUpdate()

          if ( update == true ) {
            _.get()
          }

          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully add new file',
            text: `You have successfully add new file`
          })

          _.loading = false
          
          if (_.fileQueues.filter( x => x.isUploaded).length == _.fileQueues.length) {
            $('#closeModalUpload').click()
          }
        })
        .catch(err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
          _.loading = false
        })
    },
    async uploadAllFileFromQueues() {
      let _ = this
      let queues = _.fileQueues.filter( x => !x.isUploaded )
      for (let index = 0; index < queues.length; index++) {
        await _.uploadFile(queues[index].id, false)
      }
      await _.get()
    },
    deleteFile(data) {
      let _ = this
      _.$swal.fire({
        title: 'You are about to delete this file',
        text: 'Do you really want to delete this file? This process cannot be undone.',
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: 'No, cancel',
        confirmButtonText: 'Yes, sure',
        focusCancel: true
        }).then((result) => {
          if (result.value) {
            _.axios.delete(`/v1/setting/drive/delete/${data.id}`)
              .then(() => {
                _.$swalToast.fire({
                  icon: 'success',
                  title: 'Successfully delete file',
                  text: 'You have successfully delete this file'
                })

                _.get()

                if ( this.activeDocument.id == data.id ) {
                  this.activeDocument = null
                }
              })
              .catch(err=>{
                _.$swalToast.fire({
                  icon: 'error',
                  title: `Oops! Something went wrong (${err.response.status})`,
                  text: err.response.data.errors
                })
              })
          }
      })
    },
  },
  mounted() {
    let _ = this
    this.get()
  },
  watch: {
    activeDocument: function(){
      let _ = this 

      if ( ['docx', 'pptx', 'xlsx', 'doc', 'ppt', 'drawing'].includes(_.getFileExtension(_.activeDocument.title)) ) {
        _.iframeLoading = true

        $(document).ready(function() {
          $('[data-bs-toggle="tooltip"]').tooltip('dispose');
        });
        setTimeout(() => {
          $(document).ready(function() {
            $('[data-bs-toggle="tooltip"]').tooltip();
          });
        }, 100);

        setTimeout(() => {
          document.querySelector('iframe').onload = function(){
            _.iframeLoading = false
          };
        }, 100);
      }
    }
  }
}
</script>