import Vue from 'vue'

Vue.mixin({
  methods: {
    isAllowedExtension( filename, allowedExtension ) {
      let _ = this

      var fileExtension = filename.split('.').pop().toLowerCase();
      var isValidFile = false;

      for(var index in allowedExtension) {
        if(fileExtension === allowedExtension[index]) {
          isValidFile = true; 
          break;
        }
      }

      return isValidFile;
    },
    isAllowedFileSize( size, maxFileSize ) {
      if (size > ((1024 * 1024) * maxFileSize)) {
        return false
      }

      return true
    },
    async upload( file, allowedExtension = null, maxFileSize = null, refs = null  ){
      let _ = this

      if (allowedExtension && !_.isAllowedExtension(file.name, allowedExtension)) {
        if ( refs ) {
          this.$refs[refs].reset();
          this.$refs[refs].applyResult({
            errors: ['Allowed file extensions are : *.' + allowedExtension.join(', *.')],
            valid: false,
            failedRules: {}
          });
        }
        return;
      }

      if (maxFileSize && !_.isAllowedFileSize(file.size, maxFileSize)) {
        if ( refs ) {
          this.$refs[refs].reset();
          this.$refs[refs].applyResult({
            errors: ['File is too large, allowed maximum size is '+ maxFileSize + ' Mb'],
            valid: false,
            failedRules: {}
          });
        }
        return;
      }

      let formData = new FormData()
      formData.append('file', file)

      const res = await _.axios.post('/v1/misc/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' }})
        .then(resp => {
          return resp.data.data
        }).catch(err => {
          console.log(err.response)
        })

      return res
    },
    numbersOnly(evt) {
      evt = (evt) ? evt : window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
          evt.preventDefault()
      } else {
          return true
      }
    },
    async copy( val ) {
      await navigator.clipboard.writeText(val);
      this.$swalToast.fire({
        icon: 'success',
        title: `Successfully copied to clipboard`
      })
    },
    generateInitial( string ) {
      string = string.trim()
      if ( !string ) return ''
      var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();
      
      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    },
    hideModal(){
      $('.modal').modal('hide');
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
    },
    availabilityStatusColor( availability_status ) {
      if ( !availability_status ) {
        return 'success'
      }

      const statusSplit = availability_status.split(': ')
      const status = statusSplit[0]

      let color = '';
      switch (status) {
        case 'Available':
          color = 'success'
          break;
        case 'Working':
          color = 'primary'
          break;
        case 'Leave':
          color = 'danger'
          break;
        case 'Meeting':
          color = 'info'
          break;
        case 'Away':
          color = 'warning'
          break;
        case 'Offline':
          color = 'secondary'
          break;
      
        default:
          color = 'success'
          break;
      }
      return color;
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'
  
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  
      const i = Math.floor(Math.log(bytes) / Math.log(k))
  
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },    
    diffForHuman( date ) {
      const str = this.$moment(date).fromNow() + ''
      return this.capitalizeFirstLetter(str)
    },
    isToday( date ) {
      return this.$moment(date).format('YYYY-MM-DD') == this.$moment().format('YYYY-MM-DD')
    },
    generateInitialColor() {
      const classes = [
        'bg-primary text-inverse-primary',
        'bg-secondary text-inverse-secondary',
        'bg-success text-inverse-success',
        'bg-info text-inverse-info',
        'bg-warning text-inverse-warning',
        'bg-danger text-inverse-danger',
        'bg-dark text-inverse-dark',
      ]
      const random = Math.floor(Math.random() * classes.length)
      return classes[random]
    },
    getFileExtension( filename ) {
      return filename.split('.').pop().toLowerCase();
    },
    generateRandomBg() {
      const classes = [
        'bg-primary',
        'bg-secondary',
        'bg-success',
        'bg-info',
        'bg-warning',
        'bg-danger',
        'bg-dark',
      ]
      const random = Math.floor(Math.random() * classes.length)
      return classes[random]
    },
    projectStatusBadgeColor( status ){
      let classes = ''
      switch (status) {
        case 'Not Yet Started':
          classes = "badge-light-danger"
          break;
        case 'In Progress':
          classes = "badge-light-info"
          break;
        case 'On Hold':
          classes = "badge-light-warning"
          break;
        case 'Support':
          classes = "badge-light-success"
          break;
        case 'Completed':
          classes = "badge-light-primary"
          break;
      
        default:
          break;
      }
      return classes
    },
    projectStatusTooltipColor( status ){
      let classes = ''
      switch (status) {
        case 'Not Yet Started':
          classes = "bg-danger"
          break;
        case 'In Progress':
          classes = "bg-info"
          break;
        case 'On Hold':
          classes = "bg-warning"
          break;
        case 'Support':
          classes = "bg-success"
          break;
        case 'Completed':
          classes = "bg-primary"
          break;
      
        default:
          break;
      }
      return classes
    },
    removeHTMLStripTag( str ) {
      return str.replace(/<[^>]*>?/gm, '');
    },
    capitalize(string) {
      return string ? string[0].toUpperCase() + string.slice(1) : null;
    }  
  },
})