<template>
  <div>
    <div class="row g-6 g-xl-9">
      <div class="col-lg-6 col-xxl-4">
        <div class="card h-100">
          <div class="card-body p-9">
            <!-- <div class="fs-2hx fw-bold">{{ 99 | numFormat }}</div>
            <div class="fs-4 fw-semibold text-gray-400 mb-7">Current Projects</div>
            <div class="d-flex flex-wrap">
              <div class="d-flex flex-center h-100px w-100px me-9 mb-5">
                <canvas id="kt_project_list_chart"></canvas>
              </div>
              <div class="d-flex flex-column justify-content-center flex-row-fluid mb-5">
                <div class="d-flex fs-6 fw-semibold align-items-center mb-3">
                  <div class="bullet bg-danger me-3"></div>
                  <div class="text-gray-400">Not Yet Started</div>
                  <div class="ms-auto fw-bold text-gray-700">{{ 99 | numFormat }}</div>
                </div>
                <div class="d-flex fs-6 fw-semibold align-items-center mb-3">
                  <div class="bullet bg-info me-3"></div>
                  <div class="text-gray-400">In Progress</div>
                  <div class="ms-auto fw-bold text-gray-700">{{ 99 | numFormat }}</div>
                </div>
                <div class="d-flex fs-6 fw-semibold align-items-center mb-3">
                  <div class="bullet bg-warning me-3"></div>
                  <div class="text-gray-400">On Hold</div>
                  <div class="ms-auto fw-bold text-gray-700">{{ 99 | numFormat }}</div>
                </div>
                <div class="d-flex fs-6 fw-semibold align-items-center mb-3">
                  <div class="bullet bg-success me-3"></div>
                  <div class="text-gray-400">Support</div>
                  <div class="ms-auto fw-bold text-gray-700">{{ 99 | numFormat }}</div>
                </div>
                <div class="d-flex fs-6 fw-semibold align-items-center mb-3">
                  <div class="bullet bg-primary me-3"></div>
                  <div class="text-gray-400">Completed</div>
                  <div class="ms-auto fw-bold text-gray-700">{{ 99 | numFormat }}</div>
                </div>
              </div>
            </div> -->
            <UnderConstruction />
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-xxl-4">
        <div class="card h-100">
          <div class="card-body p-9">
            <UnderConstruction />
            <!-- <div class="fs-2hx fw-bold">$3,290.00</div>
            <div class="fs-4 fw-semibold text-gray-400 mb-7">Project Finance</div>
            <div class="fs-6 d-flex justify-content-between mb-4">
              <div class="fw-semibold">Avg. Project Budget</div>
              <div class="d-flex fw-bold">
              <span class="svg-icon svg-icon-3 me-1 svg-icon-success">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.4 10L5.3 18.1C4.9 18.5 4.9 19.1 5.3 19.5C5.7 19.9 6.29999 19.9 6.69999 19.5L14.8 11.4L13.4 10Z" fill="currentColor" />
                  <path opacity="0.3" d="M19.8 16.3L8.5 5H18.8C19.4 5 19.8 5.4 19.8 6V16.3Z" fill="currentColor" />
                </svg>
              </span>$6,570</div>
            </div>
            <div class="separator separator-dashed"></div>
            <div class="fs-6 d-flex justify-content-between my-4">
              <div class="fw-semibold">Lowest Project Check</div>
              <div class="d-flex fw-bold">
              <span class="svg-icon svg-icon-3 me-1 svg-icon-danger">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.4 14.8L5.3 6.69999C4.9 6.29999 4.9 5.7 5.3 5.3C5.7 4.9 6.29999 4.9 6.69999 5.3L14.8 13.4L13.4 14.8Z" fill="currentColor" />
                  <path opacity="0.3" d="M19.8 8.5L8.5 19.8H18.8C19.4 19.8 19.8 19.4 19.8 18.8V8.5Z" fill="currentColor" />
                </svg>
              </span>$408</div>
            </div>
            <div class="separator separator-dashed"></div>
            <div class="fs-6 d-flex justify-content-between mt-4">
              <div class="fw-semibold">Ambassador Page</div>
              <div class="d-flex fw-bold">
              <span class="svg-icon svg-icon-3 me-1 svg-icon-success">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.4 10L5.3 18.1C4.9 18.5 4.9 19.1 5.3 19.5C5.7 19.9 6.29999 19.9 6.69999 19.5L14.8 11.4L13.4 10Z" fill="currentColor" />
                  <path opacity="0.3" d="M19.8 16.3L8.5 5H18.8C19.4 5 19.8 5.4 19.8 6V16.3Z" fill="currentColor" />
                </svg>
              </span>$920</div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-xxl-4">
        <div class="card h-100">
          <div class="card-body p-9">
            <div class="fs-2hx fw-bold">{{ clients.length | numFormat }}</div>
            <div class="fs-4 fw-semibold text-gray-400 mb-7">Our Clients</div>
            <div class="symbol-group symbol-hover mb-9">
              <div class="symbol symbol-35px symbol-circle" 
                v-for="client in clients.slice(0, 8)" :key="client.id" data-bs-toggle="tooltip" :title="client.name">
                <span class="symbol-label fw-bold" :class="generateInitialColor()" v-if="!client.photo_link">{{ generateInitial(client.name )}}</span>
                <img :alt="client.name" :src="client.photo_link" v-else class="bg-white img-fluid" style="object-fit: cover;"/>
              </div>
              <router-link to="/project-management/client" v-if="clients.length > 8" class="symbol symbol-35px symbol-circle">
                <span class="symbol-label bg-dark text-gray-300 fs-8 fw-bold">+{{ clients.length - 8 }}</span>
              </router-link>
            </div>
            <div class="d-flex">
              <router-link to="/project-management/client" class="btn btn-primary btn-sm me-3">All Clients</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-wrap flex-stack my-5">
      <div class="d-flex align-items-center position-relative my-1">
        <span class="svg-icon svg-icon-1 position-absolute ms-4">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
            <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
          </svg>
        </span>
        <input type="text" class="form-control w-250px ps-14" placeholder="Search Project" 
          v-debounce:300.lock="handleSearch" v-model="table.params.search"/>

        <input type="checkbox" name="" id="showAssignedToMe" class="form-check-input ms-3 me-3 cursor-pointer" v-model="showAssignedToMe" @change="get()">
        <label for="showAssignedToMe" class="cursor-pointer">Only Assigned To Me</label>
      </div>
      <div class="d-flex flex-wrap my-1">
        <button type="button" class="btn btn-light-primary me-3" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
          <span class="svg-icon svg-icon-2">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor" />
            </svg>
          </span>
          Filter
        </button>
        <div class="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true">
          <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bold">Filter Options</div>
          </div>
          <div class="separator border-gray-200"></div>
          <div class="px-7 py-5" data-kt-user-table-filter="form">
            <div class="mb-3">
              <label class="form-label fs-6 fw-semibold">Category:</label>
              <multiselect 
                track-by="id"
                label="title"
                placeholder="Select category"
                v-model="table.params.tag_id" 
                :options="categories"
                :allow-empty="true"
                :close-on-select="true"
                :multiple="false"
                :searchable="true"
                :hide-selected="false"
                :showLabels="false"
                @input="get()"
              ></multiselect>
            </div>
            <div class="mb-3">
              <label class="form-label fs-6 fw-semibold">Framework:</label>
              <multiselect 
                track-by="id"
                label="title"
                placeholder="Select framework"
                v-model="table.params.technology_id" 
                :options="frameworks"
                :allow-empty="true"
                :close-on-select="true"
                :multiple="false"
                :searchable="true"
                :hide-selected="false"
                :showLabels="false"
                @input="get()"
              ></multiselect>
            </div>
            <div class="mb-3">
              <label class="form-label fs-6 fw-semibold">Phase:</label>
              <multiselect 
                track-by="id"
                label="name"
                placeholder="Select phase"
                v-model="table.params.phase_id" 
                :options="phases"
                :allow-empty="true"
                :close-on-select="true"
                :multiple="false"
                :searchable="true"
                :hide-selected="false"
                :showLabels="false"
                @input="get()"
              ></multiselect>
            </div>
            <div class="mb-10">
              <label class="form-label fs-6 fw-semibold">Status:</label>
              <multiselect 
                placeholder="Select Project Status"
                v-model="table.params.status" 
                :options="[
                  'Not Yet Started',
                  'In Progress',
                  'On Hold',
                  'Support',
                  'Completed',
                ]"
                :close-on-select="true"
                :multiple="false"
                :searchable="true"
                :showLabels="false"
                @input="get()"
              ></multiselect>
            </div>
            <div class="d-flex justify-content-end">
              <button type="reset" class="btn btn-light btn-active-light-primary fw-semibold me-2 px-6" data-kt-menu-dismiss="true" data-kt-user-table-filter="reset" 
                @click="
                  table.params.technology_id = null, 
                  table.params.tag_id = null, 
                  table.params.phase_id = null,
                  table.params.status = null,
                  get()">Reset</button>
              <!-- <button type="submit" class="btn btn-primary fw-semibold px-6" data-kt-menu-dismiss="true" data-kt-user-table-filter="filter" @click="get()">Apply</button> -->
            </div>
          </div>
        </div>
        <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app" id="buttonAddProject" @click="reset()">Add New Project</button>
      </div>
    </div>
    <div class="row g-6 g-xl-9" v-if="fetchingProjectList">
      <div class="col-md-6 col-xl-4" v-for="i in 3" :key="i">
        <ProjectSkeleton />
      </div>
    </div>
    <div class="row g-6 g-xl-9" v-else>
      <div class="col-md-6 col-xl-4" v-for="data in table.data" :key="data.id">
        <ProjectCard :data="data" />
      </div>
      <div class="d-flex flex-column flex-center" v-if="table.data.length == 0">
        <img src="/assets/media/illustrations/sigma-1/21.png" class="mw-400px">
        <div class="fs-1 fw-bolder text-dark mb-4">No projects found.</div>
        <div class="fs-6">Start creating new projects!</div>
      </div>
      <p class="text-center text-sm" v-if="table.meta.current_page < table.meta.last_page">
        <a class="color-gray-100 cursor-pointer" @click="changePage( table.meta.current_page + 1 )">
          Show more <span class="fa fa-arrow-down"></span>
        </a>
      </p>
    </div>

    <div class="modal fade" id="kt_modal_create_app" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h2>Add New Project</h2>
						<div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
							<span class="svg-icon svg-icon-1">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
									<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
								</svg>
							</span>
						</div>
					</div>
					<div class="modal-body py-lg-10 px-lg-10">
						<div class="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid" id="kt_modal_create_app_stepper">
							<div class="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
								<div class="stepper-nav ps-lg-10">
									<div class="stepper-item" :class="{ 'current': step == 1, 'completed': step > 1 }" data-kt-stepper-element="nav">
										<div class="stepper-wrapper">
											<div class="stepper-icon w-40px h-40px">
												<i class="stepper-check fas fa-check"></i>
												<span class="stepper-number">1</span>
											</div>
											<div class="stepper-label">
												<h3 class="stepper-title">Details</h3>
												<div class="stepper-desc">Name your project</div>
											</div>
										</div>
										<div class="stepper-line h-40px"></div>
									</div>
									<div class="stepper-item" :class="{ 'current': step == 2, 'completed': step > 2 }" data-kt-stepper-element="nav">
										<div class="stepper-wrapper">
											<div class="stepper-icon w-40px h-40px">
												<i class="stepper-check fas fa-check"></i>
												<span class="stepper-number">2</span>
											</div>
											<div class="stepper-label">
												<h3 class="stepper-title">Categories</h3>
												<div class="stepper-desc">Define your project categories</div>
											</div>
										</div>
										<div class="stepper-line h-40px"></div>
									</div>
									<div class="stepper-item" :class="{ 'current': step == 3, 'completed': step > 3 }" data-kt-stepper-element="nav">
										<div class="stepper-wrapper">
											<div class="stepper-icon w-40px h-40px">
												<i class="stepper-check fas fa-check"></i>
												<span class="stepper-number">3</span>
											</div>
											<div class="stepper-label">
												<h3 class="stepper-title">Frameworks</h3>
												<div class="stepper-desc">Select your project frameworks</div>
											</div>
										</div>
										<div class="stepper-line h-40px"></div>
									</div>
									<div class="stepper-item" :class="{ 'current': step == 4, 'completed': step > 4 }" data-kt-stepper-element="nav">
										<div class="stepper-wrapper">
											<div class="stepper-icon w-40px h-40px">
												<i class="stepper-check fas fa-check"></i>
												<span class="stepper-number">4</span>
											</div>
											<div class="stepper-label">
												<h3 class="stepper-title">Members</h3>
												<div class="stepper-desc">Pick several members</div>
											</div>
										</div>
										<div class="stepper-line h-40px"></div>
									</div>
									<div class="stepper-item" :class="{ 'current': step == 5, 'completed': step > 5 }" data-kt-stepper-element="nav">
										<div class="stepper-wrapper">
											<div class="stepper-icon w-40px h-40px">
												<i class="stepper-check fas fa-check"></i>
												<span class="stepper-number">5</span>
											</div>
											<div class="stepper-label">
												<h3 class="stepper-title">Completed</h3>
												<div class="stepper-desc">Review and Submit</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="flex-row-fluid px-lg-15">
								<ValidationObserver :ref="'observer-step-' + step">
                  <form class="form" @submit.prevent="changeToStep(step+1)">
                    <div :class="{ 'current': step == 1 }" data-kt-stepper-element="content" v-if="step == 1">
                      <div class="w-100">
                        <div class="fv-row mb-7">
                          <ValidationProvider name="Logo" v-slot="{ errors }" :rules="{ }" ref="logo">
                            <label class="d-block fw-semibold fs-6 mb-5">Logo</label>
                            <div class="image-input image-input-outline image-input-placeholder" data-kt-image-input="true">
                              <div class="image-input-wrapper w-125px h-125px" :style="`background-image: url(${request.logo ? $apiBaseUrl + '/storage/' + request.logo : '/assets/media/misc/image.png'}); background-position: center;`"></div>
                              <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change logo">
                                <i class="bi bi-pencil-fill fs-7"></i>
                                <input type="file" class="hidden" id="upload" v-on:change="handleUpload" :accept="'.' + allowedExtension.join(', .').toLowerCase()">
                                <input type="hidden" name="avatar_remove" />
                              </label>
                              <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove logo" 
                                v-if="request.logo" @click="request.logo = null">
                                <i class="bi bi-x fs-2"></i>
                              </span>
                            </div>
                            <div class="form-text">Maximum file size: {{ maxFileSize }} Mb. ({{ '.' + allowedExtension.join(' .').toUpperCase() }}).</div>
                            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                          </ValidationProvider>
                        </div>
                        <div class="fv-row mb-7">
                          <ValidationProvider name="Client" v-slot="{ errors }" :rules="{ required: true }" ref="client_id">
                            <label class="d-flex align-items-center fs-5 fw-semibold mb-2">
                              <span class="required">Client</span>
                            </label>
                            <multiselect 
                              track-by="id"
                              label="name"
                              placeholder="Select Client"
                              v-model="request.client_id" 
                              :options="clients"
                              :allow-empty="false"
                              :close-on-select="true"
                              :multiple="false"
                              :searchable="true"
                              :hide-selected="false"
                              :showLabels="false"
                            ></multiselect>
                            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                          </ValidationProvider>
                        </div>
                        <div class="fv-row mb-7">
                          <ValidationProvider name="Project name" v-slot="{ errors }" :rules="{ required: true }" ref="name">
                            <label class="d-flex align-items-center fs-5 fw-semibold mb-2">
                              <span class="required">Project Name</span>
                              <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Specify your unique project name"></i>
                            </label>
                            <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Project name" v-model="request.name"/>
                            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                          </ValidationProvider>
                        </div>
                        <div class="fv-row mb-7">
                          <ValidationProvider name="Project overview" v-slot="{ errors }" :rules="{ required: true }" ref="description">
                            <label class="d-flex align-items-center fs-5 fw-semibold mb-2">
                              <span class="required">Project Overview</span>
                            </label>
                            <textarea class="form-control form-control-lg form-control-solid" placeholder="Describe your project" value="" cols="30" rows="5" v-model="request.description"></textarea>
                            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                    <div :class="{ 'current': step == 2 }" data-kt-stepper-element="content" v-if="step == 2">
                      <div class="w-100">
                        <div class="fv-row">
                          <label class="d-flex align-items-center fs-5 fw-semibold mb-4">
                            <span class="required">Select Categories</span>
                            <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Define your project categories"></i>
                          </label>

                          <ValidationProvider name="Category" v-slot="{ errors }" :rules="{ required: true }" ref="tags">
                            <input type="hidden" v-model="request.tags">
                            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                          </ValidationProvider>

                          <div class="d-flex align-items-center position-relative my-1 mb-4">
                            <span class="svg-icon svg-icon-1 position-absolute ms-4">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
                              </svg>
                            </span>
                            <input type="text" class="form-control form-control-solid ps-14" placeholder="Search Category" v-model="searchCategory"/>
                          </div>

                          <div class="d-flex flex-wrap">
                            <button class="btn btn-light btn-active-light-primary btn-sm me-2 mb-4" title="Remove" 
                              v-for="tag in request.tags" :key="tag.id" @click="removeCategory(tag.id)">
                              {{ tag.title }} 
                              <span class="svg-icon svg-icon-6 me-0 align-self-center">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.3" d="M6.7 19.4L5.3 18C4.9 17.6 4.9 17 5.3 16.6L16.6 5.3C17 4.9 17.6 4.9 18 5.3L19.4 6.7C19.8 7.1 19.8 7.7 19.4 8.1L8.1 19.4C7.8 19.8 7.1 19.8 6.7 19.4Z" fill="currentColor"/>
                                  <path d="M19.5 18L18.1 19.4C17.7 19.8 17.1 19.8 16.7 19.4L5.40001 8.1C5.00001 7.7 5.00001 7.1 5.40001 6.7L6.80001 5.3C7.20001 4.9 7.80001 4.9 8.20001 5.3L19.5 16.6C19.9 16.9 19.9 17.6 19.5 18Z" fill="currentColor"/>
                                </svg>
                              </span>
                            </button>
                          </div>

                          <div class="scroll-y" data-kt-scroll="true" data-kt-scroll-activate="{default: true}" data-kt-scroll-max-height="auto" data-kt-scroll-offset="600px">
                            <label class="d-flex flex-stack cursor-pointer mb-5" v-for="category in modalCategories" :key="category.id">
                              <span class="d-flex align-items-center me-2">
                                <div class="symbol symbol-50px overflow-hidden me-6">
                                  <span class="symbol-label fw-bold" v-if="!category.logo">{{ generateInitial(category.title )}}</span>
                                  <img :alt="category.title" :src="category.logo_link" v-else class="bg-white img-fluid" 
                                    onerror="this.onerror=null;this.src='/assets/media/misc/image.png';" style="object-fit: cover;"/>
                                </div>
                                <span class="d-flex flex-column">
                                  <span class="fw-bold fs-6">{{ category.title }}</span>
                                  <span class="fs-7 text-muted">{{ category.description }}</span>
                                </span>
                              </span>
                              <span class="form-check form-check-custom form-check-solid">
                                <input class="form-check-input" type="checkbox" :value="category" v-model="request.tags"/>
                              </span>
                            </label>
                            <!-- TODO: PAGINATE -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div :class="{ 'current': step == 3 }" data-kt-stepper-element="content" v-if="step == 3">
                      <div class="w-100">
                        <div class="fv-row">
                          <label class="d-flex align-items-center fs-5 fw-semibold mb-4">
                            <span class="required">Select Frameworks</span>
                            <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Select your project frameworks"></i>
                          </label>

                          <ValidationProvider name="Framework" v-slot="{ errors }" :rules="{ required: true }" ref="technologies">
                            <input type="hidden" v-model="request.technologies">
                            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                          </ValidationProvider>

                          <div class="d-flex align-items-center position-relative my-1 mb-4">
                            <span class="svg-icon svg-icon-1 position-absolute ms-4">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
                              </svg>
                            </span>
                            <input type="text" class="form-control form-control-solid ps-14" placeholder="Search Framework" v-model="searchFramework"/>
                          </div>

                          <div class="d-flex flex-wrap">
                            <button class="btn btn-light btn-active-light-primary btn-sm me-2 mb-4" title="Remove" 
                              v-for="technology in request.technologies" :key="technology.id" @click="removeFramework(technology.id)">
                              {{ technology.title }} 
                              <span class="svg-icon svg-icon-6 me-0 align-self-center">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.3" d="M6.7 19.4L5.3 18C4.9 17.6 4.9 17 5.3 16.6L16.6 5.3C17 4.9 17.6 4.9 18 5.3L19.4 6.7C19.8 7.1 19.8 7.7 19.4 8.1L8.1 19.4C7.8 19.8 7.1 19.8 6.7 19.4Z" fill="currentColor"/>
                                  <path d="M19.5 18L18.1 19.4C17.7 19.8 17.1 19.8 16.7 19.4L5.40001 8.1C5.00001 7.7 5.00001 7.1 5.40001 6.7L6.80001 5.3C7.20001 4.9 7.80001 4.9 8.20001 5.3L19.5 16.6C19.9 16.9 19.9 17.6 19.5 18Z" fill="currentColor"/>
                                </svg>
                              </span>
                            </button>
                          </div>

                          <div class="scroll-y" data-kt-scroll="true" data-kt-scroll-activate="{default: true}" data-kt-scroll-max-height="auto" data-kt-scroll-offset="600px">
                            <label class="d-flex flex-stack cursor-pointer mb-5" v-for="framework in modalFrameworks" :key="framework.id">
                              <span class="d-flex align-items-center me-2">
                                <div class="symbol symbol-50px overflow-hidden me-6">
                                  <span class="symbol-label fw-bold" v-if="!framework.image">{{ generateInitial(framework.title )}}</span>
                                  <img :alt="framework.title" :src="framework.image_link" v-else class="bg-white img-fluid" 
                                    onerror="this.onerror=null;this.src='/assets/media/misc/image.png';" style="object-fit: cover;"/>
                                </div>
                                <span class="d-flex flex-column">
                                  <span class="fw-bold fs-6">{{ framework.title }}</span>
                                  <span class="fs-7 text-muted">{{ framework.description }}</span>
                                </span>
                              </span>
                              <span class="form-check form-check-custom form-check-solid">
                                <input class="form-check-input" type="checkbox" :value="framework" v-model="request.technologies"/>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div :class="{ 'current': step == 4 }" data-kt-stepper-element="content" v-if="step == 4">
                      <div class="w-100">
                        <label class="d-flex align-items-center fs-5 fw-semibold mb-4">
                          <span class="required">Select Members</span>
                          <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Pick several members to support your project"></i>
                        </label>

                        <!-- <ValidationProvider name="Member" v-slot="{ errors }" :rules="{ required: true }" ref="members">
                          <input type="hidden" v-model="request.tags">
                          <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                        </ValidationProvider> -->

                        <div class="fv-row" v-for="(member, index) in request.members" :key="index">
                          <div class="row">
                            <div class="col fv-row fv-plugins-icon-container">
                              <ValidationProvider :name="'Member ' + (index+1)" v-slot="{ errors }" :rules="{ required: true }" ref="members">
                                <multiselect 
                                  track-by="id"
                                  label="full_name"
                                  placeholder="Select user"
                                  v-model="member.user_id" 
                                  :options="users.filter( x => selectedUsers.indexOf(x.id) == -1  || x.id == member.user_id )"
                                  :close-on-select="true"
                                  :multiple="false"
                                  :searchable="true"
                                  :hide-selected="true"
                                  :showLabels="false"
                                ></multiselect>
                                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                              </ValidationProvider>
                            </div>
                            <div class="col fv-row fv-plugins-icon-container">
                              <ValidationProvider :name="'Member ' + (index+1) + ' position'" v-slot="{ errors }" :rules="{ required: true }" ref="members" v-if="member.user_id">
                                <multiselect 
                                  track-by="id"
                                  label="title"
                                  placeholder="Select positions"
                                  v-model="member.position_id" 
                                  :options="positions.filter( x => member.user_id.positions.map( z => z.position_id).includes(x.id) )"
                                  :allow-empty="false"
                                  :close-on-select="false"
                                  :multiple="true"
                                  :searchable="true"
                                  :hide-selected="true"
                                  :showLabels="false"
                                ></multiselect>
                                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                              </ValidationProvider>
                            </div>
                            <button type="button" class="btn btn-icon btn-light btn-active-light-primary align-self-start me-3" @click="removeMember(index)" v-if="request.members.length != 1">
                              <span class="svg-icon svg-icon-3">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path>
                                  <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path>
                                  <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path>
                                </svg>
                              </span>
                            </button>
                          </div>
                          <div class="separator separator-dashed my-4" v-if="index != (request.members.length-1)"></div>
                        </div>
                        <div class="d-flex justify-content-end">
                          <button type="button" class="btn btn-light btn-active-light-primary align-self-center mt-4" title="Add more member" @click="addMoreMember()">
                            <span class="svg-icon svg-icon-3">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M3 13V11C3 10.4 3.4 10 4 10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14H4C3.4 14 3 13.6 3 13Z" fill="currentColor"/>
                                <path d="M13 21H11C10.4 21 10 20.6 10 20V4C10 3.4 10.4 3 11 3H13C13.6 3 14 3.4 14 4V20C14 20.6 13.6 21 13 21Z" fill="currentColor"/>
                              </svg>
                            </span>
                            Add More Member
                          </button>
                        </div>
                      </div>
                    </div>
                    <div :class="{ 'current': step == 5 }" data-kt-stepper-element="content" v-if="step == 5">
                      <div class="w-100 text-center">
                        <h1 class="fw-bold text-dark mb-3">Release!</h1>
                        <div class="text-muted fw-semibold fs-3">Submit your app to kickstart your project.</div>
                        <div class="text-center px-4 py-15">
                          <img src="/assets/media/illustrations/sigma-1/10.png" alt="" class="mw-100 mh-300px theme-light-show" />
                          <img src="/assets/media/illustrations/sigma-1/10-dark.png" alt="" class="mw-100 mh-300px theme-dark-show" />
                        </div>
                      </div>
                    </div>

                    <div class="d-flex flex-stack pt-10">
                      <div class="me-2">
                        <button type="button" class="btn btn-lg btn-light-primary me-3" v-if="step != 1" @click="step -= 1">
                          <span class="svg-icon svg-icon-3 me-1">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor" />
                              <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" />
                            </svg>
                          </span>Back
                        </button>
                      </div>
                      <div>
                        <button type="submit" class="btn btn-lg btn-primary" v-if="step == 5">
                          <span class="indicator-label" v-if="!loading">Submit
                            <span class="svg-icon svg-icon-3 ms-2 me-0">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor" />
                                <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" />
                              </svg>
                            </span>
                          </span>
                          <span class="indicator-progress" v-else>
                            Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        </button>
                        <button type="submit" class="btn btn-lg btn-primary" v-else>Continue
                          <span class="svg-icon svg-icon-3 ms-1 me-0">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor" />
                              <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" />
                            </svg>
                          </span>
                        </button>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
  </div>
</template>
<script>
import ProjectCard from '@/components/ProjectCard.vue'
import ProjectSkeleton from '@/components/skeleton/ProjectSkeleton.vue'
import UnderConstruction from '@/components/UnderConstruction.vue'

export default {
	components: {
		ProjectCard,
    ProjectSkeleton,
    UnderConstruction
	},
  data() {
    return {
      table: {
        data: [],
        meta: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
          total: 0,
        },
        params: {
          limit: 10,
          sortby: 'updated_at.DESC',
          search: '',
          technology_id: null,
          tag_id: null,
          phase_id: null,
          status: null,
        }
      },
      backupFrameworks: [],
      frameworks: [],
      modalFrameworks: [],
      searchFramework: null,

      backupCategories: [],
      categories: [],
      modalCategories: [],
      searchCategory: null,

      clients: [],
      phases: [],
      step: 1,
      request: {
        name: null,
        description: null,
        client_id: null,
        technologies: [],
        tags: [],
        members: [],
        logo: null,
      },
      positions: [],
      users: [],
      loading: false,
      maxFileSize: 2,
      allowedExtension: ['jpg', 'jpeg', 'png'],
      fetchingProjectList: false,
      showAssignedToMe: false,
      loggedUser: JSON.parse(localStorage.getItem('user'))
    }
  },
  computed: {
    selectedUsers: function() {
      return this.request.members.map( x => x.user_id ? x.user_id.id : null ).filter( x => x != null)
    },
    requestBody: function() {
      let _ = this
      return {
        logo: _.request.logo,
        name: _.request.name,
        description: _.request.description,
        client_id: _.request.client_id ? _.request.client_id.id : null,
        technologies: _.request.technologies.map( x => x.id ),
        tags: _.request.tags.map( x => x.id ),
        members: _.request.members.map( function(x) {
          return {
            user_id: x.user_id ? x.user_id.id : null,
            position_id: x.position_id ? x.position_id.map( z => z.id ) : null
          }
        }).filter( x => x.user_id != null )
      }
    }
  },
  methods: {
    async handleUpload(event){
      let _ = this
      let file = event.target.files[0]
      
      const isUploaded = await _.upload( file, _.allowedExtension, _.maxFileSize, 'logo' )

      if (isUploaded) {
        _.request.logo = isUploaded
      }
    },
    get( page = 1 ) {
      let _ = this
      
      _.fetchingProjectList = true

      _.axios.get('/v1/project'
        + '?page=' + page
        + '&limit=' + _.table.params.limit
        + '&sortBy=' + _.table.params.sortby
        + '&search=' + _.table.params.search
        + '&technology_id=' + (_.table.params.technology_id ? _.table.params.technology_id.id : '')
        + '&tag_id=' + (_.table.params.tag_id ? _.table.params.tag_id.id : '')
        + '&status=' + (_.table.params.status ? _.table.params.status : '')
        + '&phase_id=' + (_.table.params.phase_id ? _.table.params.phase_id.id : '')
        + '&user_id=' + (_.showAssignedToMe ? _.loggedUser.id : '' )
      )
      .then( resp => {
        _.table.data = resp.data.data
        _.table.meta = resp.data.meta

        _.fetchingProjectList = false
      })
      .catch( err => {
        _.$swalToast.fire({
          icon: 'error',
          title: `Oops! Something went wrong (${err.response.status})`,
          text: err.response.data.errors
        })

        _.fetchingProjectList = false
      })
    },
    getFramework() {
      let _ = this
      _.axios.get('/v1/technology')
        .then( resp => {
          _.frameworks = resp.data.data
          _.backupFrameworks = resp.data.data
          _.modalFrameworks = resp.data.data
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    getCategory() {
      let _ = this
      _.axios.get('/v1/tag')
        .then( resp => {
          _.categories = resp.data.data
          _.backupCategories = resp.data.data
          _.modalCategories = resp.data.data

          setTimeout(() => {
            KTScroll.createInstances();
          }, 100);
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    getPosition() {
      let _ = this
      _.axios.get('/v1/position')
        .then( resp => {
          _.positions = resp.data.data
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    getUser() {
      let _ = this
      _.axios.get('/v1/user')
        .then( resp => {
          _.users = resp.data.data
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    getClient() {
      let _ = this
      _.axios.get('/v1/client')
      .then( resp => {
        _.clients = resp.data.data

        $(document).ready(function() {
          $('[data-bs-toggle="tooltip"]').tooltip();
        });
      })
      .catch( err => {
        _.$swalToast.fire({
          icon: 'error',
          title: `Oops! Something went wrong (${err.response.status})`,
          text: err.response.data.errors
        })
      })
    },
    getPhase() {
      let _ = this

      _.axios.get('/v1/phase')
        .then( resp => {
          _.phases = resp.data.data
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    removeCategory( categoryId ) {
      let _ = this
      const searchCategory = _.request.tags.map( x => x.id ).indexOf( categoryId )
      if ( searchCategory != -1 ) {
        _.request.tags.splice(searchCategory, 1)
      }
    },
    removeFramework( frameworkId ) {
      let _ = this
      const searchFramework = _.request.technologies.map( x => x.id ).indexOf( frameworkId )
      if ( searchFramework != -1 ) {
        _.request.technologies.splice(searchFramework, 1)
      }
    },
    addMoreMember() {
      this.request.members.push({
        user_id: null,
        position_id: null
      })
    },
    removeMember(index) {
      this.request.members.splice(index, 1)
    },
    async reset() {
      this.request = {
        name: null,
        description: null,
        client_id: null,
        technologies: [],
        tags: [],
        members: [],
        logo: null
      }
      this.request.members.push({
        user_id: null,
        position_id: []
      })

      this.step = 1
      await this.$refs['observer-step-1'].reset()
    },
    async changeToStep( step ) {
      let _ = this

      if ( step == 6 ) {
        _.createProject()
        return;
      }
      
      const isValid = await _.$refs['observer-step-' + (step-1)].validate()
      if ( !isValid ) return

      await this.$refs['observer-step-' + (step-1)].reset()

      _.step = step
    },
    createProject() {
      let _ = this

      _.loading = true
      _.axios.post('/v1/project', _.requestBody)
        .then(() => {
          _.loading = false
          _.reset()
          _.hideModal()
          _.get()
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully add new project',
            text: `You have successfully add new project`
          })
          _.$root.$emit('updateProject')
        })
        .catch( err => {
          if ( typeof err.response.data.errors === 'object' && err.response.data.errors !== null ) {
            let elms = []
            Object.keys(err.response.data.errors).forEach(elm => {
              elms.push(elm)
              
              if ( elms.length > 0 ) {
                if ( ['logo', 'client_id', 'name', 'description'].includes(elms[0]) ) {
                  _.step = 1
                } else if ( ['tags'].includes(elms[0]) ) {
                  _.step = 2
                } else if ( ['technologies'].includes(elms[0]) ) {
                  _.step = 3
                } else if ( ['members'].includes(elms[0]) ) {
                  _.step = 4
                }
              }

              setTimeout(() => {
                _.$refs[elm].applyResult({
                  errors: [err.response.data.errors[elm][0]],
                  valid: false,
                  failedRules: {}
                });
              }, 100);
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
          _.loading = false
        })
    }, 
    handleSearch() {
      this.table.data = []
      this.get()
    },
    changePage(page) {
      let _ = this
			if (page > _.table.meta.last_page) {
				page = _.table.meta.last_page;
			}
			_.table.meta.current_page = page;
			_.get(page)
		},
  },
  mounted() {
    let _ = this

    _.hideModal()

    // setTimeout(() => {
    //   $('#buttonAddProject').click()
    //   _.step = 4
    // }, 100);

    _.get()
    _.getFramework()
    _.getCategory()
    _.getClient()
    _.getPosition()
    _.getUser()
    _.getClient()
    _.getPhase()

    setTimeout(() => {
      KTMenu.createInstances();
      // $('#buttonAddProject').click();
    }, 100);

    $(document).ready(function() {
      $('textarea').keypress(function(event) {
        if (event.keyCode == 13) {
          event.preventDefault();
        }
      });
    });
  },
  watch: {
    searchCategory: function() {
      let _ = this
      _.modalCategories = JSON.parse(JSON.stringify(_.backupCategories))
      _.modalCategories = _.modalCategories.filter( function(x) {
        return x.title.toLowerCase().indexOf(_.searchCategory.toLowerCase()) > -1
      })
    },
    searchFramework: function() {
      let _ = this
      _.modalFrameworks = JSON.parse(JSON.stringify(_.backupFrameworks))
      _.modalFrameworks = _.modalFrameworks.filter( function(x) {
        return x.title.toLowerCase().indexOf(_.searchFramework.toLowerCase()) > -1
      })
    },
    step: function() {
      $(document).ready(function() {
        $('[data-bs-toggle="tooltip"]').tooltip('dispose');
      });
      setTimeout(() => {
        $(document).ready(function() {
          $('[data-bs-toggle="tooltip"]').tooltip();
        });
      }, 100);
    }
  }
}
</script>