<template>
  <router-link :to="'/project-management/project/detail/' + data.id + '/overview'" class="card border-hover-primary">
    <div class="card-header border-0 pt-9">
      <div class="card-title m-0">
        <div class="symbol symbol-50px w-50px bg-light">
            <span class="symbol-label" v-if="!data.logo">
              <span class="fw-bold">{{ generateInitial(data.name) }}</span>
            </span>
            <img v-else :src="data.logo_link" class="" alt="" referrerpolicy="no-referrer" 
              onerror="this.onerror=null;this.src='/assets/media/misc/image.png';" style="object-fit: cover;"/>
        </div>
      </div>
      <div class="card-toolbar">
        <span class="badge fw-bold me-auto px-4 py-3" :class="projectStatusBadgeColor(data.status)">{{ data.status }}</span>
      </div>
    </div>
    <div class="card-body p-9">
      <div class="fs-3 fw-bold text-dark">{{ data.name }}</div>
      <p class="text-gray-400 fw-semibold fs-5 mt-1 mb-7" style="height:68px;">{{ data.description.length > 100 ? data.description.substring(0, 100) + '...' : data.description }}</p>
      <div class="d-flex flex-wrap mb-5">
        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
          <div class="fs-6 text-gray-800 fw-bold" v-if="data.due_date">{{ data.due_date | moment('LL') }}</div>
          <div class="fs-6 text-gray-800 fw-bold" v-else>-</div>
          <div class="fw-semibold text-gray-400">Due Date</div>
        </div>
        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
          <div class="fs-6 text-gray-800 fw-bold" v-if="data.project_timeline">{{ data.project_timeline.phase ? data.project_timeline.phase.name : ''}} 
            <span v-if="data.project_timeline.sub_phase">: {{ data.project_timeline.sub_phase }}</span>
          </div>
          <div class="fs-6 text-gray-800 fw-bold" v-else>-</div>
          <div class="fw-semibold text-gray-400">Phase</div>
        </div>
      </div>
      <div class="h-4px w-100 bg-light mb-5" data-bs-toggle="tooltip" :title="`This project ${data.progress ? data.progress : 0}% completed`">
        <div class="bg-primary rounded h-4px" role="progressbar" :style="`width: ${data.progress ? data.progress : 0}%`" :aria-valuenow="data.progress ? data.progress : 0" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <div class="symbol-group symbol-hover">
        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" :title="member.user.full_name" v-for="member in data.project_members.filter( x => x.user )" :key="member.id">
          <span class="symbol-label bg-warning text-inverse-warning fw-bold" v-if="!member.user.avatar">{{ generateInitial(member.user.full_name )}}</span>
          <img v-else :alt="member.user.full_name" :src="member.user.avatar" style="object-fit: cover;"/>
        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
export default {
  props: [
    'data'
  ],
  mounted() {
    $(document).ready(function() {
      $('[data-bs-toggle="tooltip"]').tooltip();
    });
  },
}
</script>