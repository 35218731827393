<template>
  <div>
    <p class="text-center text-sm" v-if="data.current_page < data.last_page">
      <a class="color-gray-100 cursor-pointer" @click="changePage( data.current_page + 1 )">
        Show more <span class="fa fa-arrow-down"></span>
      </a>
    </p>
  </div>
</template>
<script>
export default {
  props: ['data'],
  methods: {
    changePage(page) {
      let _ = this
			if (page > _.data.last_page) {
				page = _.data.last_page;
			}
			_.data.current_page = page;
			_.$root.$emit('changePage', page);
		}
  },
}
</script>