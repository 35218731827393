<template>
  <div>
		<div class="row">
			<div class="col-sm-12 col-md-2 d-flex align-items-center justify-content-center justify-content-md-start">
				<div class="dataTables_length">
					<label>
						<select class="form-select form-select-sm form-select-solid" v-model="limit">
							<option value="10">10</option>
							<option value="25">25</option>
							<option value="50">50</option>
							<option value="100">100</option>
						</select>
					</label>
				</div>
			</div>
			<div class="col-sm-12 col-md-10 d-flex align-items-center justify-content-center justify-content-md-end">
				<div class="dataTables_paginate paging_simple_numbers">
					<ul class="pagination">
						<li class="paginate_button page-item previous" :class="{ disabled: data.current_page <= 1 }">
							<a href="#" class="page-link" @click.prevent="changePage(data.current_page - 1)"><i class="previous"></i></a>
						</li>
						<li class="paginate_button page-item" v-for="(page, key) in pages" :key="key" :class="iscurrent_page(page) ? 'active' : ''">
							<a href="#" class="page-link" @click.prevent="changePage(page)">{{ page }}</a>
						</li>
						<li class="paginate_button page-item" :class="{ disabled: data.current_page >= data.last_page }">
							<a href="#" class="page-link" @click.prevent="changePage(data.current_page + 1)"><i class="next"></i></a>
						</li>
					</ul>
				</div>
			</div>
		</div>
  </div>
</template>
<script>
export default {
	props: [ 'data' ],
	data() {
		return {
			limit: 10
		}
	},
	methods: {
		iscurrent_page(page){
			return this.data.current_page === page
		},
		changePage(page) {
			if (page > this.data.last_page) {
				page = this.data.last_page;
			}
			this.data.current_page = page;
			this.$root.$emit('changePage', page);
		}
	},
	computed: {
		pages() {
			let current         = this.data.current_page
			let last            = this.data.last_page
			let delta           = 1
			let left            = current - delta
			let right           = current + delta + 1
			let range           = []
			let rangeWithDots   = []  
			let l               = 0              

			for (let i = 1; i <= last; i++) {
				if (i == 1 || i == last || i >= left && i < right) {
					range.push(i);
				}
			}

			for (let i of range) {
				if (l) {
					if (i - l === 2) {
						rangeWithDots.push(l + 1);
					} else if (i - l !== 1) {
						rangeWithDots.push('...');
					}
				}
				rangeWithDots.push(i);
				l = i;
			}

			return rangeWithDots;
		}
	},
	watch: {
		limit: function(){
			let _ = this
			_.$root.$emit('changeLimit', _.limit)
		}
	}
}
</script>