<template>
  <div>
    <div class="card mb-5 mb-xl-10">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div class="ms-4">
            <div class="d-flex align-items-center">
              <div class="text-gray-800 fw-bold">Google Drive</div>
              <div class="badge badge-light-danger ms-5" v-if="!isIntegratedGoogleDrive">Not Connected</div>
              <div class="badge badge-light-primary ms-5" v-else>Connected</div>
            </div>
            <div class="text-muted">By integrating Google Drive, you can retrieve or send all data to your Google Drive account</div>
          </div> 

          <button type="submit" class="btn btn-primary btn-sm" v-if="loading">
            <span class="indicator-progress">
              {{ connecting ? 'Connecting...' : 'Please wait...' }}
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>

          <div class="align-self-center" v-if="!loading && !isIntegratedGoogleDrive">
            <a :href="googleDriveIntegrationUrl" class="btn btn-primary btn-sm">Connect</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      connecting: false,
      isIntegratedGoogleDrive: false
    }
  },
  computed: {
    googleDriveIntegrationUrl: function() {
      return 'https://accounts.google.com/o/oauth2/v2/auth'
        + '?scope='         + 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/docs https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.metadata https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/drive.photos.readonly https://www.googleapis.com/auth/drive.readonly'
        + '&access_type='   + 'offline'
        + '&response_type=' + 'code'
        + '&state='         + 'vodjo2016!'
        + '&redirect_uri='  + window.location.origin + '/integration-setting'
        + '&client_id='     + '23615408525-39kfju04e2qbf49kj3ja74s063f4v1ih.apps.googleusercontent.com'
    }
  },
  methods: {
    integrateGoogleDrive( code ) {
			let _ = this
      _.loading = true
      _.connecting = true
			_.axios.post('/v1/setting/drive/integrate', { code: code })
				.then(() => {
          _.loading = false
          _.connecting = false
          _.isIntegratedGoogleDrive = true

          _.$router.replace({'query': null});
					_.$swalToast.fire({
            icon: 'success',
            title: 'Successfully integrating Google Drive',
            text: `You have successfully integrate your google drive's account`
          })
          _.googleDriveDetails()
				})
				.catch(err => {
					_.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
					_.$router.replace({'query': null});
          _.loading = false
          _.connecting = false
				})
		},
    googleDriveDetails() {
      let _ = this
      if (!_.isIntegratedGoogleDrive) {
        _.loading = true
      }
			_.axios.get('/v1/setting/drive/integrate')
				.then(resp => {
          if ( resp.data.data.root_folder ) {
            _.isIntegratedGoogleDrive = true
          }
          _.loading = false
				})
        .catch(() => {
          if ( _.$route.query.code ) {
            _.integrateGoogleDrive( _.$route.query.code )
          } else {
            _.loading = false
          }
        })
    }
  },
  mounted() {
    let _ = this
    _.googleDriveDetails()
  },
}
</script>