import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      name: null,
      email: null
    },
	  token: null,
  },
  getters: {
    //
  },
  mutations: {
    LOGIN(state, payload) {
      state.token = payload.token
      state.user = payload.user

      localStorage.setItem('token', payload.token)
      localStorage.setItem('user', JSON.stringify(payload.user))
    },
    LOGOUT(state) {
      state.token = null
      state.user = null

      localStorage.removeItem('token')
      localStorage.removeItem('user')
    },
    UPDATE_USER(state, payload) {
      state.user = payload

      localStorage.setItem('user', JSON.stringify(payload))
    },
  },
  actions: {
    login({ commit }, payload) {
      commit('LOGIN', payload)
    },
    logout({ commit }) {
      commit('LOGOUT')
    },
    update_user({ commit }, payload) {
      commit('UPDATE_USER', payload)
    },
  },
  modules: {
    //
  }
})
