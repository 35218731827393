<template>
  <div class="dataTables_wrapper dt-bootstrap4 no-footer">
    <div class="table-responsive">
      <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
        <thead>
          <tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
            <th
              v-for="(column, index) in table.options.columns" 
              :class="{ 
                'cursor-pointer': column.sortable,
                'sorting':      column.sortable,
                'sorting_asc':  currentSort == column.reference && currentOrder == 'asc',
                'sorting_desc': currentSort == column.reference && currentOrder == 'desc',
                'w-10px pe-2':  column.name == 'checkbox'
              }"
              @click="sort( column )"
              :key="index">
              <div class="form-check form-check-sm form-check-custom form-check-solid me-3" v-if="column.name == 'checkbox'">
                <input class="form-check-input" type="checkbox" @click="selectAll($event)" :checked="table.data.length > 0 ? isAllChecked : false" :disabled="table.data.length == 0"/>
              </div>
              <span v-else>{{column.name == 'ID' ? 'NO' : column.name }}</span>
            </th>

            <th class="text-end" v-if="table.options.actionButton">Actions</th>
          </tr>
        </thead>
        <tbody class="fw-semibold text-gray-600">
          <tr v-for="(data, key) in table.data" :key="key">
            <td v-for="(row, krow) in table.options.rows" :key="krow">
              <div class="form-check form-check-sm form-check-custom form-check-solid" v-if="row.type == 'checkbox'">
                <input class="form-check-input" type="checkbox" :id="data.id" name="select" 
                  @click="select(data.id, $event)" 
                  :checked="selected.some((obj)=> obj == data.id)"
                  v-if="isShow(data.id)"
                />
              </div>
              <div class="d-flex align-items-center" v-else>
                <div class="symbol symbol-50px overflow-hidden me-3" v-if="row.image" :class="{ 'symbol-circle' : row.imageRounded }">
                  <router-link v-if="row.route" :to="row.route + getValue(data, row.routeReference)">
                    <div class="symbol-label">
                      <img v-if="getValue(data, row.image)" :src="getValue(data, row.image)" :alt="getValue(data, row.title)" class="w-100" referrerpolicy="no-referrer"
                        onerror="this.onerror=null;this.src='/assets/media/misc/image.png';" style="object-fit:cover;"/>
                      <span class="fw-bold" v-else>{{ generateInitial(getValue(data, row.title)) }}</span>
                    </div>
                  </router-link>
                  <div class="symbol-label" v-else>
                    <img v-if="getValue(data, row.image)" :src="getValue(data, row.image)" :alt="getValue(data, row.title)" class="w-100 h-100" referrerpolicy="no-referrer" 
                      onerror="this.onerror=null;this.src='/assets/media/misc/image.png';" style="object-fit:cover;"/>
                    <span class="fw-bold" v-else>{{ generateInitial(getValue(data, row.title)) }}</span>
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <router-link v-if="row.route" :to="row.route + getValue(data, row.routeReference)" class="text-gray-800 text-hover-primary mb-1">
                    <Title :data="getValue(data, row.title)" :type="row.type"/>
                  </router-link>
                  <span v-else-if="(row.title == 'id')">
                    <span v-if="currentSort == 'updated_at' && currentOrder == 'asc'">{{ (table.meta.total - ( table.meta.per_page * (table.meta.current_page - 1) + key )) }}</span>
                    <span v-else>{{ (table.meta.current_page - 1) * table.meta.per_page + key + 1 }}</span>
                  </span>
                  <span v-else>
                    <Title :data="getValue(data, row.title)" :type="row.type"/>
                  </span>
                  <span v-if="row.subtitle">
                    <Title :data="getValue(data, row.subtitle)" :type="row.type"/>
                  </span>
                </div>
              </div>
            </td>
            <td v-if="table.options.actionButton" class="text-end">
              <slot name="actionButton" v-bind:data="data"></slot>
            </td>
          </tr>
          <tr v-if="loading">
            <td v-for="(row, krow) in table.options.rows" :key="krow">
              <div class="form-check form-check-sm form-check-custom form-check-solid" v-if="row.type == 'checkbox'">
              </div>
              <div class="d-flex align-items-center" v-else-if="row.title != 'id'">
                <div class="symbol symbol-50px overflow-hidden me-3" v-if="row.image" :class="{ 'symbol-circle' : row.imageRounded }">
                  <vue-skeleton-loader type="rect" :width="50" :height="50" :rounded="true"/>
                </div>
                <div class="d-flex flex-column">
                  <vue-skeleton-loader type="rect" :width="100" :height="20" :rounded="true" class="mb-3"/>
                  <vue-skeleton-loader type="rect" :width="150" :height="20" :rounded="true" class="mb-3"/>
                </div>
              </div>
            </td>
            <td v-if="table.options.actionButton" class="text-end">
              <vue-skeleton-loader type="rect" :width="80" :height="30" :rounded="true" class="mb-3" style="float:right;"/>
            </td>
          </tr>
          <tr v-if="!loading && (!table.data || table.data.length == 0)">
            <td :colspan="table.options.columns.length + ( table.options.actionButton ? 1 : 0 )" class="text-center">
              <div class="d-flex flex-column flex-center">
                <img src="/assets/media/illustrations/sigma-1/21.png" class="mw-400px">
                <div class="fs-1 fw-bolder text-dark mb-4">No data found.</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination :data="table.meta" v-if="table.data && table.data.length != 0"/>
  </div>
</template>
<script>
import Title from '@/components/Title.vue'
import Pagination from '@/components/Pagination.vue'

export default {
  components: {
    Title,
    Pagination
	},
  props: [ 'table', 'loading' ],
  computed: {
    isAllChecked: function() {
      return this.selected.length == (this.table.data ? this.table.data.length : 0)
    }
  },
  data() {
    return {
      currentSort: '',
      currentOrder: '',
      selected: []
    }
  },
  methods: {
    getValue( data, reference ) {
      let str = data

      if (reference.indexOf('&') != -1) {
        let splitReference = reference.split('&')

        let zz = ''

        splitReference.forEach(ref => {
          zz = zz + ' ' + str[ref]
        });
        str = zz
      } else {
        let splitReference = reference.split('.')
        splitReference.forEach(ref => {
          if ( str != null && str[ref] ) {
            str = str[ref]
          } else {
            str = null
          }
        }); 
      }

      return str ? str : null
    },
    sort( column ) {
      let _ = this

      if (!column.sortable) return;

      if ( !_.table.data || _.table.data.length == 0 ) return

      if ( _.currentSort == column.reference ) {
        if ( _.currentOrder == 'desc' ) {
          _.currentSort = ''
          _.currentOrder = ''
        } else {
          _.currentOrder = _.currentOrder == 'asc' ? 'desc' : 'asc'
        }
      } else {
        _.currentSort = column.reference
        _.currentOrder = 'asc'
      }

      _.$root.$emit('changeSortBy', _.currentSort + '.' + _.currentOrder);
    },
    selectAll(event){
      let _ = this
      const loggedUser = JSON.parse(localStorage.getItem('user'))

      if (event.target.checked) {
        _.table.data.forEach(elm => {
          if (_.$route.name == 'Users' && elm.id == loggedUser.id) {
            //
          } else {
            _.selected.push( elm.id )
          }
        });
      } else {
        _.selected = []
      }
    },
    select(value, event){
      let _ = this
      if (event.target.checked) {
        _.selected.push(value)                
      } else {
        const index = _.selected.indexOf(value);
        if (index > -1) {
          _.selected.splice(index, 1);
        }
      }
    },
    isShow(id) {
      let _ = this
      const loggedUser = JSON.parse(localStorage.getItem('user'))

      if (_.$route.name == 'Users') {
        if (id == loggedUser.id) {
          return false
        }
      }

      return true
    }
  },
  watch: {
    selected: function() {
      this.$root.$emit('changeSelected', this.selected);
    }
  },
  mounted() {
    let _ = this
    _.$root.$on('resetSelected', () => {
      _.selected = []
    })
  },
}
</script>