<template>
  <div class="d-flex flex-column flex-root">
			<div class="d-flex flex-column flex-lg-row flex-column-fluid">
				<div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
					<div class="d-flex flex-center flex-column flex-lg-row-fluid">
						<div class="w-lg-500px p-10">
							<div class="form w-100">
								<div class="text-start mb-11">
									<h1 class="text-dark fw-bolder mb-3">Sign In</h1>
									<div class="text-gray-500 fw-semibold fs-6">Enterprise Resource Plan</div>
								</div>
                <a :href="`${$apiBaseUrl}/auth/google/redirect`" 
									class="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
									v-if="!$route.query.token">
                	<img alt="Logo" src="/assets/media/svg/brand-logos/google-icon.svg" class="h-15px me-3" />Sign in with Google
								</a>
                <button
									class="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100" disabled
									v-else>
                	<img alt="Logo" src="/assets/media/svg/brand-logos/google-icon.svg" class="h-15px me-3" />Signing in <i style="margin-left: 5px;" class="fa fa-spin fa-spinner"></i>
								</button>
							</div>
						</div>
					</div>
					<div class="d-flex flex-center flex-wrap px-5">
						<div class="d-flex fw-semibold text-primary fs-base">
							<a href="https://vodjo.com" class="px-5" target="_blank">Terms</a>
							<a href="https://vodjo.com" class="px-5" target="_blank">Plans</a>
							<a href="https://vodjo.com" class="px-5" target="_blank">Contact Us</a>
						</div>
					</div>
				</div>
				<div class="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style="background-image: url(/assets/media/misc/bg-2.jpg)">
					<div class="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
						<a href="https://vodjo.com" class="mb-0 mb-lg-12">
							<img alt="Logo" src="/img/putih.png" class="h-60px h-lg-75px" />
						</a>
						<img class="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20" src="/assets/media/misc/auth-screens.png" alt="" />
						<h1 class="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7">Fast, Efficient and Productive</h1>
						<div class="d-none d-lg-block text-white fs-base text-center">This app aims to
						<a href="#" class="opacity-75-hover text-warning fw-bold me-1">facilitate</a> all the work and needs of each employee 
						<br />in order to build an 
						<a href="#" class="opacity-75-hover text-warning fw-bold me-1">effective & productive</a> work
            </div>
					</div>
				</div>
			</div>
		</div>
</template>
<script>
export default {
	methods: {
		login(token) {
			let _ = this
			_.axios.get('/auth/me', {
					headers: {
						'Authorization': `Bearer ${token}`
					}
				})
				.then(resp => {
					const data = {
						user: resp.data.data,
						token: token
					}
					_.$store.dispatch('login', data)
					window.location = '/dashboard'
				})
				.catch(err => {
					_.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
					_.$router.replace({'query': null});
				})
		},
	},
	mounted() {
		let _ = this
		if (_.$route.query.token) {
			_.login(_.$route.query.token)
		}
	},
}
</script>