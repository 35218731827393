<template>
  <div>
    <div class="card card-flush">
      <div class="card-header align-items-center py-5 gap-2 gap-md-5">
        <div class="card-title">
          <div class="d-flex align-items-center position-relative my-1">
            <span class="svg-icon svg-icon-1 position-absolute ms-4">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
              </svg>
            </span>
            <input type="text" class="form-control form-control-solid w-250px ps-14" placeholder="Search User" 
              v-debounce:300.lock="handleSearch" v-model="table.params.search"/>
          </div>
        </div>
        <div class="card-toolbar">
          <div class="d-flex justify-content-end" v-if="selected.length == 0">
            <button type="button" class="btn btn-light-primary me-3" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
              <span class="svg-icon svg-icon-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor" />
                </svg>
              </span>
              Filter
            </button>
            <div class="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true">
              <div class="px-7 py-5">
                <div class="fs-5 text-dark fw-bold">Filter Options</div>
              </div>
              <div class="separator border-gray-200"></div>
              <div class="px-7 py-5" data-kt-user-table-filter="form">
                <div class="mb-3">
                  <label class="form-label fs-6 fw-semibold">Role:</label>
                  <multiselect 
                    track-by="id"
                    label="name"
                    placeholder="Select Role"
                    v-model="table.params.role_id" 
                    :options="roles"
                    :close-on-select="true"
                    :multiple="false"
                    :searchable="true"
                    :showLabels="false"
                    @input="get()"
                  ></multiselect>
                </div>
                <div class="mb-10">
                  <label class="form-label fs-6 fw-semibold">Position:</label>
                  <multiselect 
                    track-by="id"
                    label="title"
                    placeholder="Select Position"
                    v-model="table.params.position_id" 
                    :options="positions"
                    :close-on-select="true"
                    :multiple="false"
                    :searchable="true"
                    :showLabels="false"
                    @input="get()"
                  ></multiselect>
                </div>
                <div class="d-flex justify-content-end">
                  <button type="reset" class="btn btn-light btn-active-light-primary fw-semibold px-6" data-kt-menu-dismiss="true" data-kt-user-table-filter="reset" 
                    @click="table.params.role_id = null, table.params.position_id = null, get()">Reset</button>
                  <!-- <button type="submit" class="btn btn-primary fw-semibold px-6" data-kt-menu-dismiss="true" data-kt-user-table-filter="filter" @click="get()">Apply</button> -->
                </div>
              </div>
            </div>
            <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_user" @click="reset()">Add New User</button>
          </div>
          <div class="d-flex justify-content-end align-items-center my-1" v-else>
            <div class="fw-bold me-5">
            <span class="me-2">{{ selected.length | numFormat }}</span>Selected</div>
            <button type="button" class="btn btn-danger" @click="bulkDeleteUser()">Delete Selected</button>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <Table :table="table" :loading="loading">
          <template v-slot:actionButton="{ data }">
            <button class="btn btn-sm btn-light btn-active-light-primary text-nowrap" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
              <span class="svg-icon svg-icon-5 m-0">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                </svg>
              </span>
            </button>
            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
              <div class="menu-item px-3">
                <router-link :to="'/user-management/user/detail/' + data.id" class="menu-link px-3">
                  View
                </router-link>
              </div>
              <div class="menu-item px-3" v-if="data.id != loggedUser.id">
                <a href="#" class="menu-link px-3" data-kt-roles-table-filter="delete_row" @click="deleteUser( data )">Delete</a>
              </div>
            </div>
          </template>
        </Table>
      </div>
    </div>

    <div class="modal fade" id="kt_modal_add_user" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
          <div class="modal-header" id="kt_modal_add_user_header">
            <h2 class="fw-bold">Add New User</h2>
            <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal">
              <span class="svg-icon svg-icon-1">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                </svg>
              </span>
            </div>
          </div>
          <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
            <ValidationObserver ref="observer">
              <form class="form" @submit.prevent="createUser()">
                <div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
                  <div class="fv-row mb-7">
                    <ValidationProvider name="Avatar" v-slot="{ errors }" :rules="{ }" ref="avatar">
                      <label class="d-block fw-semibold fs-6 mb-5">Avatar</label>
                      <div class="image-input image-input-outline image-input-placeholder" data-kt-image-input="true">
                        <div class="image-input-wrapper w-125px h-125px" :style="`background-image: url(${request.avatar ? request.avatar : '/assets/media/avatars/blank.png'}); object-fit:cover; background-position:center;`"></div>
                        <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change image">
                          <i class="bi bi-pencil-fill fs-7"></i>
                          <input type="file" class="hidden" id="upload" v-on:change="handleUpload" :accept="'.' + allowedExtension.join(', .').toLowerCase()">
                          <input type="hidden" name="avatar_remove" />
                        </label>
                        <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove image" 
                          v-if="request.avatar" @click="request.avatar = null">
                          <i class="bi bi-x fs-2"></i>
                        </span>
                      </div>
                      <div class="form-text">Maximum file size: {{ maxFileSize }} Mb. ({{ '.' + allowedExtension.join(' .').toUpperCase() }}).</div>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row mb-7">
                    <ValidationProvider name="NIK" v-slot="{ errors }" :rules="{ }" ref="nik">
                      <label class="fw-semibold fs-6 mb-2">NIK</label>
                      <input type="text" name="user_name" class="form-control form-control-solid mb-3 mb-lg-0" placeholder="NIK" v-model="request.nik" @keypress="numbersOnly($event)"/>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row mb-7">
                    <ValidationProvider name="Full name" v-slot="{ errors }" :rules="{ required: true }" ref="full_name">
                      <label class="required fw-semibold fs-6 mb-2">Full Name</label>
                      <input type="text" name="user_name" class="form-control form-control-solid mb-3 mb-lg-0" placeholder="Full name" v-model="request.full_name" />
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row mb-7">
                    <ValidationProvider name="Email" v-slot="{ errors }" :rules="{ required: true, email: true }" ref="email">
                      <label class="required fw-semibold fs-6 mb-2">Email</label>
                      <input type="email" name="user_email" class="form-control form-control-solid mb-3 mb-lg-0" placeholder="Email" v-model="request.email"/>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row mb-7">
                    <ValidationProvider name="Phone number" v-slot="{ errors }" :rules="{ required: true, min: 8, max: 15 }" ref="phone_number">
                      <label class="required fw-semibold fs-6 mb-2">Phone Number</label>
                      <input type="text" name="user_phone" class="form-control form-control-solid mb-3 mb-lg-0" placeholder="Phone number" v-model="request.phone_number"  @keypress="numbersOnly($event)"/>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row mb-7">
                    <ValidationProvider name="Address" v-slot="{ errors }" :rules="{ }" ref="address">
                      <label class="fw-semibold fs-6 mb-2">Address</label>
                      <textarea class="form-control form-control-solid mb-3 mb-lg-0" cols="30" rows="5" v-model="request.address" placeholder="Address details"></textarea>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row mb-7">
                    <ValidationProvider name="Joined date" v-slot="{ errors }" :rules="{ required: true }" ref="join_date">
                      <label class="required fw-semibold fs-6 mb-2">Joined Date</label>
                      <div style="position:relative">
                        <date-picker class="form-control form-control-solid" v-model="request.join_date" :config="joinDatepickerOptions" placeholder="Pick a date"></date-picker>
                      </div>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="mb-7">
                    <ValidationProvider name="Role" v-slot="{ errors }" :rules="{ required: true }" ref="role_id">
                      <label class="required fw-semibold fs-6 mb-5">Role</label>
                      <div v-for="(role, index) in roles" :key="index">
                        <div class="d-flex fv-row">
                          <div class="form-check form-check-custom form-check-solid">
                            <input class="form-check-input me-3" name="user_role" type="radio" :value="role.id" :id="'kt_modal_update_role_option_' + index" v-model="request.role_id"/>
                            <label class="form-check-label" :for="'kt_modal_update_role_option_' + index">
                              <div class="fw-bold text-gray-800">{{ role.name }}</div>
                              <div class="text-gray-600">{{ role.description }}</div>
                            </label>
                          </div>
                        </div>
                        <div class='separator separator-dashed my-5' v-if="index != roles.length-1"></div>
                      </div>
                      <div class="mt-3" v-if="errors[0]">
                        <small class="text-danger text-sm text-xs">{{ errors[0] }}</small>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="mb-7">
                    <label class="fw-semibold fs-6 mb-5">Position</label>
                    <div v-for="(position, index) in positions" :key="index">
                      <div class="d-flex fv-row">
                        <div class="form-check form-check-custom form-check-solid">
                          <input class="form-check-input me-3" name="user_role" type="checkbox" :value="position.id" :id="'kt_modal_update_position_option_' + index" v-model="request.positions"/>
                          <label class="form-check-label" :for="'kt_modal_update_position_option_' + index">
                            <div class="fw-bold text-gray-800">{{ position.title }}</div>
                            <div class="text-gray-600">{{ position.description }}</div>
                          </label>
                        </div>
                      </div>
                      <div class='separator separator-dashed my-5' v-if="index != positions.length-1"></div>
                    </div>
                  </div>
                </div>
                <div class="text-center pt-15">
                  <button type="reset" class="btn btn-light me-3" data-bs-dismiss="modal">Discard</button>
                  <button type="submit" class="btn btn-primary">
                    <span class="indicator-label" v-if="!loading">Submit</span>
                    <span class="indicator-progress" v-else>
                      Please wait...
                      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from '@/components/Table.vue'

export default {
	components: {
    Table
	},
  data() {
    return {
      table: {
        options: {
          columns: [
            { name: 'checkbox', sortable: false, reference: null },
            { name: 'ID', sortable: true, reference: 'updated_at' },
            { name: 'User', sortable: true, reference: 'first_name' },
            { name: 'Contact', sortable: true, reference: 'email' },
            { name: 'Role & Position', sortable: false, reference: null },
            { name: 'Last Active', sortable: false, reference: null },
            { name: 'Joined Date', sortable: true, reference: 'join_date' },
          ],
          rows: [
            { title: null, type: 'checkbox' },
            { title: 'id', type: 'text' },
            {
              title: 'full_name',
              type: 'text',
              image: 'avatar',
              imageRounded: true,
              subtitle: 'nik',
              route: '/user-management/user/detail/',
              routeReference: 'id',
            },
            {
              title: 'email',
              type: 'text',
              subtitle: 'phone_number',
              route: '/user-management/user/detail/',
              routeReference: 'id',
            },
            {
              title: 'role.name',
              type: 'text',
              subtitle: 'position_text',
              route: '/user-management/role-and-permission/detail/',
              routeReference: 'role.id',
            },
            { title: 'tokens.last_used_at', type: 'diffForHuman' },
            { title: 'join_date', type: 'date' },
          ],
          actionButton: true
        },
        data: [],
        meta: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
        },
        params: {
          limit: 10,
          sortby: 'updated_at.DESC',
          search: '',
          role_id: null,
          position_id: null,
        }
      },
      selected: [],
      roles: [],
      positions: [],
      request: {
        full_name: null,
        role_id: null,
        phone_number: null,
        positions: [],
        address: null,
        email: null,
        // password: null,
        // password_confirmation: null,
        join_date: null,
        // skill: null,
        avatar: null,
        nik: null
      },
      loading: false,
      maxFileSize: 2,
      allowedExtension: ['jpg', 'jpeg', 'png'],
      joinDatepickerOptions: {
        maxDate: this.$moment().format('YYYY-MM-DD'),
        format: 'YYYY-MM-DD',
        useCurrent: true,
      },
      loggedUser: JSON.parse(localStorage.getItem('user')),
      loading: false
    }
  },
  methods: {
    get( page = 1 ) {
      let _ = this
      _.loading = true
      _.table.data = []
      _.axios.get('/v1/user'
        + '?page=' + page
        + '&limit=' + _.table.params.limit
        + '&sortBy=' + _.table.params.sortby
        + '&search=' + _.table.params.search
        + '&role_id=' + (_.table.params.role_id ? _.table.params.role_id.id : '')
        + '&position_id=' + (_.table.params.position_id ? _.table.params.position_id.id : '')
      )
      .then( resp => {
        _.table.meta = resp.data.meta
        _.table.data = resp.data.data.map( function(x) {
          return {
            ...x,
            position_text: x.positions.filter( x => x.position ).map( z => z.position.title ).join(', ')
          }
        })

        setTimeout(() => {
          KTMenu.createInstances();
        }, 100);
        _.loading = false
      })
      .catch( err => {
        _.loading = false
        console.log(err)
        _.$swalToast.fire({
          icon: 'error',
          title: `Oops! Something went wrong (${err.response.status})`,
          text: err.response.data.errors
        })
      })
    },
    handleSearch() {
      this.get()
    },
    async handleUpload(event){
      let _ = this
      let file = event.target.files[0]
      const isUploaded = await _.upload( file, _.allowedExtension, _.maxFileSize, 'avatar' )

      if (isUploaded) {
        _.request.avatar = _.$apiBaseUrl + '/storage/' + isUploaded
      }
    },
    getRole() {
      let _ = this
      _.axios.get('/v1/role')
        .then( resp => {
          _.roles = resp.data.data
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    getPosition() {
      let _ = this
      _.axios.get('/v1/position')
        .then( resp => {
          _.positions = resp.data.data
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    async createUser() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      _.loading = true
      _.axios.post('/v1/user', _.request)
        .then(() => {
          _.loading = false
          _.hideModal()
          _.get()
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully add new user',
            text: `You have successfully add new user`
          })
          _.$root.$emit('updateContact')
        })
        .catch( err => {
          if ( typeof err.response.data.errors === 'object' && err.response.data.errors !== null ) {
            Object.keys(err.response.data.errors).forEach(elm => {
              _.$refs[elm].applyResult({
                errors: [err.response.data.errors[elm][0]],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
          _.loading = false
        })
    },
    deleteUser(data) {
      let _ = this
      _.$swal.fire({
        title: 'You are about to delete this user',
        text: 'Do you really want to delete this user? This process cannot be undone.',
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: 'No, cancel',
        confirmButtonText: 'Yes, sure',
        focusCancel: true
        }).then((result) => {
          if (result.value) {
            _.axios.delete(`/v1/user/${data.id}`)
              .then(() => {
                _.$swalToast.fire({
                  icon: 'success',
                  title: 'Successfully delete user',
                  text: 'You have successfully delete this user'
                })

                _.get()
                _.$root.$emit('updateContact')
              })
              .catch(err=>{
                _.$swalToast.fire({
                  icon: 'error',
                  title: `Oops! Something went wrong (${err.response.status})`,
                  text: err.response.data.errors
                })
              })
          }
      })
    },
    bulkDeleteUser() {
      let _ = this
      _.$swal.fire({
        title: 'You are about to delete a lot of users',
        text: 'Do you really want to delete those users? This process cannot be undone.',
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: 'No, cancel',
        confirmButtonText: 'Yes, sure',
        focusCancel: true
        }).then((result) => {
          if (result.value) {
            _.axios.post(`/v1/user/bulkDelete`, { selectedIds: _.selected })
              .then(() => {
                _.$swalToast.fire({
                  icon: 'success',
                  title: 'Successfully delete users',
                  text: 'You have successfully delete those users'
                })
                _.selected = []
                _.get()
                _.$root.$emit('resetSelected')
                _.$root.$emit('updateContact')
              })
              .catch(err=>{
                _.$swalToast.fire({
                  icon: 'error',
                  title: `Oops! Something went wrong (${err.response.status})`,
                  text: err.response.data.errors
                })
              })
          }
      })
    },
    async reset() {
      $('#kt_modal_add_user_scroll').animate({ scrollTop: 0 }, 'slow');

      this.request = {
        full_name: null,
        role_id: null,
        phone_number: null,
        positions: [],
        address: null,
        email: null,
        // password: null,
        // password_confirmation: null,
        join_date: null,
        // skill: null,
        avatar: null,
        nik: null
      },
      await this.$refs.observer.reset()
    },
  },
  mounted() {
    let _ = this
    // _.hideModal()

    _.get()
    _.getRole()
    _.getPosition()

    _.$root.$on("changePage", (data) => {
      _.get(data)
    });
    _.$root.$on("changeLimit", (data) => {
      _.table.params.limit = data
      _.get()
    });
    _.$root.$on("changeSortBy", (data) => {
      if ( data == '.' ) {
        _.table.params.sortby = 'id.desc'
      } else {
        _.table.params.sortby = data
      }
      _.get()
    });
    _.$root.$on("changeSelected", (data) => {
      _.selected = data
    });

    setTimeout(() => {
      KTMenu.createInstances();
      KTScroll.createInstances();
    }, 500);
  },
  beforeDestroy(){
    this.$root.$off("changePage");
    this.$root.$off("changeLimit");
    this.$root.$off("changeSortBy");
    this.$root.$off("changeSelected");
  }
}
</script>