<template>
  <div>
    <div class="card mb-6 mb-xl-9">
      <div class="card-body pt-9 pb-0">
        <div class="d-flex flex-wrap flex-sm-nowrap mb-6 w-100">
          <div>
            <div class="d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4"  v-if="!project.logo">
              <span class="fw-bold fs-2">{{ generateInitial(project.name )}}</span>
            </div>
            <img v-else class="d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4" :src="project.logo_link" alt="image"  style="object-fit:cover;"/>
          </div>
          <div class="flex-grow-1">
            <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div class="d-flex flex-column w-100">
                <div class="d-flex align-items-center mb-1 w-100 justify-content-between">
                  <a href="#" class="text-gray-800 text-hover-primary fs-2 fw-bold me-3">{{ project.name }}</a>
                  <span class="badge badge-light-primary me-auto" v-if="project.client">By {{ project.client.name }}</span>

                  <div class="symbol-group symbol-hover">
                    <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" 
                      :title="member.user.full_name" v-for="member in project.project_members.filter( x => x.user).slice(0, 8)" :key="member.id"
                      @click="$router.push('/user-management/user/detail/' + member.user_id)">
                      <span class="symbol-label bg-warning text-inverse-warning fw-bold" v-if="!member.user.avatar">{{ generateInitial(member.user.full_name )}}</span>
                      <img v-else :alt="member.user.full_name" :src="member.user.avatar" style="object-fit:cover;"/>
                    </div>
                    <router-link :to="'/project-management/project/detail/' + $route.params.id + '/member'" v-if="project.project_members.length > 8" class="symbol symbol-35px symbol-circle">
                      <span class="symbol-label bg-dark text-gray-300 fs-8 fw-bold">+{{ project.project_members.length - 8 }}</span>
                    </router-link>
                  </div>
                </div>
                <div class="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400">{{ project.description }}</div>
              </div>
            </div>
            <div class="d-flex flex-wrap justify-content-start">
              <div class="d-flex flex-wrap">
                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                  <div class="d-flex align-items-center">
                    <div class="fs-4 fw-bold" v-if="project.due_date">{{ project.due_date | moment('LL') }}</div>
                    <div class="fs-4 fw-bold" v-else>-</div>
                  </div>
                  <div class="fw-semibold fs-6 text-gray-400">Due Date</div>
                </div>
                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                  <div class="d-flex align-items-center align-self-center">
                    <div class="fs-4 fw-bold">{{ project.status ? project.status : '-' }}</div>
                  </div>
                  <div class="fw-semibold fs-6 text-gray-400">Status</div>
                </div>
                <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                  <div class="d-flex align-items-center align-self-center">
                    <div class="fs-4 fw-bold" v-if="project.project_timeline">{{ project.project_timeline.phase.name }} 
                      <span v-if="project.project_timeline.sub_phase">: {{ project.project_timeline.sub_phase }}</span>
                    </div>
                    <div class="fs-4 fw-bold" v-else>-</div>
                  </div>
                  <div class="fw-semibold fs-6 text-gray-400">Phase</div>
                </div>
              </div>
              <div class="me-6">
                <p class="mb-3">Categories:</p>
                <div class="d-flex me-7 justify-content-between">
                  <div class="symbol overflow-hidden symbol-35px me-2" v-for="project_tag in project.project_tags.filter( x => x.tag )" :key="project_tag.technology_id"
                      data-bs-toggle="tooltip" :title="project_tag.tag.title">
                      <span v-if="!project_tag.tag.logo" class="symbol-label fw-bold">{{ generateInitial(project_tag.tag.title) }}</span>
                      <img v-else :src="project_tag.tag.logo_link" :alt="project_tag.tag.logo" class="img-fluid" referrerpolicy="no-referrer" 
                        onerror="this.onerror=null;this.src='/assets/media/misc/image.png';"  style="object-fit:cover;"/>
                  </div>
                </div>
              </div>
              <div>
                <p class="mb-3">Frameworks:</p>
                <div class="d-flex me-7 justify-content-between">
                  <div class="symbol overflow-hidden symbol-35px me-2" v-for="project_technology in project.project_technologies.filter( x => x.technology )" :key="project_technology.technology_id"
                      data-bs-toggle="tooltip" :title="project_technology.technology.title">
                      <span v-if="!project_technology.technology.image" class="symbol-label fw-bold">{{ generateInitial(project_technology.technology.title) }}</span>
                      <img v-else :src="project_technology.technology.image_link" :alt="project_technology.technology.image" class="img-fluid" referrerpolicy="no-referrer" 
                        onerror="this.onerror=null;this.src='/assets/media/misc/image.png';"  style="object-fit:cover;"/>
                  </div>
                </div>
              </div>
              <div v-if="!loading && project.progress" class="h-4px w-100 bg-light mt-3" data-bs-toggle="tooltip" :title="`This project ${project.progress ? project.progress : 0}% completed`">
                <div class="bg-primary rounded h-4px" role="progressbar" :style="`width: ${project.progress ? project.progress : 0}%`" :aria-valuenow="project.progress ? project.progress : 0" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="separator"></div>
        <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold" style="
            flex-flow: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
          ">
          <li class="nav-item">
            <router-link class="nav-link text-active-primary py-5 me-6" :class="{ 'active': $route.params.tab == 'overview' }" 
              style="white-space: nowrap;"
              :to="'/project-management/project/detail/' + $route.params.id + '/overview'">Overview</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link text-active-primary py-5 me-6" :class="{ 'active': $route.params.tab == 'member' }" 
              style="white-space: nowrap;"
              :to="'/project-management/project/detail/' + $route.params.id + '/member'">Members</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link text-active-primary py-5 me-6" :class="{ 'active': $route.params.tab == 'timeline' }" 
              style="white-space: nowrap;"
              :to="'/project-management/project/detail/' + $route.params.id + '/timeline'">Timelines</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link text-active-primary py-5 me-6" :class="{ 'active': $route.params.tab == 'task' }" 
              style="white-space: nowrap;"
              :to="'/project-management/project/detail/' + $route.params.id + '/task'">Tasks</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link text-active-primary py-5 me-6" :class="{ 'active': $route.params.tab == 'file' }" 
              style="white-space: nowrap;"
              :to="'/project-management/project/detail/' + $route.params.id + '/file'">Files</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link text-active-primary py-5 me-6" :class="{ 'active': $route.params.tab == 'mom' }" 
              style="white-space: nowrap;"
              :to="'/project-management/project/detail/' + $route.params.id + '/mom'">MOMs</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link text-active-primary py-5 me-6" :class="{ 'active': $route.params.tab == 'client-feedback' }" 
              style="white-space: nowrap;"
              :to="'/project-management/project/detail/' + $route.params.id + '/client-feedback'">Client's Feedbacks</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link text-active-primary py-5 me-6" :class="{ 'active': $route.params.tab == 'payment-terms' }" 
              style="white-space: nowrap;"
              :to="'/project-management/project/detail/' + $route.params.id + '/payment-terms'">Payment Terms</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link text-active-primary py-5 me-6" :class="{ 'active': $route.params.tab == 'budget-expense' }" 
              style="white-space: nowrap;"
              :to="'/project-management/project/detail/' + $route.params.id + '/budget-expense'">Budgets & Expenses</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link text-active-primary py-5 me-6" :class="{ 'active': $route.params.tab == 'setting' }" 
              style="white-space: nowrap;"
              :to="'/project-management/project/detail/' + $route.params.id + '/setting'">Settings</router-link>
          </li>
        </ul>
      </div>
    </div>

    <Overview v-if="$route.params.tab == 'overview' && project.id"/>
    <Member v-if="$route.params.tab == 'member' && project.id" :members="project.project_members"/>
    <Timeline v-if="$route.params.tab == 'timeline' && project.id" :project="project"/>
    <Task v-if="$route.params.tab == 'task' && project.id" :members="project.project_members"/>
    <Document v-if="$route.params.tab == 'file' && project.id" :project="project"/>
    <MOM v-if="$route.params.tab == 'mom' && project.id" :members="project.project_members" :project="project"/>
    <ClientFeedback v-if="$route.params.tab == 'client-feedback' && project.id"/>
    <PaymentTerms v-if="$route.params.tab == 'payment-terms' && project.id"/>
    <BudgetExpense v-if="$route.params.tab == 'budget-expense' && project.id"/>
    <Setting v-if="$route.params.tab == 'setting' && project.id"/>
  </div>
</template>

<script>
import Overview from './Overview.vue'
import Member from './Member.vue'
import Timeline from './Timeline.vue'
import Task from './Task.vue'
import Document from './Document.vue'
import MOM from './MOM.vue'
import ClientFeedback from './ClientFeedback.vue'
import PaymentTerms from './PaymentTerms.vue'
import BudgetExpense from './BudgetExpense.vue'
import Setting from './Setting.vue'

export default {
  components: {
    Overview,
    Member,
    Timeline,
    Task,
    Document,
    MOM,
    ClientFeedback,
    PaymentTerms,
    BudgetExpense,
    Setting,
  },
  data() {
    return {
      project: {
        name: '',
        project_members: [],
        project_tags: [],
        project_technologies: [],
        client: {}
      },
      loading: false
    }
  },
  methods: {
    show() {
      let _ = this
      _.loading = true
      _.axios.get('/v1/project/' + _.$route.params.id)
      .then( resp => {
        _.project = resp.data.data
        _.loading = false
        _.$root.$emit('updateProject')
        $(document).ready(function() {
          $('[data-bs-toggle="tooltip"]').tooltip();
        });
      })
      .catch( err => {
        if (err.response.status == 403) {
          _.$router.push('/project-management/project')
        }
        _.loading = false
        _.$swalToast.fire({
          icon: 'error',
          title: `Oops! Something went wrong (${err.response.status})`,
          text: err.response.data.errors
        })
      })
    },
  },
  mounted() {
    this.show()

    this.$root.$on('updateProjectDetail', () => {
      this.show()
    })
  },
  watch: {
    '$route.params.id': function() {
      this.show()
    }
  },
  beforeDestroy(){
    this.$root.$off("updateProjectDetail");
  },
}
</script>