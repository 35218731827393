<template>
  <div>
    <div class="d-flex flex-wrap flex-stack pb-7">
      <div class="d-flex flex-wrap align-items-center my-1">
        <h3 class="fw-bold me-5 my-1">Tasks</h3>
        <div class="d-flex align-items-center position-relative my-1 me-3 ">
          <span class="svg-icon svg-icon-1 position-absolute ms-4">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
              <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
            </svg>
          </span>
          <input type="text" class="form-control w-250px ps-14" placeholder="Search by code or title" 
            v-debounce:300.lock="handleSearch" v-model="taskFilter.search"/>
        </div>
        <button type="button" class="btn btn-light-primary me-3" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
          <span class="svg-icon svg-icon-2">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor" />
            </svg>
          </span>
          Filter
        </button>
        <div class="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true">
          <div class="px-7 py-5">
            <div class="fs-5 text-dark fw-bold">Filter Options</div>
          </div>
          <div class="separator border-gray-200"></div>
          <div class="px-7 py-5" data-kt-user-table-filter="form">
            <div class="mb-3">
              <label class="form-label fs-6 fw-semibold">Assigned User:</label>
              <multiselect 
                track-by="id"
                label="full_name"
                placeholder="Select member"
                v-model="taskFilter.assign_to" 
                :options="members.map( x => x.user )"
                :close-on-select="true"
                :multiple="false"
                :searchable="true"
                :hide-selected="false"
                :showLabels="false"
                :allow-empty="true"
                @input="get()"
              ></multiselect>
            </div>
            <div class="mb-3">
              <label class="form-label fs-6 fw-semibold">Phase:</label>
              <multiselect 
                track-by="id"
                label="name"
                placeholder="Select project phase"
                v-model="taskFilter.project_timeline_id" 
                :options="timelines.filter( x => x.phase )"
                :close-on-select="true"
                :multiple="false"
                :searchable="true"
                :hide-selected="false"
                :showLabels="false"
                :custom-label="customLabel"
                :allow-empty="true"
                @input="get()"
              ></multiselect>
            </div>
            <div class="mb-3">
              <label class="form-label fs-6 fw-semibold">Priority:</label>
              <multiselect 
                placeholder="Select task priority"
                v-model="taskFilter.priority" 
                :options="['High', 'Medium', 'Normal', 'Low']"
                :close-on-select="true"
                :multiple="false"
                :searchable="true"
                :hide-selected="false"
                :showLabels="false"
                :allow-empty="true"
                @input="get()"
              ></multiselect>
            </div>
            <div class="d-flex justify-content-end">
              <button type="reset" class="btn btn-light btn-active-light-primary fw-semibold me-2 px-6" data-kt-menu-dismiss="true" data-kt-user-table-filter="reset" 
                @click="
                  taskFilter.assign_to = null, 
                  taskFilter.project_timeline_id = null, 
                  taskFilter.priority = null,
                  get()">Reset</button>
            </div>
          </div>
        </div>
        <input type="checkbox" name="" id="showSubtask" class="form-check-input me-3 cursor-pointer" v-model="showSubtask">
        <label for="showSubtask" class="cursor-pointer">{{ showSubtask ? 'Hide' : 'Show' }} Subtasks</label>
      </div>
      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-light-primary me-3" data-bs-toggle="modal" data-bs-target="#kt_modal_import_prd" @click="resetImportPRD()">
          <span class="svg-icon svg-icon-2">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.3" d="M5 16C3.3 16 2 14.7 2 13C2 11.3 3.3 10 5 10H5.1C5 9.7 5 9.3 5 9C5 6.2 7.2 4 10 4C11.9 4 13.5 5 14.3 6.5C14.8 6.2 15.4 6 16 6C17.7 6 19 7.3 19 9C19 9.4 18.9 9.7 18.8 10C18.9 10 18.9 10 19 10C20.7 10 22 11.3 22 13C22 14.7 20.7 16 19 16H5ZM8 13.6H16L12.7 10.3C12.3 9.89999 11.7 9.89999 11.3 10.3L8 13.6Z" fill="currentColor"/>
              <path d="M11 13.6V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19V13.6H11Z" fill="currentColor"/>
            </svg>
          </span>
          Import PRD
        </button>
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_task" @click="reset()">
          Add New Task
        </button>
      </div>
    </div>
    <scroll-shadow class="w-100">
      <div class="d-flex" style="flex-wrap: nowrap;overflow-x: auto;">
        <div class="col-md-4 col-lg-12 col-xl-3 me-4 col-12 bg-secondary pt-4 pe-4 ps-4 pb-2" style="border-radius: 0.85rem; height: fit-content;" v-for="(status, index) in statuses" :key="index">
          <div class="mb-4">
            <div class="d-flex flex-stack mb-3">
              <div class="fw-bold fs-5">{{ status.name }}
              <span class="fs-6 text-gray-400 ms-2">{{ getModulesByStatus(status).length | numFormat }}</span></div>
            </div>
            <div class="h-3px w-100" :class="status.color"></div>
          </div>
          <scroll-shadow class="w-100">
            <div style="max-height: 700px; overflow-y:auto;" >
              <draggable class="list-group" :list="getModulesByStatus(status)" group="task" @change="log($event, status)">
                <button class="btn btn-bg-light btn-active-dark text-start p-4 mb-4" 
                  v-for="(task, index) in getModulesByStatus(status)" :key="index"
                  @click="getTaskByTaskId(task.id)" data-bs-toggle="modal" data-bs-target="#kt_modal_view_task" :id="'cardTask-' + task.id">
                  <div class="translate-middle top-10 rounded-circle border border-4 border-body h-20px w-20px bg-danger" style="float:right; margin-right: -20px;" 
                    v-if="task.has_update && task.is_watching"></div>
                  <div class="fs-8 fw-semibold text-gray-600" v-if="task.project_timeline && task.project_timeline.phase">
                    {{ task.project_timeline.phase.name }}
                    <span v-if="task.project_timeline.sub_phase">: {{ task.project_timeline.sub_phase }}</span>
                  </div>
                  <p class="fs-6">[{{task.code}}] {{ removeHTMLStripTag(task.title) }}</p>
                  <div class="d-flex flex-stack flex-wrapr">
                    <div class="d-flex">
                      <div class="me-2">
                        <span class="svg-icon svg-icon-4">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z" fill="currentColor"></path>
                            <rect x="6" y="12" width="7" height="2" rx="1" fill="currentColor"></rect>
                            <rect x="6" y="7" width="12" height="2" rx="1" fill="currentColor"></rect>
                          </svg>
                        </span>
                        <span class="ms-1 fs-8 fw-bold text-gray-600">{{ task.thread_count | numFormat }}</span>
                      </div>
                      <div class="me-2" data-bs-toggle="tooltip" 
                        :title="diffForHuman(task.project_timeline.end_date)">
                        <span class="svg-icon svg-icon-4">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="currentColor"/>
                            <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="currentColor"/>
                          </svg>
                        </span>
                        <span class="ms-1 fs-8 fw-bold text-gray-600">{{ task.project_timeline.end_date | moment('MMM DD') }}</span>
                      </div>
                      <div
                        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" data-bs-toggle="tooltip" 
                        :title="capitalize(task.priority)">
                        <span class="svg-icon svg-icon-4" 
                          :class="{ 
                            'text-danger': task.priority == 'high', 
                            'text-warning': task.priority == 'medium', 
                            'text-info': task.priority == 'normal', 
                            'text-secondary': task.priority == 'low', 
                          }">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M6 22H4V3C4 2.4 4.4 2 5 2C5.6 2 6 2.4 6 3V22Z" fill="currentColor"/>
                            <path d="M18 14H4V4H18C18.8 4 19.2 4.9 18.7 5.5L16 9L18.8 12.5C19.3 13.1 18.8 14 18 14Z" fill="currentColor"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                    <div class="symbol-group symbol-hover" v-if="task.user_assigned">
                      <div class="symbol symbol-30px symbol-circle" data-bs-toggle="tooltip" 
                        :title="task.user_assigned.full_name"
                        @click="$router.push('/user-management/user/detail/' + task.user_assigned.id)">
                        <span class="symbol-label bg-warning text-inverse-warning fw-bold" v-if="!task.user_assigned.avatar">{{ generateInitial(task.user_assigned.full_name )}}</span>
                        <img v-else :alt="task.user_assigned.full_name" :src="task.user_assigned.avatar" style="object-fit:cover;"/>
                      </div>
                    </div>
                  </div>
                </button>
                <!-- <button class="btn btn-active-primary text-start p-2 w-100" id="showModal" data-bs-toggle="modal" data-bs-target="#kt_modal_add_task" @click="reset()">
                  + Add New Task
                </button> -->
              </draggable>
            </div>
          </scroll-shadow>
        </div>
      </div>
    </scroll-shadow>
    <div class="modal fade" id="kt_modal_add_task" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-md">
				<div class="modal-content">
					<div class="modal-header">
						<h2>{{ detail ? 'Edit' : 'Add New'}} Task</h2>
						<div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
							<span class="svg-icon svg-icon-1">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
									<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
								</svg>
							</span>
						</div>
					</div>
					<div class="modal-body py-lg-10 px-lg-10">
						<ValidationObserver ref="observer">
              <form class="form" @submit.prevent="createTask()">
                <div class="fv-row mb-7">
                  <ValidationProvider name="Phase" v-slot="{ errors }" :rules="{ required: true }" ref="project_timeline_id">
                    <label class="fw-semibold required fs-6 mb-2">Project Phase</label>
                    <multiselect 
                      track-by="id"
                      label="name"
                      placeholder="Select Phase"
                      v-model="request.project_timeline_id" 
                      :options="timelines.filter( x => x.phase )"
                      :allow-empty="false"
                      :close-on-select="true"
                      :multiple="false"
                      :searchable="true"
                      :hide-selected="false"
                      :showLabels="false"
                      :custom-label="customLabel"
                    ></multiselect>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="fv-row mb-7">
                  <ValidationProvider name="Task title" v-slot="{ errors }" :rules="{ required: true }" ref="title">
                    <label class="d-flex align-items-center fs-5 fw-semibold mb-2">
                      <span class="required">Task Title</span>
                      <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Specify your unique task title"></i>
                    </label>
                    <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Task title" v-model="request.title"/>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="fv-row mb-7">
                  <ValidationProvider name="User stories" v-slot="{ errors }" :rules="{ required: true }" ref="user_stories">
                    <label class="d-flex align-items-center fs-5 fw-semibold mb-2">
                      <span class="required">User Stories</span>
                    </label>
                    <textarea class="form-control form-control-lg form-control-solid" placeholder="Describe user stories" value="" cols="30" rows="5" v-model="request.user_stories"></textarea>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="fv-row">
                  <ValidationProvider name="Assigned user" v-slot="{ errors }" :rules="{ }" ref="assign_to">
                    <label class="d-flex align-items-center fs-5 fw-semibold mb-2">
                      <span class="">Assigned user</span>
                    </label>
                    <multiselect 
                      track-by="id"
                      placeholder="Select user"
                      v-model="request.assign_to" 
                      :options="members"
                      :allow-empty="false"
                      :close-on-select="true"
                      :multiple="false"
                      :searchable="true"
                      :hide-selected="false"
                      :showLabels="false"
                      :custom-label="customLabelUser"
                    ></multiselect>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="d-flex flex-center pt-15">
                  <button type="button" class="btn btn-light me-3" data-bs-dismiss="modal">Discard</button>
                  <button type="submit" class="btn btn-primary">
                    <span class="indicator-label" v-if="!loading">Submit</span>
                    <span class="indicator-progress" v-else>
                      Please wait...
                      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                </div>
              </form>
            </ValidationObserver>
					</div>
				</div>
			</div>
		</div>  
    <div class="modal fade" id="kt_modal_view_task" tabindex="-1" aria-hidden="true" v-if="taskDetail">
			<div class="modal-dialog modal-dialog-centered modal-xl">
				<div class="modal-content">
					<div class="modal-header" style="padding: 5px 5px 5px 10px; background: var(--kt-page-bg)">
            <div class="d-flex">
              <div class="badge badge-light" v-if="taskDetail.project_timeline && taskDetail.project_timeline.phase">
                {{ taskDetail.project_timeline.phase.name }}
                <span v-if="taskDetail.project_timeline.sub_phase">: {{ taskDetail.project_timeline.sub_phase }}</span>
              </div>

              <div v-if="taskDetail.parent_id" class="ms-1 cursor-pointer" @click="setDetail(tasks[getTaskDetail(taskDetail.parent_id)])">/
                <div class="badge badge-light" v-if="taskDetail.parent">
                  [{{taskDetail.parent.code}}] {{ taskDetail.parent.title }}
                </div>
              </div>
            </div>

						<div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
							<span class="svg-icon svg-icon-1">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
									<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
								</svg>
							</span>
						</div>
					</div>
          <div class="col" style="border-bottom: 1px solid var(--kt-modal-header-border-color); ">
            <div class="d-flex">
              <div class="col-6 p-4" style="border-right: 1px solid var(--kt-modal-header-border-color); ">
                <div class="d-flex">
                  <multiselect 
                    track-by="reference"
                    label="name"
                    placeholder="Select Status"
                    v-model="taskDetail.current_status"
                    :options="statuses"
                    :allow-empty="false"
                    :close-on-select="true"
                    :multiple="false"
                    :searchable="false"
                    :hide-selected="false"
                    :showLabels="false"
                    @input="updateStatus()"
                    class="me-4 fit-content"
                  ></multiselect>
                  <div class="symbol-group symbol-hover my-1">
                    <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" v-if="taskDetail.user_assigned" 
                      :title="taskDetail.user_assigned.full_name"
                      data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start">
                      <span class="symbol-label bg-warning text-inverse-warning fw-bold" v-if="!taskDetail.user_assigned.avatar">{{ generateInitial(taskDetail.user_assigned.full_name )}}</span>
                      <img v-else :alt="taskDetail.user_assigned.full_name" :src="taskDetail.user_assigned.avatar" style="object-fit:cover;"/>
                    </div>
                    <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" v-else 
                      title="Assign User"
                      data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start">
                      <img :src="'/assets/media/avatars/blank.png'" style="object-fit:cover;"/>
                    </div>
                    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold fit-content py-3" data-kt-menu="true" style="">
                      <div class="menu-item px-3">
                        <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">MEMBERS</div>
                      </div>
                      <div class="menu-item ps-3 px-3" v-for="(member, index) in members" :key="index">
                        <a class="menu-link" :class="{ 'active': member.user.id == taskDetail.assign_to }" 
                          @click="taskDetail.assign_to = member.user.id, taskDetail.user_assigned = member.user, updateStatus()">
                          <div class="symbol symbol-35px symbol-circle me-4 ms-1">
                            <span class="symbol-label bg-warning text-inverse-warning fw-bold" v-if="!member.user.avatar">{{ generateInitial(member.user.full_name )}}</span>
                            <img v-else :alt="member.user.full_name" :src="member.user.avatar" style="object-fit:cover;"/>
                          </div>
                          {{member.user.full_name}}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div id="priorityButton" class="ms-4 align-self-center ps-4" style="border-left: 1px solid var(--kt-modal-header-border-color); ">
                    <div class="btn btn-sm btn-icon btn-outline btn-outline-dashed btn-circle" 
                      :class="{ 
                        'btn-color-danger btn-outline-danger': taskDetail.priority == 'high', 
                        'btn-color-warning btn-outline-warning': taskDetail.priority == 'medium', 
                        'btn-color-info btn-outline-info': taskDetail.priority == 'normal', 
                        'btn-color-secondary btn-outline-secondary': taskDetail.priority == 'low', 
                      }"
                      data-bs-toggle="tooltip" :title="capitalize(taskDetail.priority)"
                      data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start">
                      <span class="svg-icon svg-icon-1">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M6 22H4V3C4 2.4 4.4 2 5 2C5.6 2 6 2.4 6 3V22Z" fill="currentColor"/>
                          <path d="M18 14H4V4H18C18.8 4 19.2 4.9 18.7 5.5L16 9L18.8 12.5C19.3 13.1 18.8 14 18 14Z" fill="currentColor"/>
                        </svg>
                      </span>
                    </div>
                    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3" data-kt-menu="true" style="">
                      <div class="menu-item px-3">
                        <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Priority</div>
                      </div>
                      <div class="menu-item px-3">
                        <a class="menu-link p-0" @click="taskDetail.priority = 'high', updateStatus()">
                          <div class="btn btn-sm btn-icon btn-color-danger">
                            <span class="svg-icon svg-icon-1">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M6 22H4V3C4 2.4 4.4 2 5 2C5.6 2 6 2.4 6 3V22Z" fill="currentColor"/>
                                <path d="M18 14H4V4H18C18.8 4 19.2 4.9 18.7 5.5L16 9L18.8 12.5C19.3 13.1 18.8 14 18 14Z" fill="currentColor"/>
                              </svg>
                            </span>
                          </div>
                          High
                        </a>
                      </div>
                      <div class="menu-item px-3">
                        <a class="menu-link p-0" @click="taskDetail.priority = 'medium', updateStatus()">
                          <div class="btn btn-sm btn-icon btn-color-warning">
                            <span class="svg-icon svg-icon-1">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M6 22H4V3C4 2.4 4.4 2 5 2C5.6 2 6 2.4 6 3V22Z" fill="currentColor"/>
                                <path d="M18 14H4V4H18C18.8 4 19.2 4.9 18.7 5.5L16 9L18.8 12.5C19.3 13.1 18.8 14 18 14Z" fill="currentColor"/>
                              </svg>
                            </span>
                          </div>
                          Medium
                        </a>
                      </div>
                      <div class="menu-item px-3">
                        <a class="menu-link p-0" @click="taskDetail.priority = 'normal', updateStatus()">
                          <div class="btn btn-sm btn-icon btn-color-info">
                            <span class="svg-icon svg-icon-1">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M6 22H4V3C4 2.4 4.4 2 5 2C5.6 2 6 2.4 6 3V22Z" fill="currentColor"/>
                                <path d="M18 14H4V4H18C18.8 4 19.2 4.9 18.7 5.5L16 9L18.8 12.5C19.3 13.1 18.8 14 18 14Z" fill="currentColor"/>
                              </svg>
                            </span>
                          </div>
                          Normal
                        </a>
                      </div>
                      <div class="menu-item px-3">
                        <a class="menu-link p-0" @click="taskDetail.priority = 'low', updateStatus()">
                          <div class="btn btn-sm btn-icon btn-color-secondary">
                            <span class="svg-icon svg-icon-1">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M6 22H4V3C4 2.4 4.4 2 5 2C5.6 2 6 2.4 6 3V22Z" fill="currentColor"/>
                                <path d="M18 14H4V4H18C18.8 4 19.2 4.9 18.7 5.5L16 9L18.8 12.5C19.3 13.1 18.8 14 18 14Z" fill="currentColor"/>
                              </svg>
                            </span>
                          </div>
                          Low
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 p-4">
                <div class="d-flex justify-content-between">
                  <div class="d-flex">
                    <div class="me-4 pe-4" style="border-right: 1px solid var(--kt-modal-header-border-color); ">
                      <label for="" class="fs-8">Created Date</label>
                      <p class="fw-bold text-nowrap mb-0">{{ taskDetail.created_at | moment('LL') }}</p>
                    </div>
                    <div class="">
                      <label for="" class="fs-8">Due Date</label>
                      <p class="fw-bold text-nowrap mb-0">{{ taskDetail.project_timeline.end_date | moment('LL') }}</p>
                    </div>
                  </div>
                  <button type="button" title="Remove Task" 
                    class="btn btn-icon btn-light btn-active-light-primary align-self-start" @click="deleteTask()">
                    <span class="svg-icon svg-icon-3">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path>
                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path>
                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path>
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="d-flex">
              <div class="col-6 p-4" style="border-right: 1px solid var(--kt-modal-header-border-color); ">
                <scroll-shadow class="w-100">
                  <div style="height: 70vh; overflow-y:auto;">
                    <h3 v-if="taskDetail.title && !quillTitleShowed"
                      v-html="`[${taskDetail.code}] ${taskDetail.title}`" 
                      @click="quillTitleShowed = true"></h3>
                    <quill-editor v-else-if="taskDetail.title"
                      v-model="taskDetail.title"
                      :options="{
                        modules: {
                          toolbar: false
                        },
                        theme: 'snow'
                      }"
                      @blur="onBlurQuill('title')"
                      @ready="onReadyQuill($event)"
                      class="quillTitle">
                    </quill-editor>

                    <div v-if="!quillUserStoryShowed && !taskDetail.user_stories" @click="quillUserStoryShowed = true"
                      class="btn btn-active-light border border-gray-300 border-dashed rounded w-100 mb-3 text-start h-100px text-muted fs-6 px-4">
                      User stories...
                    </div>
                    <div class="fw-semibold mb-4 text-gray-800 ql-editor h-auto p-0 cursor-pointer" 
                      v-html="taskDetail.user_stories" 
                      @click="quillUserStoryShowed = true"
                      v-if="!quillUserStoryShowed"></div>
                    <div class="card bg-light mb-4" v-else>
                      <div class="card-body pl-4 pe-4 ps-4 pb-4 mt-2 pt-0"> 
                        <quill-editor
                          v-model="taskDetail.user_stories"
                          :options="quillOptions"
                          @ready="onReadyQuill($event)">
                        </quill-editor>
                        <div class="d-flex justify-content-between mt-3">
                          <div class="d-flex">
                            <button class="btn btn-sm btn-primary me-2" 
                              :disabled="!taskDetail.user_stories || loading"
                              @click="updateStatus(), quillUserStoryShowed = null"
                            >Save</button>
                            <button type="button" title="Discard" 
                              class="btn btn-icon btn-sm align-self-start" @click="quillUserStoryShowed = null, resetTaskDetail()">
                              <span class="svg-icon svg-icon-3">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.3" d="M6 19.7C5.7 19.7 5.5 19.6 5.3 19.4C4.9 19 4.9 18.4 5.3 18L18 5.3C18.4 4.9 19 4.9 19.4 5.3C19.8 5.7 19.8 6.29999 19.4 6.69999L6.7 19.4C6.5 19.6 6.3 19.7 6 19.7Z" fill="currentColor"/>
                                  <path d="M18.8 19.7C18.5 19.7 18.3 19.6 18.1 19.4L5.40001 6.69999C5.00001 6.29999 5.00001 5.7 5.40001 5.3C5.80001 4.9 6.40001 4.9 6.80001 5.3L19.5 18C19.9 18.4 19.9 19 19.5 19.4C19.3 19.6 19 19.7 18.8 19.7Z" fill="currentColor"/>
                                </svg>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="accordion accordion-icon-toggle" id="kt_accordion_2">
                      <div name="acceptance-criteria">
                        <div class="accordion-header py-3 d-flex" data-bs-toggle="collapse" data-bs-target="#kt_accordion_2_item_1">
                          <span class="accordion-icon">
                            <span class="svg-icon svg-icon-3">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor" />
                              </svg>
                            </span>
                          </span>
                          <div class="ms-4">
                            <div class="text-gray-800 fw-bold">Acceptance Criteria
                              <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="The conditions that a software product must meet to be accepted by a user, a customer, or other systems"></i>
                            </div>
                          </div> 
                        </div>
                        <div id="kt_accordion_2_item_1" class="collapse show ps-10" data-bs-parent="#kt_accordion_2">
                          <div v-if="!quillAcceptanceCriteriaShowed && !taskDetail.acceptance_criteria" @click="quillAcceptanceCriteriaShowed = true"
                            class="btn btn-active-light border border-gray-300 border-dashed rounded w-100 mb-3 text-start h-100px text-muted fs-6 px-4">
                            Acceptance criteria...
                          </div>
                          <div class="fw-semibold mb-4 text-gray-800 ql-editor h-auto p-0 cursor-pointer" 
                            v-html="taskDetail.acceptance_criteria" 
                            @click="quillAcceptanceCriteriaShowed = true"
                            v-if="!quillAcceptanceCriteriaShowed"></div>
                          <div class="card bg-light mb-4" v-else>
                            <div class="card-body pl-4 pe-4 ps-4 pb-4 mt-2 pt-0"> 
                              <quill-editor
                                v-model="taskDetail.acceptance_criteria"
                                :options="quillOptions"
                                @ready="onReadyQuill($event)">
                              </quill-editor>
                              <div class="d-flex justify-content-between mt-3">
                                <div class="d-flex">
                                  <button class="btn btn-sm btn-primary me-2" 
                                    :disabled="!taskDetail.acceptance_criteria || loading"
                                    @click="updateStatus(), quillAcceptanceCriteriaShowed = null"
                                  >Save</button>
                                  <button type="button" title="Discard" 
                                    class="btn btn-icon btn-sm align-self-start" @click="quillAcceptanceCriteriaShowed = null, resetTaskDetail()">
                                    <span class="svg-icon svg-icon-3">
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M6 19.7C5.7 19.7 5.5 19.6 5.3 19.4C4.9 19 4.9 18.4 5.3 18L18 5.3C18.4 4.9 19 4.9 19.4 5.3C19.8 5.7 19.8 6.29999 19.4 6.69999L6.7 19.4C6.5 19.6 6.3 19.7 6 19.7Z" fill="currentColor"/>
                                        <path d="M18.8 19.7C18.5 19.7 18.3 19.6 18.1 19.4L5.40001 6.69999C5.00001 6.29999 5.00001 5.7 5.40001 5.3C5.80001 4.9 6.40001 4.9 6.80001 5.3L19.5 18C19.9 18.4 19.9 19 19.5 19.4C19.3 19.6 19 19.7 18.8 19.7Z" fill="currentColor"/>
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="separator separator-dashed"></div>
                      <div name="notes">
                        <div class="accordion-header py-3 d-flex collapsed" data-bs-toggle="collapse" data-bs-target="#kt_accordion_2_item_2">
                          <span class="accordion-icon">
                            <span class="svg-icon svg-icon-3">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor" />
                              </svg>
                            </span>
                          </span>
                          <div class="ms-4">
                            <div class="text-gray-800 fw-bold">Notes
                              <!-- <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="The conditions that a software product must meet to be accepted by a user, a customer, or other systems"></i> -->
                            </div>
                          </div> 
                        </div>
                        <div id="kt_accordion_2_item_2" class="collapse ps-10" data-bs-parent="#kt_accordion_2">
                          <div v-if="!quillNotesShowed && !taskDetail.notes" @click="quillNotesShowed = true"
                            class="btn btn-active-light border border-gray-300 border-dashed rounded w-100 mb-3 text-start h-100px text-muted fs-6 px-4">
                            Notes...
                          </div>
                          <div class="fw-semibold mb-4 text-gray-800 ql-editor h-auto p-0 cursor-pointer" 
                            v-html="taskDetail.notes" 
                            @click="quillNotesShowed = true"
                            v-if="!quillNotesShowed"></div>
                          <div class="card bg-light mb-4" v-else>
                            <div class="card-body pl-4 pe-4 ps-4 pb-4 mt-2 pt-0"> 
                              <quill-editor
                                v-model="taskDetail.notes"
                                :options="quillOptions"
                                @ready="onReadyQuill($event)">
                              </quill-editor>
                              <div class="d-flex justify-content-between mt-3">
                                <div class="d-flex">
                                  <button class="btn btn-sm btn-primary me-2" 
                                    :disabled="!taskDetail.notes || loading"
                                    @click="updateStatus(), quillNotesShowed = null"
                                  >Save</button>
                                  <button type="button" title="Discard" 
                                    class="btn btn-icon btn-sm align-self-start" @click="quillNotesShowed = null, resetTaskDetail()">
                                    <span class="svg-icon svg-icon-3">
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M6 19.7C5.7 19.7 5.5 19.6 5.3 19.4C4.9 19 4.9 18.4 5.3 18L18 5.3C18.4 4.9 19 4.9 19.4 5.3C19.8 5.7 19.8 6.29999 19.4 6.69999L6.7 19.4C6.5 19.6 6.3 19.7 6 19.7Z" fill="currentColor"/>
                                        <path d="M18.8 19.7C18.5 19.7 18.3 19.6 18.1 19.4L5.40001 6.69999C5.00001 6.29999 5.00001 5.7 5.40001 5.3C5.80001 4.9 6.40001 4.9 6.80001 5.3L19.5 18C19.9 18.4 19.9 19 19.5 19.4C19.3 19.6 19 19.7 18.8 19.7Z" fill="currentColor"/>
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="separator separator-dashed"></div>
                      <div name="positive-cases">
                        <div class="accordion-header py-3 d-flex collapsed" data-bs-toggle="collapse" data-bs-target="#kt_accordion_2_item_3">
                          <span class="accordion-icon">
                            <span class="svg-icon svg-icon-3">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor" />
                              </svg>
                            </span>
                          </span>
                          <div class="ms-4">
                            <div class="text-gray-800 fw-bold">Positive Cases
                              <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" 
                                title="A way to check if the app is doing what it expects by providing valid data as input"></i>
                            </div>
                          </div> 
                        </div>
                        <div id="kt_accordion_2_item_3" class="collapse ps-10" data-bs-parent="#kt_accordion_2">
                          <ol>
                            <li v-for="(task, index) in taskDetail.positive_case" :key="index">
                              <div class="fw-semibold text-gray-800 mb-4 ql-editor h-auto p-0 cursor-pointer" 
                                v-html="task" 
                                @click="quillPositiveCaseShowedIndex = index"
                                v-if="quillPositiveCaseShowedIndex != index"></div>

                                <div class="card bg-light mb-4" v-if="quillPositiveCaseShowedIndex == index">
                                  <div class="card-body p-4">
                                    <quill-editor
                                      v-model="taskDetail.positive_case[index]"
                                      :options="{
                                        modules: {
                                          toolbar: [
                                            ['bold', 'italic', 'underline'],
                                            ['link']
                                          ],
                                          keyboard: {
                                            bindings: {
                                              enter: {
                                                key: 13,
                                                handler: function(range, context) {
                                                  updateStatus(),
                                                  quillPositiveCaseShowedIndex = null
                                                }
                                              }
                                            }
                                          }
                                        },
                                        theme: 'bubble',
                                      }"
                                      @change="onEditorChange($event, 'positive_case', index)"
                                      @ready="onReadyQuill($event)"
                                      class="mb-4">
                                    </quill-editor>
                                    <div class="d-flex justify-content-between">
                                      <div class="d-flex">
                                        <button class="btn btn-sm btn-primary me-2" 
                                          :disabled="!taskDetail.positive_case[index] || loading"
                                          @click="updateStatus(), quillPositiveCaseShowedIndex = null"
                                        >Save</button>
                                        <button type="button" title="Discard" 
                                          class="btn btn-icon btn-sm align-self-start" @click="quillPositiveCaseShowedIndex = null, resetTaskDetail()">
                                          <span class="svg-icon svg-icon-3">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path opacity="0.3" d="M6 19.7C5.7 19.7 5.5 19.6 5.3 19.4C4.9 19 4.9 18.4 5.3 18L18 5.3C18.4 4.9 19 4.9 19.4 5.3C19.8 5.7 19.8 6.29999 19.4 6.69999L6.7 19.4C6.5 19.6 6.3 19.7 6 19.7Z" fill="currentColor"/>
                                              <path d="M18.8 19.7C18.5 19.7 18.3 19.6 18.1 19.4L5.40001 6.69999C5.00001 6.29999 5.00001 5.7 5.40001 5.3C5.80001 4.9 6.40001 4.9 6.80001 5.3L19.5 18C19.9 18.4 19.9 19 19.5 19.4C19.3 19.6 19 19.7 18.8 19.7Z" fill="currentColor"/>
                                            </svg>
                                          </span>
                                        </button>
                                      </div>
                                      <button type="button" title="Remove Case" 
                                        class="btn btn-icon btn-sm btn-danger align-self-start" @click="removePositiveCase(index)">
                                        <span class="svg-icon svg-icon-3">
                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path>
                                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path>
                                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path>
                                          </svg>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                            </li>
                            <li v-if="showNewPositiveCaseForm">
                              <div class="card bg-light">
                                  <div class="card-body p-4">
                                    <quill-editor
                                      v-model="newPositiveCaseForm"
                                      :options="{
                                        modules: {
                                          toolbar: [
                                            ['bold', 'italic', 'underline'],
                                            ['link']
                                          ],
                                          keyboard: {
                                            bindings: {
                                              enter: {
                                                key: 13,
                                                handler: function(range, context) {
                                                  addNewPositiveCase()
                                                }
                                              }
                                            }
                                          }
                                        },
                                        theme: 'bubble'
                                      }"
                                      @change="onEditorChange($event, 'positive_case')"
                                      @ready="onReadyQuill($event)"
                                      class="mb-4">
                                    </quill-editor>
                                    <div class="d-flex justify-content-between">
                                      <div class="d-flex">
                                        <button class="btn btn-sm btn-primary me-2" 
                                          @click="addNewPositiveCase()"
                                          :disabled="!newPositiveCaseForm || loading">Save</button>
                                        <button type="button" title="Discard" 
                                          class="btn btn-icon btn-sm align-self-start" @click="showNewPositiveCaseForm = false, newPositiveCaseForm = null">
                                          <span class="svg-icon svg-icon-3">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path opacity="0.3" d="M6 19.7C5.7 19.7 5.5 19.6 5.3 19.4C4.9 19 4.9 18.4 5.3 18L18 5.3C18.4 4.9 19 4.9 19.4 5.3C19.8 5.7 19.8 6.29999 19.4 6.69999L6.7 19.4C6.5 19.6 6.3 19.7 6 19.7Z" fill="currentColor"/>
                                              <path d="M18.8 19.7C18.5 19.7 18.3 19.6 18.1 19.4L5.40001 6.69999C5.00001 6.29999 5.00001 5.7 5.40001 5.3C5.80001 4.9 6.40001 4.9 6.80001 5.3L19.5 18C19.9 18.4 19.9 19 19.5 19.4C19.3 19.6 19 19.7 18.8 19.7Z" fill="currentColor"/>
                                            </svg>
                                          </span>
                                        </button>
                                      </div>
                                      <!-- <button type="button" title="Remove Task" 
                                        class="btn btn-icon btn-sm btn-danger align-self-start">
                                        <span class="svg-icon svg-icon-3">
                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path>
                                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path>
                                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path>
                                          </svg>
                                        </span>
                                      </button> -->
                                    </div>
                                  </div>
                                </div>
                            </li>
                          </ol>
                          <button class="btn btn-sm btn-light-primary mb-4" 
                            v-if="!showNewPositiveCaseForm" 
                            @click="showNewPositiveCaseForm = true">
                            Add New Case
                          </button>
                        </div>
                      </div>
                      <div class="separator separator-dashed"></div>
                      <div name="negative-cases">
                        <div class="accordion-header py-3 d-flex collapsed" data-bs-toggle="collapse" data-bs-target="#kt_accordion_2_item_4">
                          <span class="accordion-icon">
                            <span class="svg-icon svg-icon-3">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor" />
                              </svg>
                            </span>
                          </span>
                          <div class="ms-4">
                            <div class="text-gray-800 fw-bold">Negative Cases
                              <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" 
                                title="A way to check if the app is doing what it expects by providing valid data as input"></i>
                            </div>
                          </div> 
                        </div>
                        <div id="kt_accordion_2_item_4" class="collapse ps-10" data-bs-parent="#kt_accordion_2">
                          <ol>
                            <li v-for="(task, index) in taskDetail.negative_case" :key="index">
                              <div class="fw-semibold text-gray-800 mb-4 ql-editor h-auto p-0 cursor-pointer" 
                                v-html="task" 
                                @click="quillNegativeCaseShowedIndex = index"
                                v-if="quillNegativeCaseShowedIndex != index"></div>

                                <div class="card bg-light mb-4" v-if="quillNegativeCaseShowedIndex == index">
                                  <div class="card-body p-4">
                                    <quill-editor
                                      v-model="taskDetail.negative_case[index]"
                                      :options="{
                                        modules: {
                                          toolbar: [
                                            ['bold', 'italic', 'underline'],
                                            ['link']
                                          ],
                                          keyboard: {
                                            bindings: {
                                              enter: {
                                                key: 13,
                                                handler: function(range, context) {
                                                  updateStatus(),
                                                  quillNegativeCaseShowedIndex = null
                                                }
                                              }
                                            }
                                          }
                                        },
                                        theme: 'bubble',
                                      }"
                                      @change="onEditorChange($event, 'negative_case', index)"
                                      @ready="onReadyQuill($event)"
                                      class="mb-4">
                                    </quill-editor>
                                    <div class="d-flex justify-content-between">
                                      <div class="d-flex">
                                        <button class="btn btn-sm btn-primary me-2" 
                                          @click="updateStatus(), quillNegativeCaseShowedIndex = null"
                                          :disabled="!taskDetail.negative_case[index] || loading">Save</button>
                                        <button type="button" title="Discard" 
                                          class="btn btn-icon btn-sm align-self-start" @click="quillNegativeCaseShowedIndex = null, resetTaskDetail()">
                                          <span class="svg-icon svg-icon-3">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path opacity="0.3" d="M6 19.7C5.7 19.7 5.5 19.6 5.3 19.4C4.9 19 4.9 18.4 5.3 18L18 5.3C18.4 4.9 19 4.9 19.4 5.3C19.8 5.7 19.8 6.29999 19.4 6.69999L6.7 19.4C6.5 19.6 6.3 19.7 6 19.7Z" fill="currentColor"/>
                                              <path d="M18.8 19.7C18.5 19.7 18.3 19.6 18.1 19.4L5.40001 6.69999C5.00001 6.29999 5.00001 5.7 5.40001 5.3C5.80001 4.9 6.40001 4.9 6.80001 5.3L19.5 18C19.9 18.4 19.9 19 19.5 19.4C19.3 19.6 19 19.7 18.8 19.7Z" fill="currentColor"/>
                                            </svg>
                                          </span>
                                        </button>
                                      </div>
                                      <button type="button" title="Remove Case" 
                                        class="btn btn-icon btn-sm btn-danger align-self-start" @click="removeNegativeCase(index)">
                                        <span class="svg-icon svg-icon-3">
                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path>
                                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path>
                                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path>
                                          </svg>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                            </li>
                            <li v-if="showNewNegativeCaseForm">
                              <div class="card bg-light">
                                  <div class="card-body p-4">
                                    <quill-editor
                                      v-model="newNegativeCaseForm"
                                      :options="{
                                        modules: {
                                          toolbar: [
                                            ['bold', 'italic', 'underline'],
                                            ['link']
                                          ],
                                          keyboard: {
                                            bindings: {
                                              enter: {
                                                key: 13,
                                                handler: function(range, context) {
                                                  addNewNegativeCase()
                                                }
                                              }
                                            }
                                          }
                                        },
                                        theme: 'bubble'
                                      }"
                                      @change="onEditorChange($event, 'negative_case')"
                                      @ready="onReadyQuill($event)"
                                      class="mb-4">
                                    </quill-editor>
                                    <div class="d-flex justify-content-between">
                                      <div class="d-flex">
                                        <button class="btn btn-sm btn-primary me-2" @click="addNewNegativeCase()"
                                          :disabled="!newNegativeCaseForm || loading">Save</button>
                                        <button type="button" title="Discard" 
                                          class="btn btn-icon btn-sm align-self-start" @click="showNewNegativeCaseForm = false, newNegativeCaseForm = null">
                                          <span class="svg-icon svg-icon-3">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path opacity="0.3" d="M6 19.7C5.7 19.7 5.5 19.6 5.3 19.4C4.9 19 4.9 18.4 5.3 18L18 5.3C18.4 4.9 19 4.9 19.4 5.3C19.8 5.7 19.8 6.29999 19.4 6.69999L6.7 19.4C6.5 19.6 6.3 19.7 6 19.7Z" fill="currentColor"/>
                                              <path d="M18.8 19.7C18.5 19.7 18.3 19.6 18.1 19.4L5.40001 6.69999C5.00001 6.29999 5.00001 5.7 5.40001 5.3C5.80001 4.9 6.40001 4.9 6.80001 5.3L19.5 18C19.9 18.4 19.9 19 19.5 19.4C19.3 19.6 19 19.7 18.8 19.7Z" fill="currentColor"/>
                                            </svg>
                                          </span>
                                        </button>
                                      </div>
                                      <!-- <button type="button" title="Remove Task" 
                                        class="btn btn-icon btn-sm btn-danger align-self-start">
                                        <span class="svg-icon svg-icon-3">
                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path>
                                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path>
                                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path>
                                          </svg>
                                        </span>
                                      </button> -->
                                    </div>
                                  </div>
                                </div>
                            </li>
                          </ol>
                          <button class="btn btn-sm btn-light-primary mb-4" 
                            v-if="!showNewNegativeCaseForm" 
                            @click="showNewNegativeCaseForm = true">
                            Add New Case
                          </button>
                        </div>
                      </div>
                      <div class="separator separator-dashed" v-if="!taskDetail.parent_id"></div>
                      <div name="subtasks" v-if="!taskDetail.parent_id">
                        <div class="accordion-header py-3 d-flex collapsed" data-bs-toggle="collapse" data-bs-target="#kt_accordion_2_item_5">
                          <span class="accordion-icon">
                            <span class="svg-icon svg-icon-3">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor" />
                              </svg>
                            </span>
                          </span>
                          <div class="ms-4">
                            <div class="text-gray-800 fw-bold">Subtasks 
                              <div class="badge badge-light" v-if="taskDetail.subtasks && taskDetail.subtasks.length > 0">{{ taskDetail.subtasks.length }}</div>
                            </div>
                          </div> 
                        </div>
                        <div id="kt_accordion_2_item_5" class="collapse ps-10" data-bs-parent="#kt_accordion_2">
                          <ul>
                            <li v-for="subtask in taskDetail.subtasks" :key="subtask.id" class="mb-2">
                              <a href="#" @click="getTaskByTaskId(subtask.id)">
                                <div class="badge" :class="taskDetail.current_status.badgeColor">{{ taskDetail.current_status.name }}</div>
                                [{{ subtask.code }}] {{ subtask.title }}
                              </a>
                            </li> 
                            <li v-if="showNewSubtaskForm">
                              <div class="card bg-light">
                                  <div class="card-body p-4">
                                    <input type="text" class="form-control mb-4" v-model="subtaskTitle" placeholder="New subtask">
                                    <div class="d-flex justify-content-between">
                                      <div class="d-flex">
                                        <button class="btn btn-sm btn-primary me-2"
                                          :disabled="!subtaskTitle || loading"
                                          @click="createSubtask()">Save</button>
                                        <button type="button" title="Discard" 
                                          class="btn btn-icon btn-sm align-self-start" @click="showNewSubtaskForm = false, showNewSubtaskForm = null">
                                          <span class="svg-icon svg-icon-3">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path opacity="0.3" d="M6 19.7C5.7 19.7 5.5 19.6 5.3 19.4C4.9 19 4.9 18.4 5.3 18L18 5.3C18.4 4.9 19 4.9 19.4 5.3C19.8 5.7 19.8 6.29999 19.4 6.69999L6.7 19.4C6.5 19.6 6.3 19.7 6 19.7Z" fill="currentColor"/>
                                              <path d="M18.8 19.7C18.5 19.7 18.3 19.6 18.1 19.4L5.40001 6.69999C5.00001 6.29999 5.00001 5.7 5.40001 5.3C5.80001 4.9 6.40001 4.9 6.80001 5.3L19.5 18C19.9 18.4 19.9 19 19.5 19.4C19.3 19.6 19 19.7 18.8 19.7Z" fill="currentColor"/>
                                            </svg>
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </li>
                          </ul>
                          <button class="btn btn-sm btn-light-primary mb-4" 
                            v-if="!showNewSubtaskForm" 
                            @click="showNewSubtaskForm = true">
                            Add New Subtask
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </scroll-shadow>
              </div>
              <div class="col-6 p-4" >
                <ul class="nav nav-tabs nav-line-tabs mb-5 fs-6">
                  <li class="nav-item">
                    <a class="nav-link active p-0" data-bs-toggle="tab" href="#kt_tab_pane_1">
                      <h4>Issues
                        <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" 
                          title="Contains all the problems such as bug, new test flow, missed analysis, new rules / flow, new request"></i>
                      </h4>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link p-0" data-bs-toggle="tab" href="#kt_tab_pane_2">
                      <h4>Activities</h4>
                    </a>
                  </li>
                </ul>

                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="kt_tab_pane_1" role="tabpanel">
                    <div v-if="!issueDetail">
                      <div class="d-flex flex-wrap flex-stack my-5">
                        <div class="d-flex align-items-center position-relative my-1">
                          <span class="svg-icon svg-icon-1 position-absolute ms-4">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                              <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
                            </svg>
                          </span>
                          <input type="text" class="form-control form-control-solid w-250px ps-14" placeholder="Search By Issues ID" 
                            v-debounce:300.lock="handleSearchThread" v-model="threads.params.search"/>
                        </div>
                        <div class="d-flex flex-wrap my-1">
                          <button type="button" class="btn btn-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                            <span class="svg-icon svg-icon-2">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor" />
                              </svg>
                            </span>
                            Filter
                          </button>
                          <div class="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true">
                            <div class="px-7 py-5">
                              <div class="fs-5 text-dark fw-bold">Filter Options</div>
                            </div>
                            <div class="separator border-gray-200"></div>
                            <div class="px-7 py-5">
                              <div class="mb-3">
                                <label class="form-label fs-6 fw-semibold">Type:</label>
                                <multiselect 
                                  track-by="reference"
                                  label="name"
                                  placeholder="Select Type"
                                  v-model="threads.params.type"
                                  :options="threadTypes"
                                  :allow-empty="false"
                                  :close-on-select="true"
                                  :multiple="false"
                                  :searchable="false"
                                  :hide-selected="false"
                                  :showLabels="false"
                                  @input="getThreads()"
                                ></multiselect>
                              </div>
                              <div class="mb-3">
                                <label class="form-label fs-6 fw-semibold">Status:</label>
                                <multiselect 
                                  track-by="reference"
                                  label="name"
                                  placeholder="Select Status"
                                  v-model="threads.params.status"
                                  :options="threadStatuses"
                                  :allow-empty="false"
                                  :close-on-select="true"
                                  :multiple="false"
                                  :searchable="false"
                                  :hide-selected="false"
                                  :showLabels="false"
                                  @input="getThreads()"
                                ></multiselect>
                              </div>
                              <div class="d-flex justify-content-end">
                                <button type="reset" class="btn btn-light btn-active-light-primary fw-semibold px-6" data-kt-menu-dismiss="true"
                                  @click="threads.params.type = null, threads.params.status = null, getThreads()">Reset</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <scroll-shadow class="w-100">
                        <div style="height: 451px; overflow-y:auto;" >
                          <div class="btn btn-sm btn-light text-start w-100 mb-4" v-for="thread in threads.data" :key="thread.id" @click="getThreadDetails(thread.id)">
                            <div class="d-flex justify-content-between">
                              <p class="fs-8 mb-0">#{{thread.id}} last updated at {{ thread.created_at | moment('LL') }} by <span class="text-warning">{{ thread.user.full_name }}</span></p>
                              <p class="fs-8 mb-0">{{ thread.type_text }} / 
                                <span class="text-danger">{{ thread.status_text }}</span>
                              </p>
                            </div>
                            <p class="text-gray-800 fw-bold mb-0">{{ thread.title }}</p>
                          </div>
                          <div class="d-flex flex-column flex-center" v-if="threads.data.length == 0">
                            <img src="/assets/media/illustrations/sigma-1/21.png" class="mw-400px">
                            <div class="fw-bolder text-dark mb-4">No Issues Found.</div>
                          </div>
                        </div>
                      </scroll-shadow>
                      <div class="separator separator-dashed"></div>
                      <div class="card-footer pt-4" id="kt_chat_messenger_footer">
                        <textarea class="form-control form-control-solid mb-3" rows="1" data-kt-element="input" placeholder="Type a issue" 
                          v-model="thread.title"></textarea>
                        <div class="d-flex flex-stack">
                          <multiselect 
                            track-by="reference"
                            label="name"
                            placeholder="Select Type"
                            v-model="thread.type"
                            :options="threadTypes"
                            :allow-empty="false"
                            :close-on-select="true"
                            :multiple="false"
                            :searchable="false"
                            :hide-selected="false"
                            :showLabels="false"
                            class="fit-content"
                          ></multiselect>
                          <button class="btn btn-primary" type="button" @click="createThread()" :disabled="!thread.title || !thread.type">Publish</button>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="d-flex flex-wrap flex-stack mb-3">
                        <div class="d-flex align-items-center position-relative my-1">
                          <button type="button" class="btn btn-icon btn-sm btn-outline me-4"
                            @click="issueDetail = null, getThreads()">
                            <span class="svg-icon svg-icon-2">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.2657 11.4343L15.45 7.25C15.8642 6.83579 15.8642 6.16421 15.45 5.75C15.0358 5.33579 14.3642 5.33579 13.95 5.75L8.40712 11.2929C8.01659 11.6834 8.01659 12.3166 8.40712 12.7071L13.95 18.25C14.3642 18.6642 15.0358 18.6642 15.45 18.25C15.8642 17.8358 15.8642 17.1642 15.45 16.75L11.2657 12.5657C10.9533 12.2533 10.9533 11.7467 11.2657 11.4343Z" fill="currentColor"/>
                              </svg>
                            </span>
                          </button>
                          Back
                        </div>
                        <div class="d-flex flex-wrap my-1">
                          <button type="button" class="btn btn-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                            Update Status
                          </button>
                          <div class="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true">
                            <div class="px-7 py-5">
                              <div class="mb-3">
                                <label class="form-label fs-6 fw-semibold">Type:</label>
                                <multiselect 
                                  track-by="reference"
                                  label="name"
                                  placeholder="Select Type"
                                  v-model="updateThreadType"
                                  :options="threadTypes"
                                  :allow-empty="false"
                                  :close-on-select="true"
                                  :multiple="false"
                                  :searchable="false"
                                  :hide-selected="false"
                                  :showLabels="false"
                                  @input="updateThread()"
                                ></multiselect>
                              </div>
                              <div class="mb-3">
                                <label class="form-label fs-6 fw-semibold">Status:</label>
                                <multiselect 
                                  track-by="reference"
                                  label="name"
                                  placeholder="Select Status"
                                  v-model="updateThreadStatus"
                                  :options="threadStatuses"
                                  :allow-empty="false"
                                  :close-on-select="true"
                                  :multiple="false"
                                  :searchable="false"
                                  :hide-selected="false"
                                  :showLabels="false"
                                  @input="updateThread()"
                                ></multiselect>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="btn btn-sm btn-light text-start w-100">
                        <div class="d-flex justify-content-between">
                          <p class="fs-8 mb-0">#{{ issueDetail.id }} last updated at {{ issueDetail.updated_at | moment('LL') }} by <span class="text-warning">{{ issueDetail.user.full_name }}</span></p>
                          <p class="fs-8 mb-0">{{ issueDetail.type_text }} / 
                            <span class="text-danger">{{ issueDetail.status_text}}</span>
                          </p>
                        </div>
                        <p class="text-gray-800 fw-bold mb-0">{{ issueDetail.title }}</p>
                      </div>
                      <div class="separator separator-dashed my-4"></div>
                      <scroll-shadow class="w-100">
                        <div style="height: 435px; overflow-y:auto;" >
                          <div class="d-flex mb-5" v-for="threadMessage in issueDetail.messages" :key="threadMessage.id">
                            <div class="symbol symbol-45px me-5">
                              <span class="symbol-label bg-warning text-inverse-warning fw-bold" v-if="!threadMessage.user.avatar">{{ generateInitial(threadMessage.user.full_name )}}</span>
                              <img v-else :alt="threadMessage.user.full_name" :src="threadMessage.user.avatar" style="object-fit:cover;"/>
                            </div>
                            <div class="d-flex flex-column flex-row-fluid">
                              <div class="d-flex align-items-center flex-wrap mb-1">
                                <a href="#" class="text-gray-800 text-hover-primary fw-bold me-2">{{ threadMessage.user.full_name }}</a>
                                <span class="text-gray-400 fw-semibold fs-7">{{ diffForHuman(threadMessage.created_at) }}</span>
                                <!-- <a href="#" class="ms-auto text-gray-400 text-hover-primary fw-semibold fs-7">Reply</a> -->
                              </div>
                              <span class="text-gray-800 fs-7 fw-normal pt-1">{{ threadMessage.message }}</span>
                            </div>
                          </div>
                          <div class="d-flex flex-column flex-center" v-if="issueDetail.messages.length == 0">
                            <img src="/assets/media/illustrations/sigma-1/21.png" class="mw-300px">
                            <div class="fw-bolder text-dark mb-4">No Comments Found.</div>
                          </div>
                        </div>
                      </scroll-shadow>
                      <div class="separator separator-dashed"></div>
                      <div class="card-footer pt-4" id="kt_chat_messenger_footer">
                        <div class="row">
                          <button class="btn btn-icon text-primary ms-2">
                            <emoji-picker @emoji="appendEmoji" :search="searchEmoji">
                              <button
                                class="emoji-invoker"
                                slot="emoji-invoker"
                                slot-scope="{ events: { click: clickEvent } }"
                                @click.stop="clickEvent"
                              >
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current text-grey">
                                    <path d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"/>
                                </svg>
                              </button>
                              <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                                <div class="emoji-picker bg-light border-0" :style="{ top: 490 + 'px', left: 585 - + 'px' }">
                                  <!-- <div class="emoji-picker__search">
                                    <input type="text" v-model="searchEmoji" v-focus>
                                  </div> -->
                                  <div>
                                    <div v-for="(emojiGroup, category) in emojis" :key="category">
                                      <h5>{{ category }}</h5>
                                      <div class="emojis">
                                        <span
                                          v-for="(emoji, emojiName) in emojiGroup"
                                          :key="emojiName"
                                          @click="insert(emoji)"
                                          :title="emojiName"
                                        >{{ emoji }}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </emoji-picker>
                          </button>
                          <div class="col">
                            <input type="text" class="form-control form-control-solid" placeholder="Type a comment" v-model="newThreadMessage" v-on:keyup.enter="sendThreadMessage()">
                          </div>
                          <button class="btn btn-icon btn-light-primary me-2" @click="sendThreadMessage()" :disabled="!newThreadMessage">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z" fill="currentColor"/>
                              <path opacity="0.3" d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z" fill="currentColor"/>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                    ...
                  </div>
                </div>
              </div>
            </div>
          </div>
				</div>
			</div>
		</div>
    <div class="modal fade" id="kt_modal_import_prd" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-md">
				<div class="modal-content">
					<div class="modal-header">
						<h2>Import PRD</h2>
						<div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
							<span class="svg-icon svg-icon-1">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
									<rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
								</svg>
							</span>
						</div>
					</div>
					<div class="modal-body py-lg-10 px-lg-10">
            <ValidationObserver ref="observerImportPRD">
              <form class="form" @submit.prevent="importPRD()">
                <div class="fv-row mb-7">
                  <ValidationProvider name="Phase" v-slot="{ errors }" :rules="{ required: true }" ref="project_timeline_id">
                    <label class="fw-semibold required fs-6 mb-2">Project Phase</label>
                    <multiselect 
                      track-by="id"
                      label="name"
                      placeholder="Select Phase"
                      v-model="prdProjectTimelineId" 
                      :options="timelines.filter( x => x.phase )"
                      :allow-empty="false"
                      :close-on-select="true"
                      :multiple="false"
                      :searchable="true"
                      :hide-selected="false"
                      :showLabels="false"
                      :custom-label="customLabel"
                    ></multiselect>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div>
                  <div class="form-group">
                    <div class="dropzone dropzone-queue mb-2">
                      <div class="">
                        <label class="d-flex align-items-center fs-6 fw-semibold mb-2">
                          <span class="required">File</span>
                        </label>
                        <input type="file" class="d-none" id="attachFile" v-on:change="addToQueue">
                        <label for="attachFile" v-if="fileQueues.length == 0">
                          <a class="btn btn-sm btn-primary me-2">Attach file</a>
                        </label>
                        <p class="mt-2"><a class="fs-8" href="https://erp-api.demoapp.xyz/PRD-template.xlsx">Download Template</a></p>
                      </div>
                      <div class="dropzone-items wm-200px">
                        <div class="dropzone-item p-5" v-for="(queue, index) in fileQueues.filter( x => !x.isUploaded)" :key="index">
                          <div class="dropzone-file">
                            <div class="dropzone-filename text-dark" :title="queue.file.name">
                              <span>{{ queue.file.name }}</span>
                              <strong> ({{formatBytes(queue.file.size)}})</strong>
                            </div>
                            <div class="dropzone-error mt-0" v-if="!queue.isValid">{{queue.invalidMessage}}</div>
                          </div>
                          <div class="dropzone-progress" v-if="queue.isValid && queue.progress < 100">
                            <div class="progress bg-light-primary">
                              <div class="progress-bar bg-primary" role="progressbar" aria-valuemin="0" aria-valuemax="100" :aria-valuenow="0" :style="'width: '+ queue.progress +'%'"></div>
                            </div>
                          </div>
                          <div class="dropzone-toolbar">
                            <span class="dropzone-delete" v-if="queue.progress == 0 || !queue.isValid" @click="removeFileFromQueues(queue.id)">
                              <i class="bi bi-x fs-1"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <span class="form-text fs-6 text-muted">Max file size is {{ formatBytes(maxFileSize, 0) }}</span> -->
                    <ValidationProvider name="File" v-slot="{ errors }" :rules="{ required: true }" ref="file">
                      <input type="hidden" name="" v-model="fileQueues">
                      <small class="text-danger text-sm text-xs" v-if="errors[0]"><br>{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="text-center pt-15">
                    <button class="btn btn-light me-3" data-bs-dismiss="modal" type="button">Discard</button>
                    <button type="submit" class="btn btn-primary">
                      <span class="indicator-label" v-if="!loading">Submit</span>
                      <span class="indicator-progress" v-else>
                        Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
					</div>
				</div>
			</div>
		</div>  
  </div>
</template>
<script>
import 'scroll-shadow-element'
import draggable from 'vuedraggable'
import EmojiPicker from 'vue-emoji-picker'

export default {
  components: {
    draggable,
    EmojiPicker,
  },
  props: [
    'members'
  ],
  data() {
    return {
      tasks: [],
      statuses: [
        { name: 'Todo', color: 'bg-light', reference: null, badgeColor: 'badge-light', },
        { name: 'On Progress', color: 'bg-primary', reference: 1, badgeColor: 'badge-primary', },
        { name: 'Ready To Review', color: 'bg-warning', reference: 2, badgeColor: 'badge-warning', },
        { name: 'On Review', color: 'bg-info', reference: 3, badgeColor: 'badge-info', },
        { name: 'Reopen', color: 'bg-dark', reference: 6, badgeColor: 'badge-light', },
        { name: 'Feedback', color: 'bg-danger', reference: 4, badgeColor: 'badge-danger', },
        { name: 'Done', color: 'bg-success', reference: 5, badgeColor: 'badge-success', },
      ],
      threadTypes: [
        { name: 'Bug', color: 'bg-dark', reference: 1 },
        { name: 'New Test Flow', color: 'bg-primary', reference: 2 },
        { name: 'Missed Analysis', color: 'bg-warning', reference: 3 },
        { name: 'New Rules / Flow', color: 'bg-info', reference: 4 },
        { name: 'New Request', color: 'bg-danger', reference: 5 },
      ],
      threadStatuses: [
        { name: 'Open', color: 'bg-dark', reference: 1 },
        { name: 'Reopen', color: 'bg-primary', reference: 2 },
        { name: 'Ready To Test', color: 'bg-warning', reference: 3 },
        { name: 'Closed', color: 'bg-info', reference: 4 },
        { name: 'TBD', color: 'bg-danger', reference: 5 },
      ],
      detail: null,
      timelines: [],
      request: {
        project_timeline_id: null,
        title: null,
        user_stories: null,
        positive_case: [],
        negative_case: [],
        assign_to: null,
      },
      loading: false,
      taskDetail: {
        project_timeline: {
          phase: {}
        },
        user_assigned: {
          full_name: ''
        }
      },
      issueDetail: false,
      quillOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['link']
          ]
        },
        placeholder: '...',
        theme: 'snow',
      },
      quillTitleShowed: false,
      quillUserStoryShowed: false,
      quillAcceptanceCriteriaShowed: false,
      quillNotesShowed: false,
      quillPositiveCaseShowedIndex: null,
      quillNegativeCaseShowedIndex: null,
      showNewPositiveCaseForm: false,
      newPositiveCaseForm: null,
      showNewNegativeCaseForm: false,
      newNegativeCaseForm: null,
      threads: {
        data: [],
        meta: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
        },
        params: {
          sortby: 'updated_at.DESC',
          search: '',
          limit: 10,
          status: '',
          type: ''
        }
      },
      thread: {
        project_timeline_modul_id: null,
        title: null,
        type: null,
        status: null,
        type: null
      },
      threadMessages: {
        data: [],
        meta: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
        },
        params: {
          sortby: 'updated_at.DESC',
          search: '',
          limit: 10,
          status: '',
          type: ''
        }
      },
      newThreadMessage: '',
      updateThreadType: null,
      updateThreadStatus: null,
      showNewSubtaskForm: false,
      subtaskTitle: null,
      loggedUser: JSON.parse(localStorage.getItem('user')),
      taskFilter: {
        assign_to: null,
        priority: null,
        project_timeline_id: null,
        search: null
      },
      fileQueues: [],
      maxFileSize: 5000000,
      prdProjectTimelineId: null,
      searchEmoji: '',
      taskDetailBackup: null,
      showSubtask: false,
    }
  },
  computed: {
    requestBody: function() {
      if ( this.detail ) {
        return {
          ...this.request,
          project_timeline_id: this.request.project_timeline_id ? this.request.project_timeline_id.id : null,
          assign_to: this.request.assign_to ? this.request.assign_to.id : null,
        }
      }
      return {
        ...this.request,
        project_timeline_id: this.request.project_timeline_id ? this.request.project_timeline_id.id : null,
        assign_to: this.request.assign_to ? this.request.assign_to.user_id : null,
      }
    },
    requestThread: function() {
      return {
        project_timeline_modul_id: this.taskDetail.id,
        title: this.thread.title,
        type: this.thread.type,
        status: this.thread.status ? this.thread.status : 1,
        type: this.thread.type ? this.thread.type.reference : 1
      }
    },
    requestSubtask: function() {
      return {
        project_timeline_id: this.taskDetail.project_timeline_id,
        parent_id: this.taskDetail.id,
        title: this.subtaskTitle,
        user_stories: this.subtaskTitle,
        positive_case: null,
        negative_case: null,
        assign_to: null,
      }
    }
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus()
      },
    },
  },
  methods: {
    appendEmoji(emoji) {
      this.newThreadMessage += emoji
    },
    handleSearch() {
      this.tasks= []
      this.get()
    },
    get() {
      let _ = this
      _.axios.get('/v1/project/modul'
        + '?project_id=' + _.$route.params.id
        + '&assign_to=' + (_.taskFilter.assign_to ? _.taskFilter.assign_to.id : '' )
        + '&priority=' + (_.taskFilter.priority ? _.taskFilter.priority.toLowerCase() : '' )
        + '&project_timeline_id=' + (_.taskFilter.project_timeline_id ? _.taskFilter.project_timeline_id.id : '' )
        + '&search=' + (_.taskFilter.search ? _.taskFilter.search : '')
      )
      .then( resp => {
        _.tasks = resp.data.data

        if (_.taskFilter.assign_to) {
          _.showSubtask = true
        }

        $(document).ready(function() {
          $('[data-bs-toggle="tooltip"]').tooltip();
        });
      })
      .catch( err => {
        _.$swalToast.fire({
          icon: 'error',
          title: `Oops! Something went wrong (${err.response.status})`,
          text: err.response.data.errors
        })
      })
    },
    getModulesByStatus( status ) {
      if ( this.showSubtask ) {
        return this.tasks.filter( x => x.current_status == status.reference)
      } 
      return this.tasks.filter( x => x.current_status == status.reference && !x.parent_id )
    },
    async reset() {
      this.detail = null
      this.request = {
        project_timeline_id: null,
        title: null,
        user_stories: null,
        positive_case: [
          null
        ],
        negative_case: [
          null
        ],
        assign_to: null,
      }

      await this.$refs.observer.reset()
    },
    getTimeline() {
      let _ = this
      _.axios.get('/v1/project/timeline?project_id=' + _.$route.params.id)
      .then( resp => {
        _.timelines = resp.data.data
      })
      .catch( err => {
        _.$swalToast.fire({
          icon: 'error',
          title: `Oops! Something went wrong (${err.response.status})`,
          text: err.response.data.errors
        })
      })
    },
    customLabel( timeline ) {
      if ( timeline.sub_phase ) {
        return timeline.phase.name + ': ' + timeline.sub_phase
      }
      return timeline.phase.name
    },
    customLabelUser( member ) {
      return member.user ? member.user.full_name : member.full_name
    },
    async createTask() {
      let _ = this

      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      let req = {
        ..._.requestBody
      }

      delete req.positive_case
      delete req.negative_case

      _.loading = true
      _.axios.post('/v1/project/modul', req)
        .then(() => {
          _.loading = false
          _.reset()
          _.hideModal()
          _.get()
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully add new task',
            text: `You have successfully add new task`
          })
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              _.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
          _.loading = false
        })
    }, 
    updateTask(task) {
      let _ = this

      const request = {
        ...task,
      }

      _.loading = true
      _.axios.put('/v1/project/modul/' + task.id, request)
        .then(() => {
          _.loading = false
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully update task',
            text: `You have successfully update task`
          })

          _.getTaskByTaskId(_.taskDetail.id, false)
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
          _.loading = false
        })
    }, 
    updateStatus() {
      let _ = this

      $('#priorityButton').click()
    
      let req = {
        ..._.taskDetail
      }

      req.current_status = _.taskDetail.current_status ? _.taskDetail.current_status.reference : null
      req.current_feedback_status = _.taskDetail.current_feedback_status ? _.taskDetail.current_feedback_status.reference : null

      _.axios.put('/v1/project/modul/' + _.taskDetail.id, req)
        .then(() => {
          _.loading = false
          _.get()
          // _.hideModal()
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully update task status',
            text: `You have successfully update task status`
          })

          _.getTaskByTaskId(_.taskDetail.id, false)
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    setDetail(task, updateThreads = true) {
      let _ = this

      _.issueDetail = null
      _.threads.data = []

      _.taskDetail = JSON.parse(JSON.stringify(task))
      _.taskDetailBackup = JSON.parse(JSON.stringify(task))

      if (updateThreads) {
        _.getThreads()
      }

      const searchStatus = _.statuses.map( x => x.reference).indexOf(task.current_status)
      _.taskDetail.current_status = _.statuses[searchStatus]
      _.tasks[_.getTaskDetail(task.id)] = task
    },
    deleteTask() {
      let _ = this
      _.hideModal()

      _.$swal.fire({
        title: 'You are about to delete this task',
        text: 'Do you really want to delete this task? This process cannot be undone.',
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: 'No, cancel',
        confirmButtonText: 'Yes, sure',
        focusCancel: true
        }).then((result) => {
          if (result.value) {
            _.axios.delete(`/v1/project/modul/${_.taskDetail.id}`)
              .then(() => {
                _.$swalToast.fire({
                  icon: 'success',
                  title: 'Successfully delete task',
                  text: 'You have successfully delete this task'
                })

                _.get()

                if ( _.taskDetail.parent_id ) {
                  setTimeout(() => {
                    $('#cardTask-' + _.taskDetail.parent_id).click()
                  }, 100);
                }
              })
              .catch(err=>{
                _.$swalToast.fire({
                  icon: 'error',
                  title: `Oops! Something went wrong (${err.response.status})`,
                  text: err.response.data.errors
                })
              })
          }
      })
    },
    movedTask(task) {
      let _ = this
      _.axios.put('/v1/project/modul/' + task.id, { 
        title: task.title,
        assign_to: task.assign_to,
        project_timeline_id: task.project_timeline_id,
        user_stories: task.user_stories,
        current_status: task.current_status,
        current_feedback_status: task.current_feedback_status,
        positive_case: task.positive_case,
        negative_case: task.negative_case
      })
      .then(() => {
        _.$swalToast.fire({
          icon: 'success',
          title: 'Successfully update task status',
          text: `You have successfully update task status`
        })
      })
      .catch( err => {
        _.$swalToast.fire({
          icon: 'error',
          title: `Oops! Something went wrong (${err.response.status})`,
          text: err.response.data.errors
        })
      })
    },
    log(evt, status) {
      if ( evt.added && evt.added.element ) {
        const searchTask = this.tasks.map( x => x.id ).indexOf(evt.added.element.id)
        this.tasks[searchTask].current_status = status.reference
        this.movedTask(this.tasks[searchTask])
      }
    },
    getTaskDetail(id) {
      let _ = this
      let index = null
      const searchTask = this.tasks.map( x => x.id ).indexOf( id )

      if ( searchTask != -1 ) {
        index = searchTask
      }
      return index
    },
    onReadyQuill(editor) {
      editor.setSelection(editor.getLength(),0);
    },
    onBlurQuill(quillType) {
      let _ = this
      
      _.quillTitleShowed = false
      _.quillUserStoryShowed = false
      _.quillAcceptanceCriteriaShowed = false
      _.quillNotesShowed = false

      if (!_.taskDetail[quillType]) {
        _.taskDetail[quillType] = _.tasks[_.getTaskDetail(_.taskDetail.id)][quillType]
      } else {
        if (_.tasks[_.getTaskDetail(_.taskDetail.id)][quillType] != _.taskDetail[quillType]) {
          _.tasks[_.getTaskDetail(_.taskDetail.id)][quillType] = _.taskDetail[quillType]
          _.updateTask(_.tasks[_.getTaskDetail(_.taskDetail.id)])
        }
      }
    },
    onEditorChange({ quill, html, text }, type, index = null) {
      let _ = this

      let enteredText = text
      let numberOfLineBreaks = (enteredText.match(/\n/g)||[]).length;

      if ( index == null ) {
        if (numberOfLineBreaks > 1) {
          for (let i = 0; i < numberOfLineBreaks; i++) {
            const elm = enteredText.split(/^/gm)[i].trim();
            if (elm) {
              _.taskDetail[type].push(_.removeHTMLStripTag(elm))
            }
          }
          
          _.tasks[_.getTaskDetail(_.taskDetail.id)][type] = _.taskDetail[type]
          _.updateTask(_.tasks[_.getTaskDetail(_.taskDetail.id)])
          
          _.newPositiveCaseForm = null
          _.showNewPositiveCaseForm = false
          _.quillPositiveCaseShowedIndex = null

          _.newNegativeCaseForm = null
          _.showNewNegativeCaseForm = false
          _.quillNegativeCaseShowedIndex = null
        }
      }
    },
    addNewPositiveCase() {
      let _ = this

      if ( !_.newPositiveCaseForm ) {
        return;
      }

      let req = {
        ..._.taskDetail
      }

      req.current_status = _.taskDetail.current_status ? _.taskDetail.current_status.reference : null
      req.current_feedback_status = _.taskDetail.current_feedback_status ? _.taskDetail.current_feedback_status.reference : null

      if (!req.positive_case) {
        req.positive_case = []
      }

      req.positive_case.push(_.newPositiveCaseForm)

      _.loading = true
      _.axios.put('/v1/project/modul/' + _.taskDetail.id, req)
        .then(() => {
          _.loading = false
          _.get()
          // _.hideModal()
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully update task status',
            text: `You have successfully update task status`
          })
          _.taskDetail.positive_case = req.positive_case
          // _.showNewPositiveCaseForm = false
          _.newPositiveCaseForm = null
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
          _.loading = false
        })
    },
    removePositiveCase(positiveCaseIndex) {
      this.taskDetail.positive_case.splice(positiveCaseIndex, 1)
      this.updateStatus()
    },
    addNewNegativeCase() {
      let _ = this

      if ( !_.newNegativeCaseForm ) {
        return;
      }

      let req = {
        ..._.taskDetail
      }

      req.current_status = _.taskDetail.current_status ? _.taskDetail.current_status.reference : null
      req.current_feedback_status = _.taskDetail.current_feedback_status ? _.taskDetail.current_feedback_status.reference : null

      if (!req.negative_case) {
        req.negative_case = []
      }

      req.negative_case.push(_.newNegativeCaseForm)

      _.loading = true
      _.axios.put('/v1/project/modul/' + _.taskDetail.id, req)
        .then(() => {
          _.loading = false
          _.get()
          // _.hideModal()
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully update task status',
            text: `You have successfully update task status`
          })
          _.taskDetail.negative_case = req.negative_case
          // _.showNewNegativeCaseForm = false
          _.newNegativeCaseForm = null
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
          _.loading = false
        })
    },
    removeNegativeCase(negativeCaseIndex) {
      this.taskDetail.negative_case.splice(negativeCaseIndex, 1)
      this.updateStatus()
    },
    getThreads(page = 1) {
      let _ = this
      _.axios.get('/v1/project/modul/thread'
          + '?modul_id='  + _.taskDetail.id
          + '&page='      + page
          + '&limit='     + _.threads.params.limit
          + '&sortBy='    + _.threads.params.sortby
          + '&search='    + _.threads.params.search
          + '&status='    + (_.threads.params.status ? _.threads.params.status.reference : '')
          + '&type='      + (_.threads.params.type ? _.threads.params.type.reference : ''))
      .then( resp => {
        _.threads.meta = resp.data.meta
        _.threads.data = resp.data.data

        setTimeout(() => {
          KTMenu.createInstances();
          KTScroll.createInstances();
        }, 500);
      })
      .catch( err => {
        _.$swalToast.fire({
          icon: 'error',
          title: `Oops! Something went wrong (${err.response.status})`,
          text: err.response.data.errors
        })
      })
    },
    createThread() {
      let _ = this

      _.axios.post('/v1/project/modul/thread', _.requestThread)
        .then(() => {
          _.thread = {
            project_timeline_modul_id: null,
            title: null,
            type: null,
            status: null,
            type: null
          },

          _.getThreads()
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully add new thread',
            text: `You have successfully add new thread`
          })
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              _.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
        })
    }, 
    handleSearchThread() {
      this.getThreads()
    },
    getThreadDetails(threadId) {
      let _ = this
      _.axios.get('/v1/project/modul/thread/' + threadId)
      .then( resp => {
        _.issueDetail = resp.data.data

        const searchThreadType = _.threadTypes.map( x => x.reference ).indexOf(_.issueDetail.type)
        const searchThreadStatus = _.threadStatuses.map( x => x.reference ).indexOf(_.issueDetail.status)

        _.updateThreadType = _.threadTypes[searchThreadType]
        _.updateThreadStatus = _.threadStatuses[searchThreadStatus]
      })
      .catch( err => {
        _.$swalToast.fire({
          icon: 'error',
          title: `Oops! Something went wrong (${err.response.status})`,
          text: err.response.data.errors
        })
      })
    },
    sendThreadMessage() {
      let _ = this

      _.axios.post('/v1/project/modul/thread/'+ _.issueDetail.id +'/message', { message: _.newThreadMessage })
        .then(() => {
          _.getThreadDetails(_.issueDetail.id)
          _.newThreadMessage = ''
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully send new comment',
            text: `You have successfully send new comment`
          })
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    updateThread() {
      let _ = this

      let req = {
        type: _.updateThreadType.reference,
        status: _.updateThreadStatus.reference
      }

      _.axios.put('/v1/project/modul/thread/'+ _.issueDetail.id, req)
        .then(() => {
          _.getThreadDetails(_.issueDetail.id)
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully update thread',
            text: `You have successfully update thread`
          })
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    async createSubtask() {
      let _ = this

      _.loading = true
      _.axios.post('/v1/project/modul', _.requestSubtask)
        .then(async () => {
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully add new subtask',
            text: `You have successfully add new subtask`
          })

          _.loading = false
          _.subtaskTitle = null
          _.showNewSubtaskForm = false
          
          _.getTaskByTaskId(_.requestSubtask.parent_id)
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              _.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
          _.loading = false
        })
    }, 
    getTaskByTaskId(taskId, updateThreads = true) {
      let _ = this
      _.axios.get('/v1/project/modul/' + taskId)
        .then(resp => {
          _.setDetail(resp.data.data, updateThreads)
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    async addToQueue(event){
      let _ = this
      let files = event.target.files
      let fileId = 0

      Array.from(files).forEach(file => {
        const isValid = _.isAllowedExtension(file.name, ['xls', 'xlsx'])
        let invalidMessage = null

        if (!isValid) {
          invalidMessage = `Allowed file extensions are : *.xlsx or *.xls`
        }
        
        _.fileQueues.push({
          id: fileId,
          isValid: isValid,
          invalidMessage: invalidMessage,
          progress: 0,
          file: file,
          isUploaded: false
        })

        fileId += 1
      });

      $("#attachFile").val(null);
    },
    removeFileFromQueues( fileId ) {
      let _ = this
      const searchFile = _.fileQueues.map( x => x.id ).indexOf( fileId )
      if ( searchFile != -1 ) {
        _.fileQueues.splice(searchFile, 1)
      }
    },
    removeAllFileFromQueues() {
      let _ = this
      _.fileQueues = []
    },
    async resetImportPRD() {
      // this.request = {
      //   project_id: this.$route.params.id,
      //   date: null,
      //   description: null,
      //   attendants: []
      // }
      $("#attachFile").val(null);
      this.fileQueues = []
      await this.$refs['observerImportPRD'].reset()
    },
    async importPRD() {
      let _ = this
      
      const isValid = await _.$refs.observerImportPRD.validate()
      if ( !isValid ) return

      if (_.fileQueues.filter( x => !x.isValid).length > 0) return

      _.loading = true

      let formData = new FormData()
      formData.append('file', _.fileQueues[0].file)
      formData.append('project_timeline_id', _.prdProjectTimelineId.id)

      await _.axios.post('/v1/project/modul/import', formData, 
        { 
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: function( progressEvent ) {
            const percent = ( progressEvent.loaded / progressEvent.total ) * 100
            _.fileQueues[0].progress = percent
            _.$forceUpdate()
          }.bind(_)
        })
        .then(() => {
          _.get()
          _.loading = false
          _.hideModal()

          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully import PRD',
            text: `You have successfully import PRD`
          })
        })
        .catch(err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors ? err.response.data.errors : 'Format file salah, silakan upload dengan format .xls/.xlsx, atau download template terlebih dahulu'
          })
          _.resetImportPRD()
          _.loading = false
        })
    },
    resetTaskDetail() {
      let _ = this
      _.taskDetail = JSON.parse(JSON.stringify(_.taskDetailBackup))
      const searchStatus = _.statuses.map( x => x.reference).indexOf(_.taskDetailBackup.current_status)
      _.taskDetail.current_status = _.statuses[searchStatus]
    }
  },
  mounted() {
    this.get()
    this.getTimeline()

    this.hideModal()

    $(document).ready(function() {
      $('textarea').keypress(function(event) {
        if (event.keyCode == 13) {
          event.preventDefault();
        }
      });
    });
  },
  watch: {
    step: function() {
      $(document).ready(function() {
        $('[data-bs-toggle="tooltip"]').tooltip('dispose');
      });
      setTimeout(() => {
        $(document).ready(function() {
          $('[data-bs-toggle="tooltip"]').tooltip();
        });
      }, 100);
    },
    taskDetail: function() {
      $(document).ready(function() {
        $('[data-bs-toggle="tooltip"]').tooltip('dispose');
      });

      setTimeout(() => {
        $(document).ready(function() {
          $('[data-bs-toggle="tooltip"]').tooltip();
        });
        KTMenu.createInstances();
      }, 100);
    }
  }
}
</script>
<style>
  .quillTitle .ql-editor {
    font-size: 1.35rem;
    padding: 0px;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2;
    color: var(--kt-headings-color);
    outline: 0;
    font-family: Inter, Helvetica, sans-serif;
  }
  .emoji-invoker {
    /* position: absolute; */
    /* top: 0.5rem; */
    /* right: 0.5rem; */
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
    padding: 0;
    background: transparent;
    border: 0;
  }
  .emoji-invoker:hover {
    transform: scale(1.1);
  }
  .emoji-invoker > svg {
    fill: #b1c6d0;
  }

  .emoji-picker {
    position: absolute;
    z-index: 1;
    /* font-family: Montserrat; */
    border: 1px solid #ccc;
    width: 15rem;
    height: 20rem;
    overflow: scroll;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    /* background: #fff; */
    /* box-shadow: 1px 1px 8px #c7dbe6; */
  }
  .emoji-picker__search {
    display: flex;
  }
  .emoji-picker__search > input {
    flex: 1;
    border-radius: 10rem;
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    outline: none;
  }
  .emoji-picker h5 {
    margin-bottom: 0;
    color: #b1b1b1;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: default;
  }
  .emoji-picker .emojis {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .emoji-picker .emojis:after {
    content: "";
    flex: auto;
  }
  .emoji-picker .emojis span {
    padding: 0.2rem;
    cursor: pointer;
    border-radius: 5px;
  }
  .emoji-picker .emojis span:hover {
    background: #ececec;
    cursor: pointer;
  }
</style>