<template>
  <div>
    <span v-if="type == 'datetime'" data-bs-toggle="tooltip" data-bs-custom-class="tooltip-dark" data-bs-trigger="hover" data-bs-offset="0,5" data-bs-dismiss-="click" 
      :title="diffForHuman(data)">
      {{ data | moment('DD-MM-YYYY HH:mm:ss') }}
    </span>
    <span v-if="type == 'date'" data-bs-toggle="tooltip" data-bs-custom-class="tooltip-dark" data-bs-trigger="hover" data-bs-offset="0,5" data-bs-dismiss-="click" 
      :title="diffForHuman(data)">
      {{ data | moment('DD MMM YYYY')}}
    </span>
    <span v-else-if="type == 'currency'">
      {{ data | currency }}
    </span>
    <span v-else-if="type == 'diffForHuman'">
      <div class="badge badge-light fw-bold" v-if="data">{{ diffForHuman(data) }}</div>
    </span>
    <span v-else>
      {{ data }}
    </span>
  </div>
</template>
<script>
export default {
  props: ['data', 'type'],
  mounted() {
    $('[data-bs-toggle="tooltip"]').tooltip()
  },
}
</script>