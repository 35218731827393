<template>
  <div>
    <div class="d-flex justify-content-end mb-5">
      <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_phase" @click="reset()">Add New Phase</button>
    </div>

    <div class="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid gap-10" id="kt_create_account_stepper" v-if="phases.length > 0">
      <div class="card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px">
        <div class="card-body px-6 px-lg-10 px-xxl-15 py-20">
          <div class="stepper-nav">
            <div class="stepper-item cursor-pointer w-100" 
              v-for="(phase, index) in phases" :key="phase.id"
              :class="{ current: activePhase ? activePhase.id == phase.id : false}" 
              @click="activePhase = phase">
              <div class="stepper-wrapper align-items-start w-100">
                <div class="stepper-icon w-40px h-40px">
                  <i class="stepper-check fas fa-check"></i>
                  <span class="stepper-number">{{ (index + 1) | numFormat }}</span>
                </div>
                <div class="stepper-label" style="width: 80%;">
                  <h3 class="stepper-title">{{ phase.name }}</h3>
                  <div class="stepper-desc fw-semibold" style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 100%;
                    display: inline-block;
                  ">{{ phase.description }}</div>
                </div>
              </div>
              <div class="stepper-line h-40px" v-if="(index+1) != phases.length"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="card d-flex flex-row-fluid flex-center" style="height: fit-content;">
        <div class="card-body py-20 w-100 mw-xl-700px px-9" novalidate="novalidate" id="kt_create_account_form">
          <div class="current" data-kt-stepper-element="content">
            <div class="w-100">
              <div class="pb-10">
                <div class="d-flex">
                  <div class="col">
                    <h2 class="fw-bold d-flex align-items-center text-dark">{{ activePhase.name }}
                      <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Each position has a different level of dominance"></i>
                    </h2>
                    <div class="text-muted fw-semibold fs-6">{{ activePhase.description }}</div>
                  </div>
                </div>
              </div>                              
              <div class="py-0" data-kt-customer-payment-method="row">
                <div class="py-3 d-flex flex-stack flex-wrap">                    
                  <div class="d-flex align-items-center collapsible rotate" data-bs-toggle="collapse" href="#kt_customer_view_payment_method_1" role="button" aria-expanded="false" aria-controls="kt_customer_view_payment_method_1">                      
                    <div class="me-3 rotate-90">                        
                      <span class="svg-icon svg-icon-3">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor" />
                        </svg>
                      </span>                        
                    </div>
                    <div class="">
                      <div class="d-flex align-items-center">
                        <div class="text-gray-800 fw-bold">Dominant Active</div>
                        <div class="badge badge-light-danger ms-5">{{ dominantPositions.length }} Position Involved</div>
                      </div>
                      <div class="text-muted">The position in this phase must be dominant in carrying out its work</div>
                    </div>                      
                  </div>
                </div>                                    
                <div id="kt_customer_view_payment_method_1" class="collapse show fs-6 ps-10" data-bs-parent="#kt_customer_view_payment_method">                    
                 <div class="d-flex flex-wrap py-5" v-if="dominantPositions.length > 0">
                    <ul>
                      <li class="text-gray-800" v-for="(position, index) in dominantPositions" :key="index">{{ position }}</li>
                    </ul>
                  </div> 
                  <p class="text-center text-gray-800" v-else>No position involved</p>
                </div>                  
              </div>                
              <div class="separator separator-dashed"></div>
              <div class="py-0" data-kt-customer-payment-method="row">
                <div class="py-3 d-flex flex-stack flex-wrap">                    
                  <div class="d-flex align-items-center collapsible rotate collapsed" data-bs-toggle="collapse" href="#kt_customer_view_payment_method_2" role="button" aria-expanded="false" aria-controls="kt_customer_view_payment_method_1">                      
                    <div class="me-3 rotate-90">                        
                      <span class="svg-icon svg-icon-3">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor" />
                        </svg>
                      </span>                        
                    </div>
                    <div class="">
                      <div class="d-flex align-items-center">
                        <div class="text-gray-800 fw-bold">Dominant Passive</div>
                        <div class="badge badge-light-warning ms-5">{{ dominantPassivePositions.length }} Position Involved</div>
                      </div>
                      <div class="text-muted">The position in this phase must be dominant passive in carrying out its work</div>
                    </div>                      
                  </div>
                </div>                                    
                <div id="kt_customer_view_payment_method_2" class="collapse  fs-6 ps-10" data-bs-parent="#kt_customer_view_payment_method">                    
                  <div class="d-flex flex-wrap py-5" v-if="dominantPassivePositions.length > 0">
                    <ul>
                      <li class="text-gray-800" v-for="(position, index) in dominantPassivePositions" :key="index">{{ position }}</li>
                    </ul>
                  </div> 
                  <p class="text-center text-gray-800" v-else>No position involved</p>
                </div>                  
              </div>                
              <div class="separator separator-dashed"></div>
              <div class="py-0" data-kt-customer-payment-method="row">
                <div class="py-3 d-flex flex-stack flex-wrap">                    
                  <div class="d-flex align-items-center collapsible rotate collapsed" data-bs-toggle="collapse" href="#kt_customer_view_payment_method_3" role="button" aria-expanded="false" aria-controls="kt_customer_view_payment_method_1">                      
                    <div class="me-3 rotate-90">                        
                      <span class="svg-icon svg-icon-3">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor" />
                        </svg>
                      </span>                        
                    </div>
                    <div class="">
                      <div class="d-flex align-items-center">
                        <div class="text-gray-800 fw-bold">Passive</div>
                        <div class="badge badge-light-primary ms-5">{{ passivePositions.length }} Position Involved</div>
                      </div>
                      <div class="text-muted">The position in this phase must be dominant passive in carrying out its work</div>
                    </div>                      
                  </div>
                </div>                                    
                <div id="kt_customer_view_payment_method_3" class="collapse  fs-6 ps-10" data-bs-parent="#kt_customer_view_payment_method">                    
                  <div class="d-flex flex-wrap py-5" v-if="passivePositions.length > 0">
                    <ul>
                      <li class="text-gray-800" v-for="(position, index) in passivePositions" :key="index">{{ position }}</li>
                    </ul>
                  </div> 
                  <p class="text-center text-gray-800" v-else>No position involved</p>
                </div>                  
              </div>                
              <div class="separator separator-dashed"></div>
              
              <div style="text-align: right;">
                <button type="button" class="btn btn-sm btn-light btn-active-light-primary text-nowrap mt-6" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions</button>
                <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div class="menu-item px-3">
                    <a href="#" class="menu-link px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_add_phase" @click="detail = JSON.parse(JSON.stringify(activePhase))">Edit</a>
                  </div>
                  <div class="menu-item px-3">
                    <a href="#" class="menu-link px-3" @click="deletePhase(activePhase)">Delete</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column flex-center" v-if="phases.length == 0">
      <img src="/assets/media/illustrations/sigma-1/21.png" class="mw-400px">
      <div class="fs-1 fw-bolder text-dark mb-4">No phases found.</div>
      <div class="fs-6">Start creating new phases!</div>
    </div>

    <div class="modal fade" id="kt_modal_add_phase" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
          <div class="modal-header" id="kt_modal_add_phase_header">
            <h2 class="fw-bold">{{ detail ? 'Edit' : 'Add New'}} Phase</h2>
            <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal">
              <span class="svg-icon svg-icon-1">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                </svg>
              </span>
            </div>
          </div>
          <div class="modal-body mx-5 mx-xl-15 my-7">
            <ValidationObserver ref="observer">
              <form class="form" @submit.prevent="createPhase()">
                <div class="d-flex flex-column me-n7 pe-7" id="kt_modal_add_phase_scroll">
                  <div class="fv-row mb-7">
                    <ValidationProvider name="Phase name" v-slot="{ errors }" :rules="{ required: true }" ref="name">
                      <label class="required fw-semibold fs-6 mb-2">Phase Name</label>
                      <input type="text" name="phase_name" class="form-control form-control-solid mb-3 mb-lg-0" placeholder="Phase name" v-model="request.name"/>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row mb-7">
                    <ValidationProvider name="Description" v-slot="{ errors }" :rules="{ required: true }" ref="description">
                      <label class="required fw-semibold fs-6 mb-2">Description</label>
                      <textarea class="form-control form-control-solid mb-3 mb-lg-0" id="" cols="30" rows="5" placeholder="Description" v-model="request.description"></textarea>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row">
                    <ValidationProvider name="Involved Positions" v-slot="{ errors }" :rules="{ required: true }" ref="positions">
                      <label class="required fw-semibold fs-6 mb-2">Involved Positions</label>
                      <input type="hidden" name="" v-model="request.positions">
                      <small class="text-danger text-sm text-xs" v-if="errors[0]"><br>{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row" v-for="(involvedPosition, index) in request.positions" :key="index">
                    <div class="row">
                      <div class="col fv-row fv-plugins-icon-container">
                        <ValidationProvider :name="'Position ' + (index+1)" v-slot="{ errors }" :rules="{ required: true }">
                          <multiselect 
                            track-by="id"
                            label="title"
                            placeholder="Select position"
                            v-model="involvedPosition.position_id" 
                            :options="positions.filter( x => selectedPositions.indexOf(x.id) == -1  || x == involvedPosition.position_id )"
                            :allow-empty="false"
                            :close-on-select="true"
                            :multiple="false"
                            :searchable="true"
                            :hide-selected="false"
                            :showLabels="false"
                          ></multiselect>
                          <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </div>
                      <div class="col fv-row fv-plugins-icon-container">
                        <ValidationProvider :name="'Domination type of position ' + (index+1)" v-slot="{ errors }" :rules="{ required: true }">
                          <multiselect 
                            track-by="value"
                            label="text"
                            placeholder="Select domination type"
                            v-model="involvedPosition.activity" 
                            :options="activities"
                            :allow-empty="false"
                            :close-on-select="true"
                            :multiple="false"
                            :searchable="true"
                            :showLabels="false"
                          ></multiselect>
                          <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </div>
                      <button type="button" class="btn btn-icon btn-light btn-active-light-primary align-self-start me-3" title="Remove Position" @click="removePosition(index)" v-if="request.positions.length != 1">
                        <span class="svg-icon svg-icon-3">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path>
                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path>
                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path>
                          </svg>
                        </span>
                      </button>
                    </div>
                    <div class="separator separator-dashed my-4"  v-if="index != (request.positions.length-1)"></div>
                  </div>
                  <div class="d-flex justify-content-end">
                    <button type="button" class="btn btn-light btn-active-light-primary align-self-center mt-4" title="Add more Position" @click="addMorePosition()">
                      <span class="svg-icon svg-icon-3">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M3 13V11C3 10.4 3.4 10 4 10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14H4C3.4 14 3 13.6 3 13Z" fill="currentColor"/>
                          <path d="M13 21H11C10.4 21 10 20.6 10 20V4C10 3.4 10.4 3 11 3H13C13.6 3 14 3.4 14 4V20C14 20.6 13.6 21 13 21Z" fill="currentColor"/>
                        </svg>
                      </span>
                      Add More Position
                    </button>
                  </div>
                </div>
                <div class="text-center pt-15">
                  <button class="btn btn-light me-3" data-bs-dismiss="modal">Discard</button>
                  <button type="submit" class="btn btn-primary">
                    <span class="indicator-label" v-if="!loading">Submit</span>
                    <span class="indicator-progress" v-else>
                      Please wait...
                      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phases: [],
      activePhase: null,
      detail: null,
      request: {
        name: null,
        description: null,
        positions: []
      },
      loading: false,
      positions: [],
      activities: [
        { value: 1, text: 'Dominant Active' },
        { value: 2, text: 'Dominant Passive' },
        { value: 3, text: 'Passive' },
      ],
    }
  },
  computed: {
    dominantPositions: function() {
      return this.activePhase ? this.activePhase.positions.filter( x => x.activity === 1 && x.position ).map( x => x.position.title ) : []
    },
    dominantPassivePositions: function() {
      return this.activePhase ? this.activePhase.positions.filter( x => x.activity === 2 && x.position ).map( x => x.position.title ) : []
    },
    passivePositions: function() {
      return this.activePhase ? this.activePhase.positions.filter( x => x.activity === 3 && x.position ).map( x => x.position.title ) : []
    },
    selectedPositions: function() {
      return this.requestBody.positions.map( x => x.position_id )
    },
    requestBody: function() {
      let _ = this
      return {
        ..._.request,
        positions: _.request.positions.map( function(x) {
          return {
            position_id: x.position_id ? x.position_id.id : null,
            activity: x.activity ? x.activity.value : null
          }
        })
      }
    }
  },
  methods: {
    get(latest = null) {
      let _ = this

      _.axios.get('/v1/phase')
        .then( resp => {
          _.phases = resp.data.data

          if (latest) {
            _.activePhase = _.phases[_.phases.length-1]
          } else {
            if ( _.phases.length > 0 && !_.activePhase ) {
              _.activePhase = _.phases[0]
            } else {
              const searchPhase = _.phases.map( x => x.id ).indexOf(_.activePhase.id)
              if (searchPhase != -1) {
                _.activePhase = _.phases[searchPhase]
              } else {
                _.activePhase = _.phases[0]
              }
            }
          }
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })

          _.loading = false
        })
    },
    async createPhase() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      if ( _.detail ) {
        this.updatePhase() 
        return
      }

      _.loading = true
      _.axios.post('/v1/phase', _.requestBody)
        .then(() => {
          _.loading = false
          _.hideModal()
          _.get(true)
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully add new phase',
            text: `You have successfully add new phase`
          })
        })
        .catch( err => {
          if ( typeof err.response.data.errors === 'object' && err.response.data.errors !== null ) {
            Object.keys(err.response.data.errors).forEach(elm => {
              _.$refs[elm].applyResult({
                errors: [err.response.data.errors[elm][0]],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
          _.loading = false
        })
    }, 
    deletePhase(data) {
      let _ = this
      _.$swal.fire({
        title: 'You are about to delete this phase',
        text: 'Do you really want to delete this phase? This process cannot be undone.',
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: 'No, cancel',
        confirmButtonText: 'Yes, sure',
        focusCancel: true
        }).then((result) => {
          if (result.value) {
            _.axios.delete(`/v1/phase/${data.id}`)
              .then(() => {
                _.$swalToast.fire({
                  icon: 'success',
                  title: 'Successfully delete phase',
                  text: 'You have successfully delete this phase'
                })

                _.get()
              })
              .catch(err=>{
                _.$swalToast.fire({
                  icon: 'error',
                  title: `Oops! Something went wrong (${err.response.status})`,
                  text: err.response.data.errors
                })
              })
          }
      })
    },
    async reset() {
      this.detail = null
      this.request = {
        name: null,
        description: null,
        positions: []
      }
      this.request.positions.push({
        position_id: null,
        activity: null
      })
      await this.$refs.observer.reset()
    },
    async updatePhase() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      _.loading = true
      _.axios.put(`/v1/phase/${_.detail.id}`, _.requestBody)
        .then(() => {
          _.loading = false
          _.hideModal()
          _.get()
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully edit phase',
            text: `You have successfully edit phase`
          })
        })
        .catch( err => {
          if ( typeof err.response.data.errors === 'object' && err.response.data.errors !== null ) {
            Object.keys(err.response.data.errors).forEach(elm => {
              _.$refs[elm].applyResult({
                errors: [err.response.data.errors[elm][0]],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
          _.loading = false
        })
    },
    getPosition() {
      let _ = this
      _.axios.get('/v1/position')
        .then( resp => {
          _.positions = resp.data.data
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    addMorePosition() {
      this.request.positions.push({
        position_id: null,
        activity: null
      })
    },
    removePosition(index) {
      this.request.positions.splice(index, 1)
    }
  },
  mounted() {
    this.get();
    this.getPosition()

    this.hideModal()

    $(document).ready(function() {
      $('textarea').keypress(function(event) {
        if (event.keyCode == 13) {
          event.preventDefault();
        }
      });
    });
  },
  watch: {
    detail: function() {
      let _ = this
      if ( _.detail ) {
        _.request = {
          name: _.detail.name,
          description: _.detail.description,
          positions: _.detail.positions.map( function(x) {
            const searchActivity = _.activities.map( x => x.value ).indexOf( x.activity )
            return {
              position_id: x.position,
              activity: _.activities[searchActivity]
            }
          })
        }
      }
    },
    activePhase: function() {
      setTimeout(() => {
        KTMenu.createInstances();
      }, 500);
    }
  }
}
</script>