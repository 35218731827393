import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store'

import PageNotFound from '../views/error/404.vue'
import PageUnderMaintenance from '../views/error/503.vue'

import Login from '../views/auth/Login.vue'

import Profile from '../views/profile/Index.vue'

import User from '../views/user_management/user/Index.vue'
import UserDetail from '../views/user_management/user/Detail.vue'
import RoleAndPermission from '../views/user_management/role_and_permission/Index.vue'
import RoleAndPermissionDetail from '../views/user_management/role_and_permission/Detail.vue'
import Position from '../views/user_management/position/Index.vue'
import PositionDetail from '../views/user_management/position/Detail.vue'

import Framework from '../views/project_management/framework/Index.vue'
import Category from '../views/project_management/category/Index.vue'
import Phase from '../views/project_management/phase/Index.vue'
import Client from '../views/project_management/client/Index.vue'
import Project from '../views/project_management/project/Index.vue'
import ProjectDetail from '../views/project_management/project/detail/Index.vue'

import FileManager from '../views/file_manager/Index.vue'

import IntegrationSetting from '../views/integration_setting/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: () => import ('../layouts/Main.vue'),
    children: [
      {
          path: '/',
          redirect: '/dashboard'
      },
      {
          path: '/dashboard',
          name: 'Dashboard',
          component: PageUnderMaintenance,
          meta: {
            title: 'Dashboard',
            breadcrumbs: [
              'Dashboard'
            ],
            minimizedMenu: true
          }
      },
      {
          path: '/profile/:tab',
          name: 'Profile',
          component: Profile,
          meta: {
            title: 'Profile',
            breadcrumbs: [
              'Profile'
            ]
          }
      },
      {
          path: '/file-manager',
          name: 'FileManager',
          component: FileManager,
          meta: {
            title: 'File Manager',
            breadcrumbs: [
              'File Manager'
            ]
          }
      },
      {
          path: '/user-management/user',
          name: 'Users',
          component: User,
          meta: {
            title: 'Users',
            breadcrumbs: [
              'User Management',
              'Users'
            ]
          }
      },
      {
          path: '/user-management/user/detail/:id',
          name: 'UserDetail',
          component: UserDetail,
          meta: {
            title: "User Details",
            breadcrumbs: [
              'User Management',
              'Users',
              'User Details'
            ],
            minimizedMenu: true,
          }
      },
      {
          path: '/user-management/role-and-permission',
          name: 'RoleAndPermission',
          component: RoleAndPermission,
          meta: {
            title: 'Role And Permission',
            breadcrumbs: [
              'User Management',
              'Roles And Permissions'
            ]
          }
      },
      {
          path: '/user-management/role-and-permission/detail/:id',
          name: 'RoleAndPermissionDetail',
          component: RoleAndPermissionDetail,
          meta: {
            title: 'Role And Permission Details',
            breadcrumbs: [
              'User Management',
              'Roles And Permissions',
              'Role And Permission Details'
            ],
            minimizedMenu: true,
          }
      },
      {
          path: '/user-management/position',
          name: 'Position',
          component: Position,
          meta: {
            title: 'Positions',
            breadcrumbs: [
              'User Management',
              'Positions'
            ]
          }
      },
      {
          path: '/user-management/position/detail/:id',
          name: 'PositionDetail',
          component: PositionDetail,
          meta: {
            title: 'Position Details',
            breadcrumbs: [
              'User Management',
              'Positions',
              'Position Details'
            ],
            minimizedMenu: true,
          }
      },
      {
          path: '/project-management/project',
          name: 'Projects',
          component: Project,
          meta: {
            title: 'Projects',
            breadcrumbs: [
              'Project Management',
              'Projects'
            ],
            minimizedMenu: true
          }
      },
      {
          path: '/project-management/project/detail/:id/:tab',
          name: 'Project Detail',
          component: ProjectDetail,
          meta: {
            title: 'Projects',
            breadcrumbs: [
              'Project Management',
              'Projects',
              'Project Detail'
            ],
            minimizedMenu: true
          }
      },
      {
          path: '/project-management/framework',
          name: 'Frameworks',
          component: Framework,
          meta: {
            title: 'Frameworks',
            breadcrumbs: [
              'Project Management',
              'Frameworks'
            ]
          }
      },
      {
          path: '/project-management/category',
          name: 'Categories',
          component: Category,
          meta: {
            title: 'Categories',
            breadcrumbs: [
              'Project Management',
              'Categories'
            ]
          }
      },
      {
          path: '/project-management/phase',
          name: 'Phases',
          component: Phase,
          meta: {
            title: 'Phases',
            breadcrumbs: [
              'Project Management',
              'Phases'
            ]
          }
      },
      {
        path: '/project-management/client',
        name: 'Clients',
        component: Client,
        meta: {
          title: 'Clients',
          breadcrumbs: [
            'Project Management',
            'Clients'
          ]
        }
      },
      {
          path: '/statement',
          name: 'Statements',
          component: PageUnderMaintenance,
          meta: {
            title: 'Statements',
            breadcrumbs: [
              'Statements',
            ]
          }
      },
      {
          path: '/integration-setting',
          name: 'IntegrationSetting',
          component: IntegrationSetting,
          meta: {
            title: 'Integration Setting',
            breadcrumbs: [
              'Integration Setting',
            ]
          }
      },
    ]
  },
  {
    path: '',
    component: () => import ('../layouts/Authentication.vue'),
    children: [
      {
          path: '/login',
          name: 'Login',
          component: Login,
          meta: {
            isGuest: true
          }
      },
    ]
  },
  {
    path: '',
    component: () => import ('../layouts/SystemLayout.vue'),
    children: [
      {
        path: '/404',
        name: '404',
        component: PageNotFound,
      },
    ]
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if ( to.meta.isGuest ) {
    next()
  } else if ( !localStorage.getItem('token') ) {
    next({name: 'Login'})
  } else {
    next()
  }
})

export default router
