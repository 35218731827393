<template>
  <div>
    <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9 mb-6">
      <div class="col-md-4">
        <div class="card h-md-100">
          <div class="card-body d-flex flex-center">
            <button type="button" class="btn btn-clear d-flex flex-column flex-center" data-bs-toggle="modal" data-bs-target="#kt_modal_add_role" @click="reset()">
              <img src="/assets/media/illustrations/sigma-1/4.png" alt="" class="mw-100 mh-150px mb-7" />
              <div class="fw-bold fs-3 text-gray-600 text-hover-primary">Add New Position</div>
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-4" v-for="position in table.data" :key="position.id">
        <div class="card card-flush h-md-100">
          <div class="card-header">
            <div class="card-title">
              <h2>{{ position.title }}</h2>
            </div>
          </div>
          <div class="card-body pt-1">
            <div class="fw-bold text-gray-600 mb-5">Total users with this position: {{ position.users_count | numFormat }}</div>
            <div class="d-flex flex-column text-gray-600">
              <div class="d-flex align-items-center py-2">{{ position.description.length > 100 ? position.description.substring(0, 100) + '...' : position.description }}</div>
            </div>
          </div>
          <div class="card-footer flex-wrap pt-0">
            <router-link :to="`/user-management/position/detail/${position.id}`" class="btn btn-light btn-active-primary my-1 me-2">View Position</router-link>
            <button type="button" class="btn btn-light btn-active-light-primary my-1" data-bs-toggle="modal" data-bs-target="#kt_modal_add_role" @click="setDetail(position)">Edit Position</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="kt_modal_add_role" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered mw-750px">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="fw-bold">{{ detail ? 'Edit' : 'Add New' }} Position</h2>
            <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal">
              <span class="svg-icon svg-icon-1">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                </svg>
              </span>
            </div>
          </div>
          <div class="modal-body scroll-y mx-lg-5 my-7">
            <ValidationObserver ref="observer">
              <form class="form" @submit.prevent="createPosition()">
                <div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_role_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_role_header" data-kt-scroll-wrappers="#kt_modal_add_role_scroll" data-kt-scroll-offset="300px">
                  <div class="fv-row mb-10">
                    <ValidationProvider name="Position name" v-slot="{ errors }" :rules="{ required: true }" ref="title">
                      <label class="fs-5 fw-bold form-label mb-2">
                        <span class="required">Position Name</span>
                      </label>
                      <input class="form-control form-control-solid" placeholder="Enter a position name" v-model="request.title" />
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row">
                    <ValidationProvider name="Job description" v-slot="{ errors }" :rules="{ required: true }" ref="description">
                      <label class="fs-5 fw-bold form-label mb-2">
                        <span class="required">Job Description</span>
                      </label>
                      <textarea class="form-control form-control-solid" placeholder="Enter a job description" cols="30" rows="5" v-model="request.description"></textarea>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="text-center pt-15">
                  <button type="reset" class="btn btn-light me-3" data-bs-dismiss="modal">Discard</button>
                  <button type="submit" class="btn btn-primary">
                    <span class="indicator-label" v-if="!loading">Submit</span>
                    <span class="indicator-progress" v-else>
                      Please wait...
                      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <PaginationInfiniteScroll :data="table.meta" v-if="table.data.length != 0"/>
  </div>
</template>
<script>
import PaginationInfiniteScroll from '@/components/PaginationInfiniteScroll'

export default {
  components: {
    PaginationInfiniteScroll
	},
  data() {
    return {
      table: {
        data: [],
        meta: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
        },
        params: {
          search: '',
          sortBy: 'updated_at.DESC',
        }
      },
      request: {
        title: null,
        description: null,
      },
      detail: null,
      loading: false,
    }
  },
  methods: {
    getPosition( page = 1 ) {
      let _ = this
      _.axios.get('/v1/position'
          + '?page=' + page
          + '&limit=' + _.table.meta.per_page
          + '&sortBy=' + _.table.params.sortBy
          + '&search=' + _.table.params.search
        )
        .then( resp => {
          const data = resp.data

          _.table.meta = data.meta

          data.data.forEach(elm => {
            _.table.data.push({ ...elm})
          });
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    async createPosition() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      if ( _.detail ) {
        this.updatePosition() 
        return
      }

      _.loading = true
      _.axios.post('/v1/position', _.request)
        .then(() => {
          _.loading = false
          _.hideModal()

          _.table.data = []
          _.getPosition()
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully add new position',
            text: `You have successfully add new position`
          })
        })
        .catch( err => {
          if ( typeof err.response.data.errors === 'object' && err.response.data.errors !== null ) {
            Object.keys(err.response.data.errors).forEach(elm => {
              _.$refs[elm].applyResult({
                errors: [err.response.data.errors[elm][0]],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
          _.loading = false
        })
    },
    async updatePosition() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      _.loading = true
      _.axios.put(`/v1/position/${_.detail.id}`, _.request)
        .then(()=> {
          _.loading = false
          _.hideModal()
          
          _.table.data = []
          _.getPosition()
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully edit position',
            text: `You have successfully edit position`
          })
        })
        .catch( err => {
          if ( typeof err.response.data.errors === 'object' && err.response.data.errors !== null ) {
            Object.keys(err.response.data.errors).forEach(elm => {
              _.$refs[elm].applyResult({
                errors: [err.response.data.errors[elm][0]],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
          _.loading = false
        })
    },
    async reset() {
      this.detail = null
      this.request = {
        title: null,
        description: null,
      }
      await this.$refs.observer.reset()
    },
    setDetail( data ) {
      let _ = this
      _.detail = JSON.parse(JSON.stringify(data))
      _.request.title = _.detail.title
      _.request.description = _.detail.description
    }
  },
  mounted() {
    let _ = this
    _.getPosition()

    _.$root.$on("changePage", (data) => {
      _.getPosition(data)
    });

    $(document).ready(function() {
      $('textarea').keypress(function(event) {
        if (event.keyCode == 13) {
          event.preventDefault();
        }
      });
    });
  },
  beforeDestroy(){
    this.$root.$off("changePage");
  },
}
</script>