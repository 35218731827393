<template>
  <div>
    <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9 mb-6">
      <div class="ol-md-4">
        <div class="card h-md-100">
          <div class="card-body d-flex flex-center">
            <button type="button" class="btn btn-clear d-flex flex-column flex-center" data-bs-toggle="modal" data-bs-target="#kt_modal_add_role" @click="reset()">
              <img src="/assets/media/illustrations/sigma-1/4.png" alt="" class="mw-100 mh-150px mb-7" />
              <div class="fw-bold fs-3 text-gray-600 text-hover-primary">Add New Role</div>
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-4" v-for="role in table.data" :key="role.id">
        <div class="card card-flush h-md-100">
          <div class="card-header">
            <div class="card-title">
              <h2>{{ role.name }}</h2>
            </div>
          </div>
          <div class="card-body pt-1">
            <div class="fw-bold text-gray-600 mb-5">Total users with this role: {{ role.employees_count | numFormat }}</div>
            <div class="d-flex flex-column text-gray-600">
              <div class="d-flex align-items-center py-2">{{ role.description && role.description.length > 100 ? role.description.substring(0, 100) + '...' : role.description }}</div>
            </div>
          </div>
          <div class="card-footer flex-wrap pt-0">
            <router-link :to="`/user-management/role-and-permission/detail/${role.id}`" class="btn btn-light btn-active-primary my-1 me-2">View Role</router-link>
            <button type="button" class="btn btn-light btn-active-light-primary my-1" data-bs-toggle="modal" data-bs-target="#kt_modal_add_role" @click="getRoleDetail(role)">Edit Role</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="kt_modal_add_role" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered mw-750px">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="fw-bold">{{ detail ? 'Edit' : 'Add New' }} Role</h2>
            <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal">
              <span class="svg-icon svg-icon-1">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                </svg>
              </span>
            </div>
          </div>
          <div class="modal-body scroll-y mx-lg-5 my-7">
            <ValidationObserver ref="observer">
              <form class="form" @submit.prevent="createRole()">
                <div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_role_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_role_header" data-kt-scroll-wrappers="#kt_modal_add_role_scroll" data-kt-scroll-offset="300px">
                  <div class="fv-row mb-10">
                    <ValidationProvider name="Role name" v-slot="{ errors }" :rules="{ required: true }" ref="name">
                      <label class="fs-5 fw-bold form-label mb-2">
                        <span class="required">Role Name</span>
                      </label>
                      <input class="form-control form-control-solid" placeholder="Enter a role name" v-model="request.name" />
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row mb-10">
                    <ValidationProvider name="Description" v-slot="{ errors }" :rules="{ required: true }" ref="description">
                      <label class="fs-5 fw-bold form-label mb-2">
                        <span class="required">Description</span>
                      </label>
                      <textarea class="form-control form-control-solid" placeholder="Enter a role description" v-model="request.description" cols="30" rows="5"></textarea>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row">
                    <label class="fs-5 fw-bold form-label mb-2">Role Permissions</label>
                    <div class="table-responsive">
                      <table class="table align-middle table-row-dashed fs-6 gy-5">
                        <tbody class="text-gray-600 fw-semibold">
                          <tr>
                            <td class="text-gray-800" style="white-space: nowrap;">Administrator Access
                            <i class="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Allows a full access to the system"></i></td>
                            <td>
                              <label class="form-check form-check-custom form-check-solid me-9">
                                <input class="form-check-input" type="checkbox" value="" id="kt_roles_select_all" @click="selectAll($event)" :checked="isAllChecked"/>
                                <span class="form-check-label" for="kt_roles_select_all">Select all</span>
                              </label>
                            </td>
                          </tr>
                          <tr v-for="(permission, index) in permissions" :key="index">
                            <td class="text-gray-800">{{ permission.parentName }}</td>
                            <td>
                              <div class="d-flex flex-wrap">
                                <label class="col-6 form-check form-check-sm form-check-custom form-check-solid" 
                                  v-for="(rule, indexRule) in permission.rules" :key="indexRule">
                                  <input class="form-check-input align-self-start" type="checkbox" :value="rule.id" v-model="request.permissions"
                                    :checked="request.permissions.some((obj)=> obj == rule.id)"/>
                                  <span class="form-check-label mb-1 align-self-start">{{ rule.name }}</span>
                                </label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="text-center pt-15">
                  <button type="reset" class="btn btn-light me-3" data-bs-dismiss="modal">Discard</button>
                  <button type="submit" class="btn btn-primary">
                    <span class="indicator-label" v-if="!loading">Submit</span>
                    <span class="indicator-progress" v-else>
                      Please wait...
                      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <PaginationInfiniteScroll :data="table.meta" v-if="table.data.length != 0"/>
  </div>
</template>
<script>
import PaginationInfiniteScroll from '@/components/PaginationInfiniteScroll'

export default {
  components: {
    PaginationInfiniteScroll
	},
  data() {
    return {
      table: {
        data: [],
        meta: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
        },
        params: {
          search: '',
          sort_by: 'updated_at.DESC',
        }
      },
      request: {
        name: null,
        description: null,
        permissions: [],
      },
      detail: null,
      loading: false,
      permissions: [],
      permissionTotal: 0,
      menus: [],
    }
  },
  computed: {
    isAllChecked: function() {
      return this.request.permissions.length == this.permissionTotal
    }
  },
  methods: {
    getRole( page = 1 ) {
      let _ = this
      _.axios.get('/v1/role'
          + '?page=' + page
          + '&sort_by=' + _.table.params.sort_by
          + '&limit=' + 10
        )
        .then( resp => {
          const data = resp.data

          _.table.meta = data.meta

          data.data.forEach(elm => {
            _.table.data.push({ ...elm})
          });
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    getPermission() {
      let _ = this
      _.axios.get('/v1/misc/permission')
        .then( resp => {
          const data = resp.data.data
          const groupBy = (x,f)=>x.reduce((a,b,i)=>((a[f(b,i,x)]||=[]).push(b),a),{});
          const groups = (groupBy(data, v => v.menu_id))
          _.permissionTotal = data.length

          Object.keys(groups).forEach(elm => {
            let parentName = ""
            const searchParentName = _.menus.map( x => x.id ).indexOf(parseInt(elm))
            if ( searchParentName != -1 ) {
              parentName = _.menus[searchParentName].name
            }

            _.permissions.push({
              parentName: parentName,
              rules: groups[elm]
            })
          });
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    getMenu() {
      let _ = this
      _.axios.get('/v1/misc/menu')
        .then( resp => {
          const data = resp.data.data
          data.forEach(elm => {
            let hehe = {
              ...elm
            }
            delete hehe.submenu

            _.menus.push(hehe)

            if (elm.submenu.length > 0) {
              elm.submenu.forEach(sub => {
                _.menus.push(sub)
              });
            }
          });

          this.getPermission()
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    async createRole() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      if ( _.detail ) {
        this.updateRole() 
        return
      }

      _.loading = true
      _.axios.post('/v1/role', _.request)
        .then(() => {
          _.loading = false
          _.hideModal()

          _.table.data = []
          _.getRole()
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully add new role',
            text: `You have successfully add new role`
          })
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              _.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
          _.loading = false
        })
    },
    async updateRole() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      _.loading = true
      _.axios.put(`/v1/role/${_.detail.id}`, _.request)
        .then(()=> {
          _.loading = false
          _.hideModal()
          
          _.table.data = []
          _.getRole()
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully edit role',
            text: `You have successfully edit role`
          })
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              _.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
          _.loading = false
        })
    },
    async reset() {
      this.detail = null
      this.request = {
        name: null,
        description: null,
        permissions: [],
      }
      await this.$refs.observer.reset()
    },
    selectAll(event){
      let _ = this
      _.request.permissions = []

      if (event.target.checked) {
        _.permissions.forEach(permission => {
          permission.rules.forEach(rule => {
            _.request.permissions.push(rule.id)
          });
        });
      } 
    },
    getRoleDetail(role) {
      let _ = this
      _.reset()
      _.axios.get('/v1/role/' + role.id)
        .then( resp => {
          _.detail = JSON.parse(JSON.stringify(resp.data.data))
          _.request.name = _.detail.name
          _.request.description = _.detail.description
          _.request.permissions = _.detail.permissions
        })
        .catch( err => {
          console.log(err)
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    }
  },
  mounted() {
    let _ = this
    _.getRole()
    _.getMenu()

    _.$root.$on("changePage", (data) => {
      _.getRole(data)
    });

    $(document).ready(function() {
      $('[data-bs-toggle="tooltip"]').tooltip()
      $('textarea').keypress(function(event) {
        if (event.keyCode == 13) {
          event.preventDefault();
        }
      });
    });
  },
  beforeDestroy(){
    this.$root.$off("changePage");
  },
}
</script>