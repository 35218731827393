<template>
  <div>
    <div class="card card-flush pb-0 bgi-position-y-center bgi-no-repeat mb-10" style="background-size: auto calc(100% + 10rem); background-position-x: 100%; background-image: url('assets/media/illustrations/sigma-1/4.png')">
      <div class="card-header pt-10">
        <div class="d-flex align-items-center">
          <div class="symbol symbol-circle me-5">
            <div class="symbol-label bg-transparent text-primary border border-secondary border-dashed">
              <span class="svg-icon svg-icon-2x svg-icon-primary">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.302 11.35L12.002 20.55H21.202C21.802 20.55 22.202 19.85 21.902 19.35L17.302 11.35Z" fill="currentColor" />
                  <path opacity="0.3" d="M12.002 20.55H2.802C2.202 20.55 1.80202 19.85 2.10202 19.35L6.70203 11.45L12.002 20.55ZM11.302 3.45L6.70203 11.35H17.302L12.702 3.45C12.402 2.85 11.602 2.85 11.302 3.45Z" fill="currentColor" />
                </svg>
              </span>
            </div>
          </div>
          <div class="d-flex flex-column">
            <h2 class="mb-1">File Manager</h2>
            <div class="text-muted fw-bold">
              <a href="#">ERP</a>
              <!-- <span class="mx-3">|</span>{{ formatBytes(information.size) }}
              <span class="mx-3">|</span>{{ information.item | numFormat }} items -->
            </div>
          </div>
        </div>
      </div>
      <div class="card-body pb-0">
        <div class="d-flex overflow-auto h-55px">
          <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap">
            <li class="nav-item">
              <a class="nav-link text-active-primary me-6 active" href="#">Files</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="card card-flush">
      <div class="card-header pt-8">
        <div class="card-title">
          <div class="d-flex align-items-center position-relative my-1">
            <!-- <span class="svg-icon svg-icon-1 position-absolute ms-6">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
              </svg>
            </span> -->
            <!-- <input type="text" class="form-control form-control-solid w-250px ps-15" placeholder="Search Files & Folders" 
              v-debounce:300.lock="handleSearch" v-model="table.params.search"/> -->
            <a :href="'https://drive.google.com/drive/u/0/folders/' + googleDriveData.root_file_manager_folder" target="_blank" rel="noopener noreferrer" class="btn btn-light-primary me-3">
              <span class="svg-icon svg-icon-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z" fill="currentColor"></path><rect x="21.9497" y="3.46448" width="13" height="2" rx="1" transform="rotate(135 21.9497 3.46448)" fill="currentColor"></rect><path d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z" fill="currentColor"></path></svg>
              </span>
              Open Drive
            </a>
          </div>
        </div>
        <div class="card-toolbar">
          <div class="d-flex justify-content-end" data-kt-filemanager-table-toolbar="base" v-if="selected.length == 0">
            <button type="button" class="btn btn-light-primary me-3" id="kt_file_manager_new_folder" 
              @click="
                isCreateNewFolder = true, 
                resetRequestNewFolder(),
                requestNewFolder.parent_id = (parentIds.length > 0 ? parentIds[parentIds.length-1].id : null)">
            <span class="svg-icon svg-icon-2">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor" />
                <path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z" fill="currentColor" />
                <path opacity="0.3" d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z" fill="currentColor" />
              </svg>
            </span>New Folder</button>
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_upload" @click="fileQueues = []">
            <span class="svg-icon svg-icon-2">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor" />
                <path d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z" fill="currentColor" />
                <path opacity="0.3" d="M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z" fill="currentColor" />
              </svg>
            </span>Upload Files</button>
          </div>
          <div class="d-flex justify-content-end align-items-center my-1" v-else>
            <div class="fw-bold me-5">
            <span class="me-2">{{ selected.length | numFormat }}</span>Selected</div>
            <button type="button" class="btn btn-danger" @click="bulkDeleteFile()">Delete Selected</button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="d-flex flex-stack">
          <div class="badge badge-lg badge-light-primary">
            <div class="d-flex align-items-center flex-wrap">
              <span class="svg-icon svg-icon-2 svg-icon-primary me-3">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.3" d="M14.1 15.013C14.6 16.313 14.5 17.813 13.7 19.113C12.3 21.513 9.29999 22.313 6.89999 20.913C5.29999 20.013 4.39999 18.313 4.39999 16.613C5.09999 17.013 5.99999 17.313 6.89999 17.313C8.39999 17.313 9.69998 16.613 10.7 15.613C11.1 15.713 11.5 15.813 11.9 15.813C12.7 15.813 13.5 15.513 14.1 15.013ZM8.5 12.913C8.5 12.713 8.39999 12.513 8.39999 12.313C8.39999 11.213 8.89998 10.213 9.69998 9.613C9.19998 8.313 9.30001 6.813 10.1 5.513C10.6 4.713 11.2 4.11299 11.9 3.71299C10.4 2.81299 8.49999 2.71299 6.89999 3.71299C4.49999 5.11299 3.70001 8.113 5.10001 10.513C5.80001 11.813 7.1 12.613 8.5 12.913ZM16.9 7.313C15.4 7.313 14.1 8.013 13.1 9.013C14.3 9.413 15.1 10.513 15.3 11.713C16.7 12.013 17.9 12.813 18.7 14.113C19.2 14.913 19.3 15.713 19.3 16.613C20.8 15.713 21.8 14.113 21.8 12.313C21.9 9.513 19.7 7.313 16.9 7.313Z" fill="currentColor" />
                  <path d="M9.69998 9.61307C9.19998 8.31307 9.30001 6.81306 10.1 5.51306C11.5 3.11306 14.5 2.31306 16.9 3.71306C18.5 4.61306 19.4 6.31306 19.4 8.01306C18.7 7.61306 17.8 7.31306 16.9 7.31306C15.4 7.31306 14.1 8.01306 13.1 9.01306C12.7 8.91306 12.3 8.81306 11.9 8.81306C11.1 8.81306 10.3 9.11307 9.69998 9.61307ZM8.5 12.9131C7.1 12.6131 5.90001 11.8131 5.10001 10.5131C4.60001 9.71306 4.5 8.91306 4.5 8.01306C3 8.91306 2 10.5131 2 12.3131C2 15.1131 4.2 17.3131 7 17.3131C8.5 17.3131 9.79999 16.6131 10.8 15.6131C9.49999 15.1131 8.7 14.1131 8.5 12.9131ZM18.7 14.1131C17.9 12.8131 16.7 12.0131 15.3 11.7131C15.3 11.9131 15.4 12.1131 15.4 12.3131C15.4 13.4131 14.9 14.4131 14.1 15.0131C14.6 16.3131 14.5 17.8131 13.7 19.1131C13.2 19.9131 12.6 20.5131 11.9 20.9131C13.4 21.8131 15.3 21.9131 16.9 20.9131C19.3 19.6131 20.1 16.5131 18.7 14.1131Z" fill="currentColor" />
                </svg>
              </span>
              <a href="#" @click="table.params.parent_id = '', removeAllParentIds()">Root</a>
              <div v-for="(parent, index) in parentIds" :key="index">
                <span class="svg-icon svg-icon-2 svg-icon-primary mx-1">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor" />
                  </svg>
                </span>
                <a href="#" v-if="table.params.parent_id != parent.id" @click="table.params.parent_id = parent.id, removeParentIds(index)" style="vertical-align: middle;">{{ parent.name }}</a>
                <span class="" style="vertical-align: middle;" v-else>{{ parent.name }}</span>
              </div>
            </div>
          </div>
          <div class="badge badge-lg badge-primary">
            <span id="kt_file_manager_items_counter">{{ table.data.length }} items</span>
          </div>
        </div>
        <table id="kt_file_manager_list" data-kt-filemanager-table="folders" class="table align-middle table-row-dashed fs-6 gy-5">
          <thead>
            <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
              <th class="w-10px pe-2">
                <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                  <input class="form-check-input" type="checkbox" @click="selectAll($event)" :checked="table.data.length > 0 ? isAllChecked : false" :disabled="table.data.length == 0"/>
                </div>
              </th>
              <th class="min-w-250px">Name</th>
              <th class="min-w-10px">Size</th>
              <th class="min-w-125px">Last Modified</th>
              <th class="w-125px"></th>
            </tr>
          </thead>
          <tbody class="fw-semibold text-gray-600">
            <tr id="kt_file_manager_new_folder_row" data-kt-filemanager-template="upload" v-if="isCreateNewFolder">
              <td></td>
              <td id="kt_file_manager_add_folder_form" class="fv-row" style="padding-left: 0;">
                <ValidationObserver ref="observer">
                  <form class="form" @submit.prevent="createNewFolder()">
                    <ValidationProvider name="Folder name" v-slot="{ errors }" :rules="{ required: true }" ref="name">
                      <div class="d-flex align-items-center">
                        <span class="svg-icon svg-icon-2x svg-icon-primary me-4">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor" />
                            <path d="M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z" fill="currentColor" />
                          </svg>
                        </span>
                        <input type="text" name="new_folder_name" placeholder="Enter the folder name" class="form-control mw-250px me-3" v-model="requestNewFolder.name"/>
                        <button class="btn btn-icon btn-light-primary me-3" type="submit" id="kt_file_manager_add_folder">
                          <span class="indicator-label" v-if="!loading">
                            <span class="svg-icon svg-icon-1">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z" fill="currentColor" />
                              </svg>
                            </span>
                          </span>
                          <span class="indicator-progress" v-else>
                            <span class="spinner-border spinner-border-sm align-middle"></span>
                          </span>
                        </button>
                        <button class="btn btn-icon btn-light-danger" type="button" id="kt_file_manager_cancel_folder" @click="isCreateNewFolder = false">
                          <span class="indicator-label">
                            <span class="svg-icon svg-icon-1">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor" />
                                <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor" />
                              </svg>
                            </span>
                          </span>
                        </button>
                      </div>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </form>
                </ValidationObserver>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <ValidationObserver ref="observer2" style="display: contents;">
              <form class="form" @submit.prevent="updateFileFolder()" style="display: contents;">
                <ValidationProvider name="File name" v-slot="{ errors }" :rules="{ required: true }" ref="name" style="display: contents;">
                  <tr v-for="data in table.data" :key="data.id">
                    <td style="vertical-align:middle;">
                      <div class="form-check form-check-sm form-check-custom form-check-solid">
                        <input class="form-check-input" type="checkbox" :id="data.id" name="select" @click="select(data.id, $event)" :checked="selected.some((obj)=> obj == data.id)"/>
                      </div>
                    </td>
                    <td v-if="renameFileFolder.id != data.id">
                      <div class="d-flex align-items-center" v-if="data.is_folder == 1">
                        <span class="svg-icon svg-icon-2x svg-icon-primary me-4">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor" />
                            <path d="M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z" fill="currentColor" />
                          </svg>
                        </span>
                        <a href="#" @click="table.params.parent_id = data.id, currentParent = data, parentIds.push(data)" class="text-gray-800 text-hover-primary">{{ data.name }}</a>
                      </div>
                      <div class="d-flex align-items-center" v-else>
                        <span class="svg-icon svg-icon-2x svg-icon-primary me-4">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z" fill="currentColor" />
                            <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />
                          </svg>
                        </span>
                        <a :href="data.file_link" target="_blank" class="text-gray-800 text-hover-primary">{{ data.name }}</a>
                      </div>
                    </td>
                    <td class="fv-row" v-else>
                      <div class="d-flex align-items-center">
                        <span class="svg-icon svg-icon-2x svg-icon-primary me-4" v-if="renameFileFolder.is_folder">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor" />
                            <path d="M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z" fill="currentColor" />
                          </svg>
                        </span>
                        <span class="svg-icon svg-icon-2x svg-icon-primary me-4" v-else>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z" fill="currentColor" />
                            <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />
                          </svg>
                        </span>
                        <input type="text" placeholder="Enter the file or folder name" class="form-control mw-250px me-3" v-model="renameFileFolder.name"/>
                        <button class="btn btn-icon btn-light-primary me-3" type="submit">
                          <span class="indicator-label" v-if="!loading">
                            <span class="svg-icon svg-icon-1">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z" fill="currentColor" />
                              </svg>
                            </span>
                          </span>
                          <span class="indicator-progress" v-else>
                            <span class="spinner-border spinner-border-sm align-middle"></span>
                          </span>
                        </button>
                        <button class="btn btn-icon btn-light-danger" type="button" @click="renameFileFolder.id = null">
                          <span class="indicator-label">
                            <span class="svg-icon svg-icon-1">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor" />
                                <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor" />
                              </svg>
                            </span>
                          </span>
                        </button>
                      </div>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </td>
                    <td>{{ data.is_folder == 1 ? '-' : formatBytes(data.size) }}</td>
                    <td>
                      {{ data.updated_at | moment('DD MMM YYYY, hh:mm a') }}
                    </td>
                    <td class="text-end" data-kt-filemanager-table="action_dropdown">
                      <div class="d-flex justify-content-end">
                        <div class="ms-2">
                          <button type="button" class="btn btn-sm btn-icon btn-light btn-active-light-primary me-2" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                            <span class="svg-icon svg-icon-5 m-0">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="10" y="10" width="4" height="4" rx="2" fill="currentColor" />
                                <rect x="17" y="10" width="4" height="4" rx="2" fill="currentColor" />
                                <rect x="3" y="10" width="4" height="4" rx="2" fill="currentColor" />
                              </svg>
                            </span>
                          </button>
                          <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-150px py-4" data-kt-menu="true">
                            <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3" v-if="data.is_folder" @click="table.params.parent_id = data.id, currentParent = data, parentIds.push(data)">Open folder</a>
                              <a :href="data.file_link" target="_blank" class="menu-link px-3" v-else>Download</a>
                            </div>
                            <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3" data-kt-filemanager-table="rename" @click="renameFileFolder = JSON.parse(JSON.stringify(data))">Rename</a>
                            </div>
                            <!-- <div class="menu-item px-3">
                              <a href="#" class="menu-link px-3" data-kt-filemanager-table-filter="move_row" data-bs-toggle="modal" data-bs-target="#kt_modal_move_to_folder">Move to folder</a>
                            </div> -->
                            <div class="menu-item px-3">
                              <a href="#" class="menu-link text-danger px-3" data-kt-filemanager-table-filter="delete_row" @click="deleteFileFolder(data )">Delete</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ValidationProvider>
              </form>
            </ValidationObserver>
            <tr class="odd" v-if="table.data.length == 0">
              <td valign="top" colspan="5" class="dataTables_empty">
                <div class="d-flex flex-column flex-center">
                  <img src="/assets/media/illustrations/sigma-1/21.png" class="mw-400px">
                  <div class="fs-1 fw-bolder text-dark mb-4">No items found.</div>
                  <div class="fs-6">Start creating new folders or uploading a new file!</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="modal fade" id="kt_modal_upload" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
          <form class="form" action="none" id="kt_modal_upload_form">
            <div class="modal-header">
              <h2 class="fw-bold">Upload files</h2>
              <div class="btn btn-icon btn-sm btn-active-icon-primary" id="closeModalUpload" data-bs-dismiss="modal">
                <span class="svg-icon svg-icon-1">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                  </svg>
                </span>
              </div>
            </div>
            <div class="modal-body pt-10 pb-15 px-lg-17">
              <div class="form-group">
                <div class="dropzone dropzone-queue mb-2">
                  <div class="mb-4">
                    <input type="file" class="d-none" id="attachFile" multiple v-on:change="addToQueue">
                    <label for="attachFile">
                      <a class="btn btn-sm btn-primary me-2">Attach files</a>
                    </label>
                    <button type="button" class="btn btn-sm btn-light-primary me-2" v-if="fileQueues.filter( x => !x.isUploaded).length > 0" @click="uploadAllFileFromQueues()" :disabled="loading">Upload All</button>
                    <button type="button" class="btn btn-sm btn-light-primary" v-if="fileQueues.filter( x => !x.isUploaded).length > 0" @click="removeAllFileFromQueues()" :disabled="loading">Remove All</button>
                  </div>
                  <div class="dropzone-items wm-200px">
                    <div class="dropzone-item p-5" v-for="(queue, index) in fileQueues.filter( x => !x.isUploaded)" :key="index">
                      <div class="dropzone-file">
                        <div class="dropzone-filename text-dark" :title="queue.file.name" style="overflow-wrap: anywhere;">
                          <span>{{ queue.file.name }}</span>
                          <strong> ({{formatBytes(queue.file.size)}})</strong>
                        </div>
                        <div class="dropzone-error mt-0" v-if="!queue.isValid">{{queue.invalidMessage}}</div>
                      </div>
                      <div class="dropzone-progress" v-if="queue.isValid && queue.progress < 100">
                        <div class="progress bg-light-primary">
                          <div class="progress-bar bg-primary" role="progressbar" aria-valuemin="0" aria-valuemax="100" :aria-valuenow="0" :style="'width: '+ queue.progress +'%'"></div>
                        </div>
                      </div>
                      <div class="dropzone-toolbar">
                        <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto" v-if="queue.isValid && queue.progress < 100" @click="uploadFile(queue.id)" :disabled="loading">
                          <i class="bi bi-play-fill fs-3"></i>
                        </button>
                        <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto" v-if="queue.progress == 0 || !queue.isValid" @click="removeFileFromQueues(queue.id)" :disabled="loading">
                          <i class="bi bi-x fs-1"></i>
                        </button>
                        <span class="dropzone-check" v-if="queue.progress == 100">
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="form-text fs-6 text-muted">Max file size is {{ formatBytes(maxFileSize, 0) }} per file.</span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      table: {
        data: [],
        meta: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
        },
        params: {
          limit: 10,
          sortby: 'updated_at.DESC',
          search: '',
          parent_id: '',
        }
      },
      parentIds: [],
      currentParent: null,
      isCreateNewFolder: false,
      loading: false,
      requestNewFolder: {
        name: null,
        is_folder: true,
        parent_id: null,
      },
      fileQueues: [],
      maxFileSize: 5000000,
      renameFileFolder: {
        id: null,
      },
      information: {
        item: 0,
        size: 0
      },
      selected: [],
      googleDriveData: {
        root_file_manager_folder: null,
        root_folder: null,
        root_project_folder: null,
      },
      loading: false
    }
  },
  computed: {
    isAllChecked: function() {
      return this.selected.length == (this.table.data ? this.table.data.length : 0)
    }
  },
  methods: {
    googleDriveDetails() {
      let _ = this
			_.axios.get('/v1/setting/drive/integrate')
				.then(resp => {
          _.googleDriveData = resp.data.data
          _.get()
				})
				.catch(err => {
					_.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
				})
    },
    get() {
      let _ = this
      _.axios.get('/v1/setting/drive/list/'
        + (_.table.params.parent_id ? _.table.params.parent_id : _.googleDriveData.root_file_manager_folder)
      )
      .then( resp => {
        if ( resp.data.data ) {
          _.table.data = resp.data.data.map( x => {
            const isFolder = x.mimeType == 'application/vnd.google-apps.folder'

            return {
              id: x.id,
              is_folder: isFolder,
              name: x.name,
              file: isFolder ? null : x.id,
              file_link: isFolder ? null : x.webViewLink,
              size: isFolder ? null : x.size,
              updated_at: x.modifiedTime
            }
          })
        } else {
          _.table.data = []
        }

        setTimeout(() => {
          KTMenu.createInstances();
        }, 100);
      })
      .catch( err => {
        _.$swalToast.fire({
          icon: 'error',
          title: `Oops! Something went wrong (${err.response.status})`,
          text: err.response.data.errors
        })
      })
    },
    removeParentIds( index ) {
      this.parentIds.splice(index-1, this.parentIds.length)
    },
    removeAllParentIds() {
      this.parentIds = []
    },
    async createNewFolder() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      _.loading = true

      const requestData = {
        folder_name: _.requestNewFolder.name,
        parent_id: _.table.params.parent_id ? _.table.params.parent_id : _.googleDriveData.root_file_manager_folder,
      }

      _.axios.post('/v1/setting/drive/create-folder', requestData)
        .then(() => {
          _.loading = false
          _.get()
          _.resetRequestNewFolder()
          _.isCreateNewFolder = false

          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully add new folder',
            text: `You have successfully add new folder`
          })
        })
        .catch( err => {
          if ( typeof err.response.data.errors === 'object' && err.response.data.errors !== null ) {
            Object.keys(err.response.data.errors).forEach(elm => {
              _.$refs[elm].applyResult({
                errors: [err.response.data.errors[elm][0]],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
          _.loading = false
        })
    },
    handleSearch() {
      this.get()
    },
    resetRequestNewFolder() {
      this.requestNewFolder = {
        name: null,
        is_folder: true,
        parent_id: null,
      }
    },
    resetRequestNewFile() {
      this.requestNewFile = {
        name: null,
        is_folder: false,
        parent_id: null,
        file: null,
      }
    },
    deleteFileFolder(data) {
      let _ = this
      _.$swal.fire({
        title: 'You are about to delete this ' + (data.is_folder ? 'folder' : 'file'),
        text: 'Do you really want to delete this ' + (data.is_folder ? 'folder' : 'file') + '? This process cannot be undone.',
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: 'No, cancel',
        confirmButtonText: 'Yes, sure',
        focusCancel: true
        }).then((result) => {
          if (result.value) {
            _.axios.delete(`/v1/setting/drive/delete/${data.id}`)
              .then(() => {
                _.$swalToast.fire({
                  icon: 'success',
                  title: 'Successfully delete ' + (data.is_folder ? 'folder' : 'file'),
                  text: 'You have successfully delete this ' + (data.is_folder ? 'folder' : 'file')
                })

                _.get()
              })
              .catch(err=>{
                _.$swalToast.fire({
                  icon: 'error',
                  title: `Oops! Something went wrong (${err.response.status})`,
                  text: err.response.data.errors
                })
              })
          }
      })
    },
    async addToQueue(event){
      let _ = this
      let files = event.target.files
      let fileId = _.fileQueues.length

      Array.from(files).forEach(file => {
        const isValid = file.size <= _.maxFileSize
        let invalidMessage = null
        
        if ( !isValid ) {
          invalidMessage = `File is too big (${_.formatBytes(file.size)}). Max filesize: ${_.formatBytes(_.maxFileSize, 0)}.`
        }
        
        _.fileQueues.push({
          id: fileId,
          isValid: isValid,
          invalidMessage: invalidMessage,
          progress: 0,
          file: file,
          isUploaded: false
        })

        fileId += 1
      });

      $("#attachFile").val(null);
    },
    removeFileFromQueues( fileId ) {
      let _ = this
      const searchFile = _.fileQueues.map( x => x.id ).indexOf( fileId )
      if ( searchFile != -1 ) {
        _.fileQueues.splice(searchFile, 1)
      }
    },
    removeAllFileFromQueues() {
      let _ = this
      _.fileQueues = []
    },
    async uploadFile( fileId, update = true ) {
      let _ = this

      if ( update == true ) {
        const checkDriveIntegration = await _.axios.get('/v1/setting/drive/integrate')
          .then(() => {
            return true
          })
          .catch(err => {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
            return false
          })
        
        if (!checkDriveIntegration) {
          return
        }
      }
      
      const searchFile = _.fileQueues.map( x => x.id ).indexOf( fileId )
      const index = searchFile

      let formData = new FormData()
      formData.append('file', _.fileQueues[index].file)
      formData.append('parent', (_.table.params.parent_id ? _.table.params.parent_id : _.googleDriveData.root_file_manager_folder))

      _.loading = true
      await _.axios.post('/v1/setting/drive/upload-file', formData, 
        { 
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: function( progressEvent ) {
            const percent = ( progressEvent.loaded / progressEvent.total ) * 100
            _.fileQueues[index].progress = percent
            _.$forceUpdate()
          }.bind(_)
        })
        .then(() => {
          _.fileQueues[index].isUploaded = true
          _.$forceUpdate()

          if ( update == true ) {
            _.get()
          }

          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully add new file',
            text: `You have successfully add new file`
          })

          _.loading = false
          
          if (_.fileQueues.filter( x => x.isUploaded).length == _.fileQueues.length) {
            $('#closeModalUpload').click()
          }
        })
        .catch(err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
          _.loading = false
        })
    },
    async uploadAllFileFromQueues() {
      let _ = this

      const checkDriveIntegration = await _.axios.get('/v1/setting/drive/integrate')
        .then(() => {
          return true
        })
        .catch(err => {
					_.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
          return false
				})

      if (!checkDriveIntegration) {
        return
      }

      let queues = _.fileQueues.filter( x => !x.isUploaded )
      for (let index = 0; index < queues.length; index++) {
        await _.uploadFile(queues[index].id, false)
      }
      await _.get()
    },
    async updateFileFolder() {
      let _ = this
      
      const isValid = await _.$refs.observer2.validate()
      if ( !isValid ) return

      _.loading = true

      _.renameFileFolder.file_id = _.renameFileFolder.id

      _.axios.patch('/v1/setting/drive/rename', _.renameFileFolder)
        .then(() => {
          _.get()
          _.$swalToast.fire({
            icon: 'success',
            title: `Successfully rename ${_.renameFileFolder.is_folder ? 'folder' : 'file'}`,
            text: `You have successfully rename ${_.renameFileFolder.is_folder ? 'folder' : 'file'}`
          })
          _.renameFileFolder.id = null
          _.loading = false
        })
        .catch( err => {
        if ( typeof err.response.data.errors === 'object' && err.response.data.errors !== null ) {
            Object.keys(err.response.data.errors).forEach(elm => {
              _.$refs[elm].applyResult({
                errors: [err.response.data.errors[elm][0]],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
          _.loading = false
      })
    },
    selectAll(event){
      let _ = this
      if (event.target.checked) {
        _.table.data.forEach(elm => {
          _.selected.push( elm.id )
        });
      } else {
        _.selected = []
      }
    },
    select(value, event){
      let _ = this
      if (event.target.checked) {
        _.selected.push(value)                
      } else {
        const index = _.selected.indexOf(value);
        if (index > -1) {
          _.selected.splice(index, 1);
        }
      }
    },
    bulkDeleteFile() {
      let _ = this
      _.$swal.fire({
        title: 'You are about to delete a lot of files',
        text: 'Do you really want to delete those files? This process cannot be undone.',
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: 'No, cancel',
        confirmButtonText: 'Yes, sure',
        focusCancel: true
        }).then((result) => {
          if (result.value) {
            _.axios.post(`/v1/template/bulkDelete`, { selectedIds: _.selected })
              .then(() => {
                _.$swalToast.fire({
                  icon: 'success',
                  title: 'Successfully delete files',
                  text: 'You have successfully delete those files'
                })
                _.selected = []
                _.get()
                _.getInformation()
              })
              .catch(err=>{
                _.$swalToast.fire({
                  icon: 'error',
                  title: `Oops! Something went wrong (${err.response.status})`,
                  text: err.response.data.errors
                })
              })
          }
      })
    },
  },
  mounted() {
    this.googleDriveDetails()
  },
  watch: {
    'table.params.parent_id': function() {
      this.get()
    }
  }
}
</script>