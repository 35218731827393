<template>
  <div>
    <div class="d-flex flex-wrap flex-stack pb-7">
      <div class="d-flex flex-wrap align-items-center my-1">
        <h3 class="fw-bold me-5 my-1">Members 
          <span class="ms-2 badge badge-secondary">{{ members.length | numFormat }}</span>
        </h3>
      </div>
    </div>
    <div class="tab-content">
      <div id="kt_project_users_card_pane" class="tab-pane fade show active">
        <div class="row g-6 g-xl-9" v-if="members.filter( x => x.user ).length > 0">
          <div class="col-md-6 col-xxl-4" v-for="member in members.filter(x => x.user)" :key="member.id">
            <div class="card">
              <div class="card-body d-flex flex-center flex-column p-9">
                <div class="symbol symbol-65px symbol-circle mb-5">
                  <span class="symbol-label fw-bold" :class="generateInitialColor()" v-if="!member.user.avatar">{{ generateInitial(member.user.full_name )}}</span>
                  <img v-else :src="member.user.avatar" alt="image" referrerpolicy="no-referrer" style="object-fit: cover;"/>
                </div>
                <router-link :to="'/user-management/user/detail/' + member.user_id" class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">{{ member.user.full_name }}</router-link>
                <div class="fw-semibold text-gray-400">{{ member.member_positions.map( x => x.position.title ).join(', ')}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column flex-center" v-else>
          <img src="/assets/media/illustrations/sigma-1/21.png" class="mw-400px">
          <div class="fw-bolder text-dark mb-4">No Members Found.</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'members'
  ]
}
</script>