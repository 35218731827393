<template>
  <div>
    <div class="card card-flush">
      <div class="card-header align-items-center py-5 gap-2 gap-md-5">
        <div class="card-title">
          <div class="d-flex align-items-center position-relative my-1">
            <span class="svg-icon svg-icon-1 position-absolute ms-4">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
              </svg>
            </span>
            <input type="text" class="form-control form-control-solid w-250px ps-14" placeholder="Search Client" 
              v-debounce:300.lock="handleSearch" v-model="table.params.search"/>
          </div>
        </div>
        <div class="card-toolbar">
          <div class="d-flex justify-content-end" v-if="selected.length == 0">
            <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_client" @click="reset()">Add New Client</button>
          </div>
          <div class="d-flex justify-content-end align-items-center my-1" v-else>
            <div class="fw-bold me-5">
            <span class="me-2">{{ selected.length | numFormat }}</span>Selected</div>
            <button type="button" class="btn btn-danger" @click="bulkDeleteClient()">Delete Selected</button>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <Table :table="table" :loading="loading">
          <template v-slot:actionButton="{ data }">
            <button class="btn btn-sm btn-light btn-active-light-primary text-nowrap" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
              <span class="svg-icon svg-icon-5 m-0">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                </svg>
              </span>
            </button>
            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
              <div class="menu-item px-3">
                <a href="#" class="menu-link px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_add_client" @click="setDetail(data)">Edit</a>
              </div>
              <div class="menu-item px-3">
                <a href="#" class="menu-link px-3" @click="deleteClient(data)">Delete</a>
              </div>
            </div>
          </template>
        </Table>
      </div>
    </div>

    <div class="modal fade" id="kt_modal_add_client" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
          <div class="modal-header" id="kt_modal_add_client_header">
            <h2 class="fw-bold">{{ detail ? 'Edit' : 'Add New'}} Client</h2>
            <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal">
              <span class="svg-icon svg-icon-1">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                </svg>
              </span>
            </div>
          </div>
          <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
            <ValidationObserver ref="observer">
              <form class="form" @submit.prevent="createClient()">
                <div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_client_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_client_header" data-kt-scroll-wrappers="#kt_modal_add_client_scroll" data-kt-scroll-offset="300px">
                  <div class="fv-row mb-7">
                    <ValidationProvider name="Logo" v-slot="{ errors }" :rules="{ }" ref="photo">
                      <label class="d-block fw-semibold fs-6 mb-5">Logo</label>
                      <div class="image-input image-input-outline image-input-placeholder" data-kt-image-input="true">
                        <div class="image-input-wrapper w-125px h-125px" :style="`background-image: url(${request.photo ? $apiBaseUrl + '/storage/' + request.photo : '/assets/media/misc/image.png'}); background-position:center;`"></div>
                        <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change logo">
                          <i class="bi bi-pencil-fill fs-7"></i>
                          <input type="file" class="hidden" id="upload" v-on:change="handleUpload" :accept="'.' + allowedExtension.join(', .').toLowerCase()">
                          <input type="hidden" name="avatar_remove" />
                        </label>
                        <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove logo" 
                          v-if="request.photo" @click="request.photo = null">
                          <i class="bi bi-x fs-2"></i>
                        </span>
                      </div>
                      <div class="form-text">Maximum file size: {{ maxFileSize }} Mb. ({{ '.' + allowedExtension.join(' .').toUpperCase() }}).</div>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row mb-7">
                    <ValidationProvider name="Company name" v-slot="{ errors }" :rules="{ required: true }" ref="name">
                      <label class="required fw-semibold fs-6 mb-2">Company Name</label>
                      <input type="text" name="client_name" class="form-control form-control-solid mb-3 mb-lg-0" placeholder="Company name" v-model="request.name"/>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row mb-7">
                    <ValidationProvider name="PIC name" v-slot="{ errors }" :rules="{ required: true }" ref="pic_name">
                      <label class="required fw-semibold fs-6 mb-2">PIC Name</label>
                      <input type="text" name="client_name" class="form-control form-control-solid mb-3 mb-lg-0" placeholder="PIC name" v-model="request.pic_name"/>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row mb-7">
                    <ValidationProvider name="Phone number" v-slot="{ errors }" :rules="{ required: true, min: 8, max: 15 }" ref="phone_number">
                      <label class="required fw-semibold fs-6 mb-2">Phone Number</label>
                      <input type="text" name="client_name" class="form-control form-control-solid mb-3 mb-lg-0" placeholder="Phone number" v-model="request.phone_number" @keypress="numbersOnly($event)"/>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <ValidationProvider name="Address" v-slot="{ errors }" :rules="{ required: true }" ref="address">
                    <div class="fv-row mb-7">
                      <label class="required fw-semibold fs-6 mb-2">Address</label>
                      <textarea class="form-control form-control-solid mb-3 mb-lg-0" id="" cols="30" rows="5" placeholder="Address" v-model="request.address"></textarea>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider name="Description" v-slot="{ errors }" :rules="{ required: true }" ref="description">
                    <div class="fv-row mb-7">
                      <label class="required fw-semibold fs-6 mb-2">Description</label>
                      <textarea class="form-control form-control-solid mb-3 mb-lg-0" id="" cols="30" rows="5" placeholder="Description" v-model="request.description"></textarea>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="text-center pt-15">
                  <button type="reset" class="btn btn-light me-3" data-bs-dismiss="modal" @click="reset()">Discard</button>
                  <button type="submit" class="btn btn-primary">
                    <span class="indicator-label" v-if="!loading">Submit</span>
                    <span class="indicator-progress" v-else>
                      Please wait...
                      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from '@/components/Table.vue'

export default {
	components: {
    Table
	},
  data() {
    return {
      table: {
        options: {
          columns: [
            { name: 'checkbox', sortable: false, reference: null },
            { name: 'ID', sortable: true, reference: 'updated_at' },
            { name: 'Company', sortable: true, reference: 'name' },
            { name: 'PIC', sortable: true, reference: 'pic_name' },
            { name: 'Address', sortable: false, reference: null },
            { name: 'Description', sortable: false, reference: null },
          ],
          rows: [
            { title: null, type: 'checkbox' },
            { title: 'id', type: 'text' },
            {
              title: 'name',
              type: 'text',
              image: 'photo_link',
              imageRounded: false,
              routeReference: 'id',
            },
            {
              title: 'pic_name',
              type: 'text',
              subtitle: 'phone_number'
            },
            {
              title: 'address',
              type: 'text',
            },
            {
              title: 'description',
              type: 'text',
            },
          ],
          actionButton: true
        },
        data: [],
        meta: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
        },
        params: {
          sortby: 'updated_at.DESC',
          search: '',
          limit: 10
        }
      },
      selected: [],
      maxFileSize: 2,
      allowedExtension: ['jpg', 'jpeg', 'png'],
      request: {
        name: null,
        phone_number: null,
        address: null,
        description: null,
        name: null,
        photo: null,
        pic_name: null,
      },
      loading: false,
      detail: null,
      loading: false,
    }
  },
  methods: {
    get( page = 1 ) {
      let _ = this
      _.loading = true
      _.table.data = []
      _.axios.get('/v1/client'
        + '?page=' + page
        + '&limit=' + _.table.params.limit
        + '&sortBy=' + _.table.params.sortby
        + '&search=' + _.table.params.search
      )
      .then( resp => {
        _.table.meta = resp.data.meta
        _.table.data = resp.data.data

        setTimeout(() => {
          KTMenu.createInstances();
          KTScroll.createInstances();
        }, 500);
        _.loading = false
      })
      .catch( err => {
        _.loading = false
        _.$swalToast.fire({
          icon: 'error',
          title: `Oops! Something went wrong (${err.response.status})`,
          text: err.response.data.errors
        })
      })
    },
    handleSearch() {
      this.get()
    },
    async handleUpload(event){
      let _ = this
      let file = event.target.files[0]
      _.request.photo = await _.upload( file, _.allowedExtension, _.maxFileSize, 'photo' )
    },
    async createClient() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      if ( _.detail ) {
        this.updateClient() 
        return
      }

      _.loading = true
      _.axios.post('/v1/client', _.request)
        .then(() => {
          _.loading = false
          _.hideModal()
          _.get()
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully add new client',
            text: `You have successfully add new client`
          })
        })
        .catch( err => {
          if ( typeof err.response.data.errors === 'object' && err.response.data.errors !== null ) {
            Object.keys(err.response.data.errors).forEach(elm => {
              _.$refs[elm].applyResult({
                errors: [err.response.data.errors[elm][0]],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
          _.loading = false
        })
    },
    deleteClient(data) {
      let _ = this
      _.$swal.fire({
        title: 'You are about to delete this client',
        text: 'Do you really want to delete this client? This process cannot be undone.',
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: 'No, cancel',
        confirmButtonText: 'Yes, sure',
        focusCancel: true
        }).then((result) => {
          if (result.value) {
            _.axios.delete(`/v1/client/${data.id}`)
              .then(() => {
                _.$swalToast.fire({
                  icon: 'success',
                  title: 'Successfully delete client',
                  text: 'You have successfully delete this client'
                })

                _.get()
              })
              .catch(err=>{
                _.$swalToast.fire({
                  icon: 'error',
                  title: `Oops! Something went wrong (${err.response.status})`,
                  text: err.response.data.errors
                })
              })
          }
      })
    },
    bulkDeleteClient() {
      let _ = this
      _.$swal.fire({
        title: 'You are about to delete a lot of clients',
        text: 'Do you really want to delete those clients? This process cannot be undone.',
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: 'No, cancel',
        confirmButtonText: 'Yes, sure',
        focusCancel: true
        }).then((result) => {
          if (result.value) {
            _.axios.post(`/v1/client/bulkDelete`, { selectedIds: _.selected })
              .then(() => {
                _.$swalToast.fire({
                  icon: 'success',
                  title: 'Successfully delete client',
                  text: 'You have successfully delete those clients'
                })
                _.selected = []
                _.$root.$emit('resetSelected')
                _.get()
              })
              .catch(err=>{
                _.$swalToast.fire({
                  icon: 'error',
                  title: `Oops! Something went wrong (${err.response.status})`,
                  text: err.response.data.errors
                })
              })
          }
      })
    },
    async reset() {
      $('#kt_modal_add_client_scroll').animate({ scrollTop: 0 }, 'slow');
      
      this.detail = null
      this.request = {
        name: null,
        phone_number: null,
        address: null,
        description: null,
        name: null,
        photo: null,
        pic_name: null,
      }
      await this.$refs.observer.reset()
    },
    async updateClient() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      _.loading = true
      _.axios.put(`/v1/client/${_.detail.id}`, _.request)
        .then(() => {
          _.loading = false
          _.hideModal()
          _.get()
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully edit client',
            text: `You have successfully edit client`
          })
        })
        .catch( err => {
          if ( typeof err.response.data.errors === 'object' && err.response.data.errors !== null ) {
            Object.keys(err.response.data.errors).forEach(elm => {
              _.$refs[elm].applyResult({
                errors: [err.response.data.errors[elm][0]],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
          _.loading = false
        })
    },
    setDetail( data ) {
      let _ = this
      $('#kt_modal_add_client_scroll').animate({ scrollTop: 0 }, 'slow');
      _.detail = JSON.parse(JSON.stringify(data))
      _.request = {
        name: _.detail.name,
        phone_number: _.detail.phone_number,
        address: _.detail.address,
        description: _.detail.description,
        pic_name: _.detail.pic_name,
        photo: _.detail.photo,
      }
    }
  },
  mounted() {
    let _ = this
    _.get()

    _.$root.$on("changePage", (data) => {
      _.get(data)
    });
    _.$root.$on("changeLimit", (data) => {
      _.table.params.limit = data
      _.get()
    });
    _.$root.$on("changeSortBy", (data) => {
      if ( data == '.' ) {
        _.table.params.sortby = 'id.desc'
      } else {
        _.table.params.sortby = data
      }
      _.get()
    });
    _.$root.$on("changeSelected", (data) => {
      _.selected = data
    });
    $(document).ready(function() {
      $('textarea').keypress(function(event) {
        if (event.keyCode == 13) {
          event.preventDefault();
        }
      });
    });
  },
  beforeDestroy(){
    this.$root.$off("changePage");
    this.$root.$off("changeLimit");
    this.$root.$off("changeSortBy");
    this.$root.$off("changeSelected");
  }
}
</script>