import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/helpers/helper'

import VuePageTransition from 'vue-page-transition'
import store from './store'
Vue.use(VuePageTransition)

import axios from './plugins/axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import validate from './plugins/vee-validate.js'
import { ValidationObserver } from 'vee-validate'
Vue.component('ValidationProvider', validate)
Vue.component('ValidationObserver', ValidationObserver)

import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)

import {InlineSvgPlugin} from 'vue-inline-svg';
Vue.use(InlineSvgPlugin);

const moment = require('moment')
moment.updateLocale('en', {
  week: {
    dow : 1, // Monday is the first day of the week.
  }
});

// require('moment/locale/id')
Vue.use(require('vue-moment'), {moment})

import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter,[
	{
		name: 'numFormat',
		symbol: '',
		thousandsSeparator: '.',
		fractionCount: 0,
		fractionSeparator: ',',
		symbolPosition: 'front',
		symbolSpacing: true
	},
	{
		symbol: 'Rp',
		thousandsSeparator: '.',
		fractionCount: 0,
		fractionSeparator: ',',
		symbolPosition: 'front',
		symbolSpacing: true
	}
])

import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce)

import Notifications from 'vue-notification'
Vue.use(Notifications)

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
Vue.prototype.$swalToast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
Vue.component('multiselect', Multiselect)

import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
Vue.use(datePicker);

import VueSkeletonLoader from 'skeleton-loader-vue';
Vue.component('vue-skeleton-loader', VueSkeletonLoader);

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

// import EmojiPicker from 'vue-emoji-picker'
// Vue.use(EmojiPicker)

Vue.config.productionTip = false
Vue.prototype.$apiBaseUrl = process.env.VUE_APP_API_BASE_URL

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
