<template>
  <div>
    <div class="d-flex flex-wrap flex-stack pb-7">
      <div class="d-flex flex-wrap align-items-center my-1">
        <h3 class="fw-bold me-5 my-1">Timelines</h3>
      </div>
      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_timeline" @click="reset()">
          Add New Timeline
        </button>
      </div>
    </div>
    <div class="d-flex flex-column flex-center" v-if="timelines.length == 0">
      <img src="/assets/media/illustrations/sigma-1/21.png" class="mw-400px">
      <div class="fs-1 fw-bolder text-dark mb-4">No timelines found.</div>
      <div class="fs-6">Start creating new timelines!</div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table align-middle fs-6 gy-5 no-footer">
            <thead>
              <tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                <th rowspan="2" class="text-center align-middle col-current-phase fixed-header" scope="row" style="min-width: 100px; width: 100px;">Current Phase</th>
                <th rowspan="2" class="text-center align-middle col-phase fixed-header" scope="row" style="min-width: 180px; width: 180px;">Phase</th>
                <th rowspan="2" class="text-center align-middle text-nowrap col-start-date fixed-header" scope="row" style="min-width: 100px; width: 100px;">Start Date</th>
                <th rowspan="2" class="text-center align-middle text-nowrap col-end-date fixed-header" scope="row" style="min-width: 100px; width: 100px;">End Date</th>
                <th colspan="5" class="text-center align-middle text-nowrap" v-for="(month, index) in months" :key="index">{{ month | moment(`MMMM 'YY`) }}</th>
              </tr>
              <tr>
                <th style="padding: 1rem !important;" class="text-center fs-7" 
                  v-for="(week, i) in getWeek" :key="i"
                  data-bs-toggle="tooltip" :title="`${translateToLL(week.dates[0])} - ${translateToLL(week.dates[week.dates.length-1])}`">
                  W{{ (i+1) % 5 == 0 ? 5 : (i+1) % 5 }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(timeline, index) in timelines" :key="index">
                <td class="col-current-phase text-center" scope="row">
                  <input type="radio" class="form-check-input" v-model="currentProjectTimelineId" :value="timeline.id" @change="changePhase(timeline.id)">
                </td>
                <td class="col-phase" scope="row">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#kt_modal_add_timeline" @click="setDetail(timeline)">
                    <span data-bs-toggle="tooltip" title="Click to update or delete">
                      {{ timeline.phase.name }} <span v-if="timeline.sub_phase">: {{ timeline.sub_phase }}</span>
                    </span>
                  </a>
                </td>
                <td class="col-start-date text-center" scope="row">{{ timeline.start_date | moment('DD/MM/YY') }}</td>
                <td class="col-end-date text-center" scope="row">{{ timeline.end_date | moment('DD/MM/YY') }}</td>
                <td class="" :class="getMarked(timeline).includes(i) ? 'progress-bar-striped ' + timeline.background : ''" v-for="i in (5*totalMonths)" :key="i"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="modal fade" id="kt_modal_add_timeline" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
          <div class="modal-header" id="kt_modal_add_timeline_header">
            <h2 class="fw-bold">{{ detail ? 'Edit' : 'Add New'}} Timeline</h2>
            <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal">
              <span class="svg-icon svg-icon-1">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                </svg>
              </span>
            </div>
          </div>
          <div class="modal-body mx-5 mx-xl-15 my-7">
            <ValidationObserver ref="observer">
              <form class="form" @submit.prevent="createTimeline()">
                <div class="d-flex flex-column me-n7 pe-7" id="kt_modal_add_timeline_scroll">
                  <div class="fv-row mb-7">
                    <ValidationProvider name="Phase" v-slot="{ errors }" :rules="{ required: true }" ref="phase_id">
                      <label class="fw-semibold required fs-6 mb-2">Phase</label>
                      <multiselect 
                        track-by="id"
                        label="name"
                        placeholder="Select Phase"
                        v-model="request.phase_id" 
                        :options="phases"
                        :allow-empty="false"
                        :close-on-select="true"
                        :multiple="false"
                        :searchable="true"
                        :hide-selected="false"
                        :showLabels="false"
                      ></multiselect>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row mb-7">
                    <ValidationProvider name="Sub phase" v-slot="{ errors }" ref="sub_phase">
                      <label class="fw-semibold fs-6 mb-2">Sub Phase</label>
                      <input type="text" name="timeline_name" class="form-control form-control-solid mb-3 mb-lg-0" placeholder="Sub phase" v-model="request.sub_phase"/>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="row">
                    <div class="fv-row mb-7 col">
                      <ValidationProvider name="Start date" :rules="{ required: true }" v-slot="{ errors }" ref="">
                        <label class="fw-semibold fs-6 mb-2 required">Start Date</label>
                        <date-picker class="form-control form-control-solid" v-model="request.start_date" :config="datepickerOptions" @change="request.end_date = null" placeholder="Pick a date"></date-picker>
                        <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>
                    <div class="fv-row mb-7 col">
                      <ValidationProvider name="End date" :rules="{ required: true }" v-slot="{ errors }" ref="">
                        <label class="fw-semibold fs-6 mb-2 required">End Date</label>
                        <date-picker class="form-control form-control-solid" :disabled="!request.start_date" v-model="request.end_date" :config="endDatepickerOptions" placeholder="Pick a date"></date-picker>
                        <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="text-center pt-15">
                  <button class="btn btn-light me-3" data-bs-dismiss="modal" @click="reset()" type="button">Discard</button>
                  <button class="btn btn-danger me-3" @click="deleteTimeline(detail)" type="button" v-if="detail">Delete</button>
                  <button type="submit" class="btn btn-primary">
                    <span class="indicator-label" v-if="!loading">Submit</span>
                    <span class="indicator-progress" v-else>
                      Please wait...
                      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>

    <!-- <json-viewer :value="getStartDate"></json-viewer> -->
  </div>
</template>
<script>
export default {
  props: [
    'project'
  ],
  data() {
    return {
      timelines: [],
      request: {
        project_id: this.$route.params.id,
        phase_id: null,
        sub_phase: null,
        start_date: null,
        end_date: null,
      },
      loading: false,
      detail: null,
      phases: [],
      datepickerOptions: {
        format: 'YYYY-MM-DD',
        useCurrent: false,
      },
      currentProjectTimelineId: null,
      oldProjectTimelineId: null,
      currentPhaseId: null,
      requestUpdateProject: {
        name: null,
        description: null,
        client_id: null,
        technologies: [],
        tags: [],
        members: [],
        logo: null,
      }
    }
  },  
  computed: {
    months: function() {
      if (this.timelines.length == 0 ) return [];

      var fromDate = this.$moment(this.getStartDate);
      var toDate = this.$moment(this.getEndDate);
      var monthData = [];

      while (toDate > fromDate || fromDate.format('M') === toDate.format('M')){
        monthData.push(fromDate.format('YYYY-MM-DD'));
        fromDate.add(1,'month');
      }

      return monthData;
    },
    totalMonths: function() {
      return this.months.length
    },
    getMonthDetail:function() {
      let _ = this
      let he = []
      _.months.forEach(month => {
        he.push({
          month: month,
          w1: {
            startDate: _.$moment(month).startOf('month').startOf('week').format('YYYY-MM-DD'),
            endDate: _.$moment(month).startOf('month').endOf('week').format('YYYY-MM-DD'),
            dates: _.getRange(_.$moment(month).startOf('month').startOf('week').format('YYYY-MM-DD'), _.$moment(month).startOf('month').endOf('week').format('YYYY-MM-DD'), 'days')
              .filter( x => _.$moment(x).format('YYYY-MM') == _.$moment(month).format('YYYY-MM'))
          },
          w2: {
            startDate: _.$moment(month).startOf('month').add(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
            endDate: _.$moment(month).startOf('month').add(1, 'weeks').endOf('week').format('YYYY-MM-DD'),
            dates: _.getRange(_.$moment(month).startOf('month').add(1, 'weeks').startOf('week').format('YYYY-MM-DD'), _.$moment(month).startOf('month').add(1, 'weeks').endOf('week').format('YYYY-MM-DD'), 'days')
              .filter( x => _.$moment(x).format('YYYY-MM') == _.$moment(month).format('YYYY-MM'))
          },
          w3: {
            startDate: _.$moment(month).startOf('month').add(2, 'weeks').startOf('week').format('YYYY-MM-DD'),
            endDate: _.$moment(month).startOf('month').add(2, 'weeks').endOf('week').format('YYYY-MM-DD'),
            dates: _.getRange(_.$moment(month).startOf('month').add(2, 'weeks').startOf('week').format('YYYY-MM-DD'), _.$moment(month).startOf('month').add(2, 'weeks').endOf('week').format('YYYY-MM-DD'), 'days')
              .filter( x => _.$moment(x).format('YYYY-MM') == _.$moment(month).format('YYYY-MM'))
          },
          w4: {
            startDate: _.$moment(month).startOf('month').add(3, 'weeks').startOf('week').format('YYYY-MM-DD'),
            endDate: _.$moment(month).startOf('month').add(3, 'weeks').endOf('week').format('YYYY-MM-DD'),
            dates: _.getRange(_.$moment(month).startOf('month').add(3, 'weeks').startOf('week').format('YYYY-MM-DD'), _.$moment(month).startOf('month').add(3, 'weeks').endOf('week').format('YYYY-MM-DD'), 'days')
              .filter( x => _.$moment(x).format('YYYY-MM') == _.$moment(month).format('YYYY-MM'))
          },
          w5: {
            startDate: _.$moment(month).startOf('month').add(4, 'weeks').startOf('week').format('YYYY-MM-DD'),
            endDate: _.$moment(month).startOf('month').add(4, 'weeks').endOf('week').format('YYYY-MM-DD'),
            dates: _.getRange(_.$moment(month).startOf('month').add(4, 'weeks').startOf('week').format('YYYY-MM-DD'), _.$moment(month).startOf('month').add(4, 'weeks').endOf('week').format('YYYY-MM-DD'), 'days')
              .filter( x => _.$moment(x).format('YYYY-MM') == _.$moment(month).format('YYYY-MM'))
          },
        })
      });
      return he
    },
    getWeek: function() {
      let hehe = []
      this.getMonthDetail.forEach(elm => {
        hehe.push(elm.w1)
        hehe.push(elm.w2)
        hehe.push(elm.w3)
        hehe.push(elm.w4)
        hehe.push(elm.w5)
      });
      return hehe
    },
    endDatepickerOptions: function() {
      if ( this.request.start_date ) {
        return {
          minDate: this.$moment(this.request.start_date).format('YYYY-MM-DD'),
          format: 'YYYY-MM-DD',
        }
      } else {
        return {
          format: 'YYYY-MM-DD',
        }
      }
    },
    requestBody: function() {
      let _ = this
      return {
        ..._.project,
        technologies: _.project.project_technologies.map( x => x.technology_id ),
        tags: _.project.project_tags.map( x => x.tag_id ),
        members: _.project.project_members.map( function(x) {
          return {
            user_id: x.user_id,
            position_id: x.member_positions.length > 0 ? x.member_positions.map( z => z.position_id ) : null
          }
        }).filter( x => x.user_id != null ),
        project_timeline_id: _.currentProjectTimelineId,
        phase_id: _.currentPhaseId,
      }
    },
    getStartDate: function() {
      let _ = this

      if (_.timelines.length == 0) return null;

      let timelines = JSON.parse(JSON.stringify(_.timelines))

      const sortedTimelines = timelines.sort(function(a,b){
        return new Date(b.start_date) - new Date(a.start_date);
      });

      return sortedTimelines[sortedTimelines.length-1].start_date
    },
    getEndDate: function() {
      let _ = this

      if (_.timelines.length == 0) return null;

      let timelines = JSON.parse(JSON.stringify(_.timelines))

      const sortedTimelines = timelines.sort(function(a,b){
        return new Date(b.end_date) - new Date(a.end_date);
      });

      return sortedTimelines[0].end_date
    },
  },
  methods: {
    getMarked(timeline) {
      let _ = this

      let timelineDates = _.getRange(timeline.start_date, timeline.end_date, 'days');

      let marked = []
      let w = 0
      for (let i = 0; i < _.getMonthDetail.length; i++) {
        w += 1;
        if (_.getMonthDetail[i]['w1']['dates'].some(r=> timelineDates.includes(r))) {
          marked.push(w)
        }
        w += 1;
        if (_.getMonthDetail[i]['w2']['dates'].some(r=> timelineDates.includes(r))) {
          marked.push(w)
        }
        w += 1;
        if (_.getMonthDetail[i]['w3']['dates'].some(r=> timelineDates.includes(r))) {
          marked.push(w)
        }
        w += 1;
        if (_.getMonthDetail[i]['w4']['dates'].some(r=> timelineDates.includes(r))) {
          marked.push(w)
        }
        w += 1;
        if (_.getMonthDetail[i]['w5']['dates'].some(r=> timelineDates.includes(r))) {
          marked.push(w)
        }
      }

      return marked
    },
    getRange(startDate, endDate, type) {
      let fromDate = this.$moment(startDate)
      let toDate = this.$moment(endDate)
      let diff = toDate.diff(fromDate, type)
      let range = []
      for (let i = 0; i <= diff; i++) {
        range.push(this.$moment(startDate).add(i, type).format('YYYY-MM-DD'))
      }
      return range
    },
    translateToLL( date ) {
      return this.$moment(date).format('LL')
    },
    async reset() {
      this.detail = null
      this.request = {
        project_id: this.$route.params.id,
        phase_id: null,
        sub_phase: null,
        start_date: null,
        end_date: null,
      }

      await this.$refs.observer.reset()
    },
    async createTimeline() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      if ( _.detail ) {
        this.updateTimeline() 
        return
      }

      _.loading = true

      const data = {
        ..._.request,
        phase_id: _.request.phase_id.id
      }

      _.axios.post('/v1/project/timeline', data)
        .then(() => {
          _.loading = false
          _.reset()
          _.hideModal()
          _.get()
          _.$root.$emit('updateProjectDetail')
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully add new timeline',
            text: `You have successfully add new timeline`
          })
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              _.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
          _.loading = false
        })
    },
    updateTimeline() {
      let _ = this

      _.loading = true

      const data = {
        ..._.request,
        phase_id: _.request.phase_id.id
      }

      _.axios.put('/v1/project/timeline/' + _.detail.id, data)
        .then(() => {
          _.loading = false
          _.reset()
          _.hideModal()
          _.$root.$emit('updateProjectDetail')
          _.get()
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully update timeline',
            text: `You have successfully update timeline`
          })
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              _.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
          _.loading = false
        })
    },
    getPhase() {
      let _ = this

      _.axios.get('/v1/phase')
        .then( resp => {
          _.phases = resp.data.data
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    get() {
      let _ = this
      _.axios.get('/v1/project/timeline'
          + '?project_id=' + _.$route.params.id)
      .then( resp => {
        const data = resp.data.data

        _.timelines = []

        data.forEach(elm => {
          _.timelines.push({
            ...elm,
            background: this.generateRandomBg()
          })
        });

        setTimeout(() => {
          KTMenu.createInstances();
        }, 500);

        $(document).ready(function() {
          $('[data-bs-toggle="tooltip"]').tooltip();
        });
      })
      .catch( err => {
        _.$swalToast.fire({
          icon: 'error',
          title: `Oops! Something went wrong (${err.response.status})`,
          text: err.response.data.errors
        })
      })
    },
    setDetail( data ) {
      let _ = this
      _.detail = JSON.parse(JSON.stringify(data))
      _.request = {
        project_id: _.$route.params.id,
        phase_id: _.detail.phase,
        sub_phase: _.detail.sub_phase,
        start_date: _.detail.start_date,
        end_date: _.detail.end_date,
      }
    },
    deleteTimeline(data) {
      let _ = this
      _.hideModal()

      _.$swal.fire({
        title: 'You are about to delete this timeline',
        text: 'Do you really want to delete this timeline? This process cannot be undone.',
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: 'No, cancel',
        confirmButtonText: 'Yes, sure',
        focusCancel: true
        }).then((result) => {
          if (result.value) {
            _.axios.delete(`/v1/project/timeline/${data.id}`)
              .then(() => {
                _.$swalToast.fire({
                  icon: 'success',
                  title: 'Successfully delete timeline',
                  text: 'You have successfully delete this timeline'
                })

                _.$root.$emit('updateProjectDetail')
                _.get()
              })
              .catch(err=>{
                _.$swalToast.fire({
                  icon: 'error',
                  title: `Oops! Something went wrong (${err.response.status})`,
                  text: err.response.data.errors
                })
              })
          }
      })
    },
    changePhase(projectTimelineId) {
      let _ = this
      _.$swal.fire({
        title: 'You are about to change current phase',
        text: 'Do you really want to change current phase? This process cannot be undone.',
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: 'No, cancel',
        confirmButtonText: 'Yes, sure',
        focusCancel: true
        }).then((result) => {
          if (result.value) {
            _.currentProjectTimelineId = projectTimelineId
            _.oldProjectTimelineId = projectTimelineId

            let searchPhaseId = _.timelines.map( x => x.id ).indexOf(projectTimelineId)
            _.currentPhaseId = _.timelines[searchPhaseId].phase_id

            _.axios.put('/v1/project/' + _.$route.params.id, _.requestBody)
              .then(() => {
                _.get()
                _.$root.$emit('updateProjectDetail')
                _.$swalToast.fire({
                  icon: 'success',
                  title: 'Successfully update project phase',
                  text: `You have successfully update project phase`
                })
              })
              .catch( err => {
                _.$swalToast.fire({
                  icon: 'error',
                  title: `Oops! Something went wrong (${err.response.status})`,
                  text: err.response.data.errors
                })
              })
          } 
          else {
            _.currentProjectTimelineId = _.oldProjectTimelineId
          }
      })
    },
    show() {
      let _ = this
      const data = _.project

      this.currentPhaseId = data.phase_id
      this.currentProjectTimelineId = data.project_timeline_id
      this.oldProjectTimelineId = data.project_timeline_id
    },
  },
  beforeMount() {
    this.show()
  },
  mounted() {
    this.getPhase()
    $(document).ready(function() {
      $('[data-bs-toggle="tooltip"]').tooltip();
    });

    this.get()
  },
}
</script>

<style>
  .col-current-phase {
    left: 0px;
    position: sticky;
  }
  .col-phase {
    left: 100px;
    position: sticky;
  }
  .col-start-date {
    left: 280px;
    position: sticky;
  }
  .col-end-date {
    left: 380px;
    position: sticky;
  }
  .fixed-header {
    z-index: 50;
  }
  th[scope=row], td[scope=row]{
    background-color: var(--bs-body-bg) !important;
  }
</style>