<template>
  <div>
    <div class="d-flex flex-column flex-lg-row">
      <div class="flex-column flex-lg-row-auto w-100 w-lg-200px w-xl-300px mb-10">
        <div class="card card-flush">
          <div class="card-header">
            <div class="card-title">
              <h2 class="mb-0">{{ model.name }}</h2>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="d-flex flex-column text-gray-600">
              <div class="d-flex align-items-center py-2">{{ model.description }}</div>
            </div>
          </div>
          <div class="card-footer pt-0">
            <button type="button" class="btn btn-light btn-active-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_update_role" @click="reset()">Edit Role</button> &nbsp;
            <button type="button" class="btn btn-light btn-active-light-primary my-1" @click="deleteRole()" :disabled="table.data.length > 0">Delete Role</button>
          </div>
        </div>
        <div class="modal fade" id="kt_modal_update_role" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered mw-750px">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="fw-bold">Edit Role</h2>
                <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal">
                  <span class="svg-icon svg-icon-1">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                      <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                    </svg>
                  </span>
                </div>
              </div>
              <div class="modal-body scroll-y mx-lg-5 my-7">
                <ValidationObserver ref="observer">
                  <form class="form" @submit.prevent="updateRole()">
                    <div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_role_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_role_header" data-kt-scroll-wrappers="#kt_modal_add_role_scroll" data-kt-scroll-offset="300px">
                      <div class="fv-row mb-10">
                        <ValidationProvider name="Role name" v-slot="{ errors }" :rules="{ required: true }" ref="name">
                          <label class="fs-5 fw-bold form-label mb-2">
                            <span class="required">Role Name</span>
                          </label>
                          <input class="form-control form-control-solid" placeholder="Enter a role name" v-model="request.name" />
                          <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </div>
                      <div class="fv-row mb-10">
                        <ValidationProvider name="Description" v-slot="{ errors }" :rules="{ required: true }" ref="description">
                          <label class="fs-5 fw-bold form-label mb-2">
                            <span class="required">Description</span>
                          </label>
                          <textarea class="form-control form-control-solid" placeholder="Enter a role description" v-model="request.description" cols="30" rows="5"></textarea>
                          <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </div>
                      <div class="fv-row" v-if="showPermissions">
                        <label class="fs-5 fw-bold form-label mb-2">Role Permissions</label>
                        <div class="table-responsive">
                          <table class="table align-middle table-row-dashed fs-6 gy-5">
                            <tbody class="text-gray-600 fw-semibold">
                              <tr>
                                <td class="text-gray-800" style="white-space: nowrap;">Administrator Access
                                <i class="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Allows a full access to the system"></i></td>
                                <td>
                                  <label class="form-check form-check-custom form-check-solid me-9">
                                    <input class="form-check-input" type="checkbox" value="" id="kt_roles_select_all" @click="selectAll($event)" :checked="isAllChecked"/>
                                    <span class="form-check-label" for="kt_roles_select_all">Select all</span>
                                  </label>
                                </td>
                              </tr>
                              <tr v-for="(permission, index) in permissions" :key="index">
                                <td class="text-gray-800">{{ permission.parentName }}</td>
                                <td>
                                  <div class="d-flex flex-wrap">
                                    <label class="col-6 form-check form-check-sm form-check-custom form-check-solid" 
                                      v-for="(rule, indexRule) in permission.rules" :key="indexRule">
                                      <input class="form-check-input align-self-start" type="checkbox" :value="rule.id" v-model="request.permissions"
                                        :checked="request.permissions.some((obj)=> obj == rule.id)"/>
                                      <span class="form-check-label mb-1 align-self-start">{{ rule.name }}</span>
                                    </label>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="text-center pt-15">
                      <button type="reset" class="btn btn-light me-3" data-bs-dismiss="modal">Discard</button>
                      <button type="submit" class="btn btn-primary">
                        <span class="indicator-label" v-if="!loading">Submit</span>
                        <span class="indicator-progress" v-else>
                          Please wait...
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </button>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-lg-row-fluid ms-lg-10">
        <div class="card card-flush mb-6 mb-xl-9">
          <div class="card-header pt-5">
            <div class="card-title">
              <h2 class="d-flex align-items-center">Users Assigned
              <span class="ms-2 badge badge-light-dark">{{ table.meta.total | numFormat}} </span></h2>
            </div>
            <div class="card-toolbar">
              <div class="d-flex align-items-center position-relative my-1" v-if="selected.length == 0">
                <span class="svg-icon svg-icon-1 position-absolute ms-6">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
                  </svg>
                </span>
                <input type="text" class="form-control form-control-solid w-250px ps-15" placeholder="Search Users" 
                  v-debounce:300.lock="handleSearch" v-model="table.params.search"/>
              </div>
              <div class="d-flex justify-content-end align-items-center my-1" v-else>
                <div class="fw-bold me-5">
                <span class="me-2">{{ selected.length | numFormat }}</span>Selected</div>
                <button type="button" class="btn btn-danger" @click="bulkUnassignUser()">Delete Selected</button>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <Table :table="table">
              <template v-slot:actionButton="{ data }">
                <button class="btn btn-sm btn-light btn-active-light-primary text-nowrap" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                  <span class="svg-icon svg-icon-5 m-0">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                    </svg>
                  </span>
                </button>
                <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                  <div class="menu-item px-3">
                    <router-link :to="'/user-management/user/detail/' + data.id" class="menu-link px-3">
                      View
                    </router-link>
                  </div>
                  <div class="menu-item px-3">
                    <a href="#" class="menu-link px-3" data-kt-roles-table-filter="delete_row" @click="unassignUser(data)">Delete</a>
                  </div>
                </div>
              </template>
            </Table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from '@/components/Table.vue'

export default {
	components: {
    Table
	},
  data() {
    return {
      model: {},
      table: {
        options: {
          columns: [
            { name: 'checkbox', sortable: false, reference: null },
            { name: 'ID', sortable: true, reference: 'updated_at' },
            { name: 'User', sortable: true, reference: 'first_name' },
            { name: 'Joined Date', sortable: true, reference: 'join_date' },
          ],
          rows: [
            { title: null, type: 'checkbox' },
            { title: 'id', type: 'text' },
            {
              title: 'first_name&last_name',
              type: 'text',
              image: 'avatar',
              imageRounded: true,
              subtitle: 'email',
              route: '/user-management/user/detail/',
              routeReference: 'id',
            },
            { title: 'join_date', type: 'date' },
          ],
          actionButton: true
        },
        data: [],
        meta: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
        },
        params: {
          limit: 10,
          sortby: 'id.DESC',
          search: '',
        }
      },
      request: {
        name: null,
        permissions: [],
      },
      selected: [],
      loading: false,
      permissions: [],
      permissionTotal: 0,
      menus: [],
      backupData: null,
      showPermissions: false
    }
  },
  computed: {
    isAllChecked: function() {
      return this.request.permissions.length == this.permissionTotal
    }
  },
  methods: {
    showRole() {
      let _ = this
      _.axios.get(`/v1/role/${_.$route.params.id}`)
        .then( resp => {
          _.backupData = JSON.parse(JSON.stringify(resp.data.data))
          _.model = _.backupData

          _.reset()
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    getUser( page = 1 ) {
      let _ = this
      _.axios.get('/v1/user'
        + '?page=' + page
        + '&limit=' + _.table.params.limit
        + '&sortBy=' + _.table.params.sortby
        + '&search=' + _.table.params.search
        + '&role_id=' + _.$route.params.id
      )
      .then( resp => {
        _.table.data = resp.data.data
        _.table.meta = resp.data.meta
        setTimeout(() => {
          KTMenu.createInstances();
        }, 500);
      })
      .catch( err => {
        _.$swalToast.fire({
          icon: 'error',
          title: `Oops! Something went wrong (${err.response.status})`,
          text: err.response.data.errors
        })
      })
    },
    handleSearch() {
      this.getUser()
    },
    async updateRole() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      _.loading = true
      _.axios.put(`/v1/role/${_.$route.params.id}`, _.request)
        .then(()=> {
          _.loading = false
          _.hideModal()
          _.showRole()
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully edit role',
            text: `You have successfully edit role`
          })
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              _.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
          _.loading = false
        })
    },
    async reset() {
      this.showPermissions = false

      setTimeout(() => {
        let hehe = JSON.parse(JSON.stringify(this.backupData))
        this.request = {
          name: hehe.name,
          description: hehe.description,
          permissions: hehe.permissions
        }

        this.showPermissions = true
      }, 100);
      await this.$refs.observer.reset()
    },
    deleteRole() {
      let _ = this
      _.$swal.fire({
        title: 'You are about to delete this role',
        text: 'Do you really want to delete this role? This process cannot be undone.',
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: 'No, cancel',
        confirmButtonText: 'Yes, sure',
        focusCancel: true
        }).then((result) => {
          if (result.value) {
            _.axios.delete(`/v1/role/${_.$route.params.id}`)
              .then(() => {
                _.$swalToast.fire({
                  icon: 'success',
                  title: 'Successfully delete role',
                  text: 'You have successfully delete this role'
                })

                _.$router.push('/user-management/role-and-permission')
              })
              .catch(err=>{
                _.$swalToast.fire({
                  icon: 'error',
                  title: `Oops! Something went wrong (${err.response.status})`,
                  text: err.response.data.errors
                })
              })
          }
      })
    },
    getPermission() {
      let _ = this
      _.axios.get('/v1/misc/permission')
        .then( resp => {
          const data = resp.data.data
          const groupBy = (x,f)=>x.reduce((a,b,i)=>((a[f(b,i,x)]||=[]).push(b),a),{});
          const groups = (groupBy(data, v => v.menu_id))
          _.permissionTotal = data.length

          Object.keys(groups).forEach(elm => {
            let parentName = ""
            const searchParentName = _.menus.map( x => x.id ).indexOf(parseInt(elm))
            if ( searchParentName != -1 ) {
              parentName = _.menus[searchParentName].name
            }

            _.permissions.push({
              parentName: parentName,
              rules: groups[elm]
            })
          });
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    getMenu() {
      let _ = this
      _.axios.get('/v1/misc/menu')
        .then( resp => {
          const data = resp.data.data
          data.forEach(elm => {
            let hehe = {
              ...elm
            }
            delete hehe.submenu

            _.menus.push(hehe)

            if (elm.submenu.length > 0) {
              elm.submenu.forEach(sub => {
                _.menus.push(sub)
              });
            }
          });

          this.getPermission()
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    selectAll(event){
      let _ = this
      _.request.permissions = []

      if (event.target.checked) {
        _.permissions.forEach(permission => {
          permission.rules.forEach(rule => {
            _.request.permissions.push(rule.id)
          });
        });
      } 
    },
    unassignUser( user ) {
      let _ = this

      _.$swal.fire({
        title: 'You are about to remove user from this role',
        text: 'Do you really want to remove user from this role? This process cannot be undone.',
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: 'No, cancel',
        confirmButtonText: 'Yes, sure',
        focusCancel: true
        }).then((result) => {
          if (result.value) {
            _.axios.put('/v1/user/' + user.id, {
              full_name: user.full_name,
              role_id: null,
              phone_number: user.phone_number,
              address: user.address,
              email: user.email,
              join_date: user.join_date,
              avatar: user.avatar,
              positions: user.positions.map( x => x.position_id )
            })
            .then(() => {
              _.getUser()
              _.$swalToast.fire({
                icon: 'success',
                title: 'Successfully remove user',
                text: `You have successfully remove user from this role`
              })
            })
            .catch( err => {
              _.$swalToast.fire({
                icon: 'error',
                title: `Oops! Something went wrong (${err.response.status})`,
                text: err.response.data.errors
              })
            })
          }
      })
    },
    bulkUnassignUser() {
      let _ = this
      _.$swal.fire({
        title: 'You are about to remove a lot of users from this role',
        text: 'Do you really want to remove those users from this role? This process cannot be undone.',
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: 'No, cancel',
        confirmButtonText: 'Yes, sure',
        focusCancel: true
        }).then((result) => {
          if (result.value) {
            _.axios.put(`/v1/role/${_.$route.params.id}/unassigned`, { selectedIds: _.selected })
              .then(() => {
                _.$swalToast.fire({
                  icon: 'success',
                  title: 'Successfully remove users',
                  text: 'You have successfully remove those users'
                })
                _.selected = []
                _.getUser()
                _.$root.$emit('resetSelected')
              })
              .catch(err=>{
                _.$swalToast.fire({
                  icon: 'error',
                  title: `Oops! Something went wrong (${err.response.status})`,
                  text: err.response.data.errors
                })
              })
          }
      })
    }
  },
  mounted() {
    let _ = this
    _.showRole()
    _.getUser()
    _.getMenu()

    _.$root.$on("changePage", (data) => {
      _.getUser(data)
    });
    _.$root.$on("changeLimit", (data) => {
      _.table.params.limit = data
      _.getUser()
    });
    _.$root.$on("changeSortBy", (data) => {
      if ( data == '.' ) {
        _.table.params.sortby = 'id.desc'
      } else {
        _.table.params.sortby = data
      }
      _.getUser()
    });
    _.$root.$on("changeSelected", (data) => {
      _.selected = data
    });

    setTimeout(() => {
      KTMenu.createInstances();
    }, 500);

    $(document).ready(function() {
      $('textarea').keypress(function(event) {
        if (event.keyCode == 13) {
          event.preventDefault();
        }
      });
    });
  },
  beforeDestroy(){
    this.$root.$off("changePage");
    this.$root.$off("changeLimit");
    this.$root.$off("changeSortBy");
    this.$root.$off("changeSelected");
  },
  watch: {
    limit: function(){
      this.getUser()
    },
    sortby: function(){
      this.getUser()
    },
  }
}
</script>