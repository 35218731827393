import { ValidationProvider, extend } from 'vee-validate';
import { 
  required, 
  max, 
  max_value,
  min_value,
  email,
  min,
  confirmed,
  regex,
  numeric
} from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: '{_field_} is required'
});

extend('min', {
  ...min,
  params: ['length'],
  message: '{_field_} field must be at least {length} characters'
});

extend('max', {
  ...max,
  params: ['length'],
  message: '{_field_} may not be greater than {length} characters'
});

extend('max_value', {
  ...max_value,
  message: '{_field_} field must be {max} or less'
});

extend('min_value', {
  ...min_value,
  message: '{_field_} field must be {min} or more'
});

extend('email', {
  ...email,
  message: '{_field_} field must be a valid email'
});

extend('confirmed', {
  ...confirmed,
  message: '{_field_} field does not match'
});

extend('regex', {
  ...regex,
  message: '{_field_} field format is invalid'
});

extend('numeric', {
  ...numeric,
  message: '{_field_} field may only contain numeric characters'
});

extend('strong_password', {
  message: (fieldName, placeholders) => {
    return `${fieldName} field must contain at least 8 characters, and contain at least 1 letter, 1 number, and 1 symbol`;
  },

  validate(value, args) {
    return (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/).test(value)
  },
});

export default ValidationProvider