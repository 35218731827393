<template>
  <div>
    <div class="card mb-5 mb-xl-10">
      <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
        <div class="card-title m-0">
          <h3 class="fw-bold m-0">Project Settings</h3>
        </div>
      </div>
      <div id="kt_account_settings_profile_details" class="collapse show">
        <ValidationObserver ref="observer">
          <form class="form" @submit.prevent="updateProject()">
            <div class="card-body border-top p-9">
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label fw-semibold fs-6">Logo</label>
                <div class="col-lg-8">
                  <ValidationProvider name="Logo" v-slot="{ errors }" :rules="{ }" ref="logo">
                    <div class="image-input image-input-outline image-input-placeholder" data-kt-image-input="true">
                      <div class="image-input-wrapper w-125px h-125px" :style="`background-image: url(${request.logo ? $apiBaseUrl + '/storage/' + request.logo : '/assets/media/misc/image.png'}); background-position: center;`"></div>
                      <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change logo">
                        <i class="bi bi-pencil-fill fs-7"></i>
                        <input type="file" class="hidden" id="upload" v-on:change="handleUpload" :accept="'.' + allowedExtension.join(', .').toLowerCase()">
                        <input type="hidden" name="avatar_remove" />
                      </label>
                      <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove logo" 
                        v-if="request.logo" @click="request.logo = null">
                        <i class="bi bi-x fs-2"></i>
                      </span>
                    </div>
                    <div class="form-text">Maximum file size: {{ maxFileSize }} Mb. ({{ '.' + allowedExtension.join(' .').toUpperCase() }}).</div>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-semibold fs-6">Client</label>
                <div class="col-lg-8 fv-row">
                  <ValidationProvider name="client" v-slot="{ errors }" :rules="{ required: true }" ref="client_id">
                    <multiselect 
                      track-by="id"
                      label="name"
                      placeholder="Select Client"
                      v-model="request.client_id" 
                      :options="clients"
                      :allow-empty="false"
                      :close-on-select="true"
                      :multiple="false"
                      :searchable="true"
                      :hide-selected="false"
                      :showLabels="false"
                    ></multiselect>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-semibold fs-6">Project Name</label>
                <div class="col-lg-8 fv-row">
                  <ValidationProvider name="Project name" v-slot="{ errors }" :rules="{ required: true }" ref="name">
                    <input type="text" class="form-control form-control-lg form-control-solid" v-model="request.name" />
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-semibold fs-6">Project Overview</label>
                <div class="col-lg-8 fv-row">
                  <ValidationProvider name="Project name" v-slot="{ errors }" :rules="{ required: true }" ref="description">
                    <textarea class="form-control form-control-lg form-control-solid" placeholder="Describe your project" value="" cols="30" rows="5" v-model="request.description"></textarea>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-semibold fs-6">Categories</label>
                <div class="col-lg-8 fv-row">
                  <ValidationProvider name="Categories" v-slot="{ errors }" :rules="{ required: true }" ref="tags">
                    <multiselect 
                      track-by="id"
                      label="title"
                      placeholder="Select Categories"
                      v-model="request.tags" 
                      :options="categories"
                      :allow-empty="false"
                      :close-on-select="true"
                      :multiple="true"
                      :searchable="true"
                      :hide-selected="false"
                      :showLabels="false"
                    ></multiselect>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-semibold fs-6">Frameworks</label>
                <div class="col-lg-8 fv-row">
                  <ValidationProvider name="Frameworks" v-slot="{ errors }" :rules="{ required: true }" ref="technologies">
                    <multiselect 
                      track-by="id"
                      label="title"
                      placeholder="Select Technologies"
                      v-model="request.technologies" 
                      :options="frameworks"
                      :allow-empty="false"
                      :close-on-select="true"
                      :multiple="true"
                      :searchable="true"
                      :hide-selected="false"
                      :showLabels="false"
                    ></multiselect>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-semibold fs-6">Members</label>
                <div class="col-lg-8 fv-row">
                  <div class="fv-row" v-for="(member, index) in request.members" :key="index">
                    <div class="row">
                      <div class="col fv-row fv-plugins-icon-container">
                        <ValidationProvider :name="'Member ' + (index+1)" v-slot="{ errors }" :rules="{ required: true }" ref="members">
                          <multiselect 
                            track-by="id"
                            label="full_name"
                            placeholder="Select user"
                            v-model="member.user_id" 
                            :options="users.filter( x => selectedUsers.indexOf(x.id) == -1  || x.id == member.user_id )"
                            :close-on-select="true"
                            :multiple="false"
                            :searchable="true"
                            :hide-selected="true"
                            :showLabels="false"
                            @input="member.position_id = null"
                          ></multiselect>
                          <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </div>
                      <div class="col fv-row fv-plugins-icon-container">
                        <ValidationProvider :name="'Member ' + (index+1) + ' position'" v-slot="{ errors }" :rules="{ required: true }" ref="members" v-if="member.user_id">
                          <multiselect 
                            track-by="id"
                            label="title"
                            placeholder="Select positions"
                            v-model="member.position_id" 
                            :options="positions.filter( x => member.user_id.positions.map( z => z.position_id ).includes(x.id) )"
                            :allow-empty="false"
                            :close-on-select="false"
                            :multiple="true"
                            :searchable="true"
                            :hide-selected="true"
                            :showLabels="false"
                          ></multiselect>
                          <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </div>
                      <button type="button" class="btn btn-icon btn-light btn-active-light-primary align-self-start me-3" @click="removeMember(index)" v-if="request.members.length != 1">
                        <span class="svg-icon svg-icon-3">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path>
                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path>
                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path>
                          </svg>
                        </span>
                      </button>
                    </div>
                    <div class="separator separator-dashed my-4" v-if="index != (request.members.length-1)"></div>
                  </div>
                  <div class="d-flex justify-content-end">
                    <button type="button" class="btn btn-light btn-active-light-primary align-self-center mt-4" title="Add more member" @click="addMoreMember()">
                      <span class="svg-icon svg-icon-3">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M3 13V11C3 10.4 3.4 10 4 10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14H4C3.4 14 3 13.6 3 13Z" fill="currentColor"/>
                          <path d="M13 21H11C10.4 21 10 20.6 10 20V4C10 3.4 10.4 3 11 3H13C13.6 3 14 3.4 14 4V20C14 20.6 13.6 21 13 21Z" fill="currentColor"/>
                        </svg>
                      </span>
                      Add More Member
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mb-6">
                <label class="col-lg-4 col-form-label required fw-semibold fs-6">Status</label>
                <div class="col-lg-8 fv-row">
                  <ValidationProvider name="status" v-slot="{ errors }" :rules="{ required: true }" ref="status">
                    <multiselect 
                      placeholder="Select Project Status"
                      v-model="request.status" 
                      :options="[
                        'Not Yet Started',
                        'In Progress',
                        'On Hold',
                        'Support',
                        'Completed',
                      ]"
                      :allow-empty="false"
                      :close-on-select="true"
                      :multiple="false"
                      :searchable="true"
                      :showLabels="false"
                    ></multiselect>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <button class="btn btn-light btn-active-light-primary me-2" @click="$router.push(`/project-management/project/detail/${$route.params.id}/overview`)">Discard</button>
              <button class="btn btn-danger me-2" type="button" @click="deleteProject()">Delete</button>
              <button type="submit" class="btn btn-primary">
                <span class="indicator-label" v-if="!loading">Save Changes</span>
                <span class="indicator-progress" v-else>
                  Please wait...
                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      frameworks: [],
      categories: [],
      clients: [],
      step: 1,
      request: {
        name: null,
        description: null,
        client_id: null,
        technologies: [],
        tags: [],
        members: [],
        logo: null,
      },
      positions: [],
      users: [],
      loading: false,
      maxFileSize: 2,
      allowedExtension: ['jpg', 'jpeg', 'png'],
    }
  },
  computed: {
    selectedUsers: function() {
      return this.request.members.map( x => x.user_id ? x.user_id.id : null ).filter( x => x != null)
    },
    requestBody: function() {
      let _ = this
      return {
        logo: _.request.logo,
        name: _.request.name,
        description: _.request.description,
        client_id: _.request.client_id.id,
        technologies: _.request.technologies.map( x => x.id ),
        tags: _.request.tags.map( x => x.id ),
        members: _.request.members.map( function(x) {
            return {
              user_id: x.user_id ? x.user_id.id : null,
              position_id: x.position_id ? x.position_id.map( z => z.id ) : null
            }
          }).filter( x => x.user_id != null ),
        status: _.request.status,
        project_timeline_id: _.request.project_timeline_id,
        phase_id: _.request.phase_id,
      }
    }
  },
  methods: {
    async handleUpload(event){
      let _ = this
      let file = event.target.files[0]

      const uploadedFile = await _.upload( file, _.allowedExtension, _.maxFileSize, 'logo' )

      if ( uploadedFile ) {
        _.request.logo = uploadedFile
      }
    },
    getFramework() {
      let _ = this
      _.axios.get('/v1/technology')
        .then( resp => {
          _.frameworks = resp.data.data
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    getCategory() {
      let _ = this
      _.axios.get('/v1/tag')
        .then( resp => {
          _.categories = resp.data.data

          setTimeout(() => {
            KTScroll.createInstances();
          }, 100);
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    getClient() {
      let _ = this
      _.axios.get('/v1/client')
        .then( resp => {
          _.clients = resp.data.data
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    getPosition() {
      let _ = this
      _.axios.get('/v1/position')
        .then( resp => {
          _.positions = resp.data.data
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    getUser() {
      let _ = this
      _.axios.get('/v1/user')
        .then( resp => {
          _.users = resp.data.data

          _.show()
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    removeCategory( categoryId ) {
      let _ = this
      const searchCategory = _.request.tags.map( x => x.id ).indexOf( categoryId )
      if ( searchCategory != -1 ) {
        _.request.tags.splice(searchCategory, 1)
      }
    },
    removeFramework( frameworkId ) {
      let _ = this
      const searchFramework = _.request.technologies.map( x => x.id ).indexOf( frameworkId )
      if ( searchFramework != -1 ) {
        _.request.technologies.splice(searchFramework, 1)
      }
    },
    addMoreMember() {
      this.request.members.push({
        user_id: null,
        position_id: null
      })
    },
    removeMember(index) {
      this.request.members.splice(index, 1)
    },
    async reset() {
      this.request = {
        name: null,
        description: null,
        client_id: null,
        technologies: [],
        tags: [],
        members: [],
        logo: null
      }
      this.request.members.push({
        user_id: null,
        position_id: []
      })

      this.step = 1
      await this.$refs['observer-step-1'].reset()
    },
    async updateProject() {
      let _ = this

      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      _.loading = true
      _.axios.put('/v1/project/' + _.$route.params.id, _.requestBody)
        .then(() => {
          _.loading = false
          _.show()
          _.$root.$emit('updateProjectDetail')
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully update project',
            text: `You have successfully update project`
          })
        })
        .catch( err => {
          if ( typeof err.response.data.errors === 'object' && err.response.data.errors !== null ) {
            Object.keys(err.response.data.errors).forEach(elm => {
              _.$refs[elm].applyResult({
                errors: [err.response.data.errors[elm][0]],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
          _.loading = false
        })
    }, 
    getUsersPositions(user) {
      const searchUser = this.users.map( x => x.id ).indexOf( user.id )
      if (searchUser != -1) {
        return this.users[searchUser].positions.filter( x => x.position ).map( x => x.position )
      }
    },
    show() {
      let _ = this
      _.axios.get('/v1/project/' + _.$route.params.id)
      .then( resp => {
        const data = resp.data.data

        let members = []
        data.project_members.forEach(project_member => {
          const userDetail = {
            ...project_member.user,
            positions: _.getUsersPositions(project_member.user)
          }
          members.push({
            user_id: userDetail,
            position_id: project_member.member_positions.map( x => x.position )
          })
        });

        this.request = {
          name: data.name,
          description: data.description,
          client_id: data.client,
          technologies: data.project_technologies.map( x => x.technology ),
          tags: data.project_tags.map( x => x.tag ),
          members: members,
          logo: data.logo,
          project_timeline_id: data.project_timeline_id,
          phase_id: data.phase_id,
          status: data.status,
        }
      })
      .catch( err => {
        _.$swalToast.fire({
          icon: 'error',
          title: `Oops! Something went wrong (${err.response.status})`,
          text: err.response.data.errors
        })
      })
    },
    deleteProject() {
      let _ = this
      _.hideModal()

      _.$swal.fire({
        title: 'You are about to delete this project',
        text: 'Do you really want to delete this project? This process cannot be undone.',
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: 'No, cancel',
        confirmButtonText: 'Yes, sure',
        focusCancel: true
        }).then((result) => {
          if (result.value) {
            _.axios.delete(`/v1/project/${_.$route.params.id}`)
              .then(() => {
                _.$swalToast.fire({
                  icon: 'success',
                  title: 'Successfully delete project',
                  text: 'You have successfully delete this project'
                })
                _.$root.$emit('updateProject')

                _.$router.push('/project-management/project')
              })
              .catch(err=>{
                _.$swalToast.fire({
                  icon: 'error',
                  title: `Oops! Something went wrong (${err.response.status})`,
                  text: err.response.data.errors
                })
              })
          }
      })
    },
  },
  async mounted() {
    let _ = this

    _.getFramework()
    _.getCategory()
    _.getClient()
    _.getPosition()
    _.getUser()

    
    $(document).ready(function() {
      $('textarea').keypress(function(event) {
        if (event.keyCode == 13) {
          event.preventDefault();
        }
      });
    });
  },
}
</script>