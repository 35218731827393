<template>
  <div>
    <div class="d-flex flex-column flex-lg-row">
      <div class="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10">
        <div class="card mb-5 mb-xl-8 w-lg-250px w-xl-350px">
          <div class="card-body">
            <div class="d-flex flex-center flex-column py-5">
              <div class="symbol symbol-100px symbol-circle mb-7">
                <img :src="model.avatar ? model.avatar : '/assets/media/avatars/blank.png'" alt="image" referrerpolicy="no-referrer" style="object-fit:cover;" />
              </div>
              <a href="#" class="fs-3 text-gray-800 text-hover-primary fw-bold mb-3">{{ model.first_name }} {{ model.last_name }}</a>
              <div class="mb-9" v-if="model.role">
                <div class="badge badge-lg badge-light-primary d-inline">{{ model.role.name }}</div>
              </div>
              <!-- <div class="fw-bold mb-3">Assigned Projects
              <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-html="true" data-bs-content="Number of support tickets assigned, closed and pending this week."></i></div>
              <div class="d-flex flex-wrap flex-center">
                <div class="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                  <div class="fs-4 fw-bold text-gray-700">
                    <span class="w-75px">243</span>
                    <span class="svg-icon svg-icon-3 svg-icon-success">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                        <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
                      </svg>
                    </span>
                  </div>
                  <div class="fw-semibold text-muted">Total</div>
                </div>
                <div class="border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3">
                  <div class="fs-4 fw-bold text-gray-700">
                    <span class="w-50px">56</span>
                    <span class="svg-icon svg-icon-3 svg-icon-danger">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor" />
                        <path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="currentColor" />
                      </svg>
                    </span>
                  </div>
                  <div class="fw-semibold text-muted">Solved</div>
                </div>
                <div class="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                  <div class="fs-4 fw-bold text-gray-700">
                    <span class="w-50px">188</span>
                    <span class="svg-icon svg-icon-3 svg-icon-success">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="currentColor" />
                        <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="currentColor" />
                      </svg>
                    </span>
                  </div>
                  <div class="fw-semibold text-muted">Open</div>
                </div>
              </div> -->
            </div>
            <div class="d-flex flex-stack fs-4 py-3">
              <div class="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#kt_user_view_details" role="button" aria-expanded="false" aria-controls="kt_user_view_details">Details
              <span class="ms-2 rotate-180">
                <span class="svg-icon svg-icon-3">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                  </svg>
                </span>
              </span></div>
              <span data-bs-toggle="tooltip" data-bs-trigger="hover" title="Edit user details">
                <a href="#" class="btn btn-sm btn-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_update_details" @click="reset()">Edit</a>
              </span>
            </div>
            <div class="separator"></div>
            <div id="kt_user_view_details" class="collapse show">
              <div class="pb-5 fs-6">
                <div class="fw-bold mt-5">NIK</div>
                <div class="text-gray-600">{{ model.nik ? model.nik : '-' }}</div>
                <div class="fw-bold mt-5">Email</div>
                <div class="text-gray-600">
                  <a :href="'mailto:' + model.email" class="text-gray-600 text-hover-primary">{{ model.email }}</a>
                </div>
                <div class="fw-bold mt-5">Phone Number</div>
                <div class="text-gray-600">
                  <a :href="'tel:' + model.phone_number" class="text-gray-600 text-hover-primary">{{ model.phone_number ? model.phone_number : '-' }}</a>
                </div>
                <div class="fw-bold mt-5">Address</div>
                <div class="text-gray-600">{{ model.address ? model.address : '-' }}</div>
                <div class="fw-bold mt-5">Positions</div>
                <div class="text-gray-600">{{ model.positions.length > 0 ? model.positions.map( z => z.position ? z.position.title : '' ).join(', ') : '-' }}</div>
                <div class="fw-bold mt-5">Joined Date</div>
                <div class="text-gray-600" v-if="model.join_date">{{ model.join_date | moment('DD MMM YYYY') }}</div>
                <div class="text-gray-600" v-else>-</div>
                <div class="fw-bold mt-5">Last Active</div>
                <div class="text-gray-600" v-if="model.tokens">{{ model.tokens.last_used_at | moment('DD MMM YYYY, hh:mm a') }}</div>
                <div class="text-gray-600" v-else>-</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-lg-row-fluid ms-lg-15">
        <ul class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8">
          <li class="nav-item">
            <a class="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#kt_user_view_overview_tab">Overview</a>
          </li>
          <li class="nav-item ms-auto"  v-if="$route.params.id != loggedUser.id">
            <a href="#" class="btn btn-primary ps-7" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">Actions
            <span class="svg-icon svg-icon-2 me-0">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
              </svg>
            </span></a>
            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold py-4 w-250px fs-6" data-kt-menu="true">
              <div class="menu-item px-5">
                <a href="#" class="menu-link text-danger px-5" @click="deleteUser()">Delete User</a>
              </div>
            </div>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="kt_user_view_overview_tab" role="tabpanel">
							<div class="row g-6 g-xl-9">
								<div class="col-lg-6 col-xxl-4">
									<div class="card h-100">
										<div class="card-body p-9">
											<!-- <div class="fs-2hx fw-bold">{{ table.meta.total | numFormat }}</div>
											<div class="fs-4 fw-semibold text-gray-400 mb-7">Current Projects</div>
											<div class="d-flex flex-wrap">
												<div class="d-flex flex-center h-100px w-100px me-9 mb-5">
													<canvas id="kt_project_list_chart"></canvas>
												</div>
												<div class="d-flex flex-column justify-content-center flex-row-fluid mb-5">
													<div class="d-flex fs-6 fw-semibold align-items-center mb-3">
                            <div class="bullet bg-secondary me-3"></div>
                            <div class="text-gray-400">Not Yet Started</div>
                            <div class="ms-auto fw-bold text-gray-700">{{ 99 | numFormat }}</div>
                          </div>
                          <div class="d-flex fs-6 fw-semibold align-items-center mb-3">
                            <div class="bullet bg-info me-3"></div>
                            <div class="text-gray-400">In Progress</div>
                            <div class="ms-auto fw-bold text-gray-700">{{ 99 | numFormat }}</div>
                          </div>
                          <div class="d-flex fs-6 fw-semibold align-items-center mb-3">
                            <div class="bullet bg-warning me-3"></div>
                            <div class="text-gray-400">On Hold</div>
                            <div class="ms-auto fw-bold text-gray-700">{{ 99 | numFormat }}</div>
                          </div>
                          <div class="d-flex fs-6 fw-semibold align-items-center mb-3">
                            <div class="bullet bg-success me-3"></div>
                            <div class="text-gray-400">Support</div>
                            <div class="ms-auto fw-bold text-gray-700">{{ 99 | numFormat }}</div>
                          </div>
                          <div class="d-flex fs-6 fw-semibold align-items-center mb-3">
                            <div class="bullet bg-primary me-3"></div>
                            <div class="text-gray-400">Completed</div>
                            <div class="ms-auto fw-bold text-gray-700">{{ 99 | numFormat }}</div>
                          </div>
												</div>
											</div> -->
                      <div class="fs-4 fw-semibold text-gray-400 mb-7">Current Projects</div>
                      <div class="d-flex flex-column flex-center">
                        <img src="/assets/media/illustrations/sigma-1/9.png" class="img-fluid theme-light-show col-5">
                        <img src="/assets/media/illustrations/sigma-1/9-dark.png" class="img-fluid theme-dark-show col-5">
                        <div class="fs-1 fw-bolder text-dark mb-4">Under Construction.</div>
                        <div class="fs-6">We are currently working on this page</div>
                      </div>
										</div>
									</div>
								</div>
								<div class="col-lg-6 col-xxl-8">
									<div class="card h-100">
										<div class="card-body p-9">
											<div class="fs-4 fw-semibold text-gray-400 mb-7">Project Dominance</div>
                      <div class="d-flex flex-column flex-center">
                        <img src="/assets/media/illustrations/sigma-1/9.png" class="img-fluid theme-light-show col-5">
                        <img src="/assets/media/illustrations/sigma-1/9-dark.png" class="img-fluid theme-dark-show col-5">
                        <div class="fs-1 fw-bolder text-dark mb-4">Under Construction.</div>
                        <div class="fs-6">We are currently working on this page</div>
                      </div>
										</div>
									</div>
								</div>
							</div>
							<div class="d-flex flex-wrap flex-stack my-5">
								<h2 class="fs-2 fw-semibold my-2">Projects
								  <!-- <span class="fs-6 text-gray-400 ms-1">by Status</span> -->
                </h2>
								<!-- <div class="d-flex flex-wrap my-1">
									<div class="m-0">
										<select name="status" data-control="select2" data-hide-search="true" class="form-select form-select-sm bg-body border-body fw-bold w-125px">
											<option value="Active" selected="selected">Active</option>
											<option value="Approved">In Progress</option>
											<option value="Declined">To Do</option>
											<option value="In Progress">Completed</option>
										</select>
									</div>
								</div> -->
							</div>
							<div class="row g-6 g-xl-9">
								<div class="col-md-6 col-xl-6" v-for="data in table.data" :key="data.id">
									<ProjectCard :data="data" />
								</div>
                <div class="d-flex flex-column flex-center" v-if="table.data.length == 0">
                  <img src="/assets/media/illustrations/sigma-1/21.png" class="mw-400px">
                  <div class="fs-1 fw-bolder text-dark mb-4">No projects found.</div>
                  <!-- <div class="fs-6">Start creating new projects!</div> -->
                </div>
                <p class="text-center text-sm" v-if="table.meta.current_page < table.meta.last_page">
                  <a class="color-gray-100 cursor-pointer" @click="changePage( table.meta.current_page + 1 )">
                    Show more <span class="fa fa-arrow-down"></span>
                  </a>
                </p>
							</div>
          </div> 
        </div>
      </div>
    </div>
    <div class="modal fade" id="kt_modal_update_details" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
          <div class="modal-header" id="kt_modal_add_user_header">
            <h2 class="fw-bold">Edit User</h2>
            <div class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal">
              <span class="svg-icon svg-icon-1">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                </svg>
              </span>
            </div>
          </div>
          <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
            <ValidationObserver ref="observer">
              <form class="form" @submit.prevent="updateUser()">
                <div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_add_user_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_user_header" data-kt-scroll-wrappers="#kt_modal_add_user_scroll" data-kt-scroll-offset="300px">
                  <div class="fv-row mb-7">
                    <ValidationProvider name="Avatar" v-slot="{ errors }" :rules="{ }" ref="avatar">
                      <label class="d-block fw-semibold fs-6 mb-5">Avatar</label>
                      <div class="image-input image-input-outline image-input-placeholder" data-kt-image-input="true">
                        <div class="image-input-wrapper w-125px h-125px" :style="`background-image: url(${request.avatar ? request.avatar : '/assets/media/avatars/blank.png'}); object-fit:cover; background-position:center;`"></div>
                        <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change image">
                          <i class="bi bi-pencil-fill fs-7"></i>
                          <input type="file" class="hidden" id="upload" v-on:change="handleUpload" :accept="'.' + allowedExtension.join(', .').toLowerCase()">
                          <input type="hidden" name="avatar_remove" />
                        </label>
                        <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove image" 
                          v-if="request.avatar" @click="request.avatar = null">
                          <i class="bi bi-x fs-2"></i>
                        </span>
                      </div>
                      <div class="form-text">Maximum file size: {{ maxFileSize }} Mb. ({{ '.' + allowedExtension.join(' .').toUpperCase() }}).</div>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row mb-7">
                    <ValidationProvider name="NIK" v-slot="{ errors }" :rules="{ }" ref="nik">
                      <label class="fw-semibold fs-6 mb-2">NIK</label>
                      <input type="text" name="user_name" class="form-control form-control-solid mb-3 mb-lg-0" placeholder="NIK" v-model="request.nik" @keypress="numbersOnly($event)"/>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row mb-7">
                    <ValidationProvider name="Full name" v-slot="{ errors }" :rules="{ required: true }" ref="full_name">
                      <label class="required fw-semibold fs-6 mb-2">Full Name</label>
                      <input type="text" name="user_name" class="form-control form-control-solid mb-3 mb-lg-0" placeholder="Full name" v-model="request.full_name" />
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row mb-7">
                    <ValidationProvider name="Email" v-slot="{ errors }" :rules="{ required: true, email: true }" ref="email">
                      <label class="required fw-semibold fs-6 mb-2">Email</label>
                      <input type="email" name="user_email" class="form-control form-control-solid mb-3 mb-lg-0" placeholder="Email" v-model="request.email"/>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row mb-7">
                    <ValidationProvider name="Phone number" v-slot="{ errors }" :rules="{ required: true, min: 8, max: 15 }" ref="phone_number">
                      <label class="required fw-semibold fs-6 mb-2">Phone Number</label>
                      <input type="text" name="user_phone" class="form-control form-control-solid mb-3 mb-lg-0" placeholder="Phone number" v-model="request.phone_number"  @keypress="numbersOnly($event)"/>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row mb-7">
                    <ValidationProvider name="Address" v-slot="{ errors }" :rules="{ }" ref="address">
                      <label class="fw-semibold fs-6 mb-2">Address</label>
                      <textarea class="form-control form-control-solid mb-3 mb-lg-0" cols="30" rows="5" v-model="request.address" placeholder="Address details"></textarea>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="fv-row mb-7">
                    <ValidationProvider name="Joined date" v-slot="{ errors }" :rules="{ required: true }" ref="join_date">
                      <label class="required fw-semibold fs-6 mb-2">Joined Date</label>
                      <!-- <input class="form-control form-control-solid" placeholder="Pick a date" id="kt_daterangepicker_3" v-model="request.join_date"/> -->
											<div style="position:relative">
                        <date-picker class="form-control form-control-solid" v-model="request.join_date" :config="joinDatepickerOptions" placeholder="Pick a date"></date-picker>
                      </div>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </div>
                  <div class="mb-7">
                    <ValidationProvider name="Role" v-slot="{ errors }" :rules="{ required: true }" ref="role_id">
                      <label class="required fw-semibold fs-6 mb-5">Role</label>
                      <div v-for="(role, index) in roles" :key="index">
                        <div class="d-flex fv-row">
                          <div class="form-check form-check-custom form-check-solid">
                            <input class="form-check-input me-3" name="user_role" type="radio" :value="role.id" :id="'kt_modal_update_role_option_' + index" v-model="request.role_id"/>
                            <label class="form-check-label" :for="'kt_modal_update_role_option_' + index">
                              <div class="fw-bold text-gray-800">{{ role.name }}</div>
                              <div class="text-gray-600">{{ role.description }}</div>
                            </label>
                          </div>
                        </div>
                        <div class='separator separator-dashed my-5' v-if="index != roles.length-1"></div>
                      </div>
                      <div class="mt-3" v-if="errors[0]">
                        <small class="text-danger text-sm text-xs">{{ errors[0] }}</small>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="mb-7">
                    <label class="fw-semibold fs-6 mb-5">Position</label>
                    <div v-for="(position, index) in positions" :key="index">
                      <div class="d-flex fv-row">
                        <div class="form-check form-check-custom form-check-solid">
                          <input class="form-check-input me-3" name="user_role" type="checkbox" :value="position.id" :id="'kt_modal_update_position_option_' + index" v-model="request.positions"/>
                          <label class="form-check-label" :for="'kt_modal_update_position_option_' + index">
                            <div class="fw-bold text-gray-800">{{ position.title }}</div>
                            <div class="text-gray-600">{{ position.description }}</div>
                          </label>
                        </div>
                      </div>
                      <div class='separator separator-dashed my-5' v-if="index != positions.length-1"></div>
                    </div>
                  </div>
                </div>
                <div class="text-center pt-15">
                  <button type="reset" class="btn btn-light me-3" data-bs-dismiss="modal">Discard</button>
                  <button type="submit" class="btn btn-primary">
                    <span class="indicator-label" v-if="!loading">Submit</span>
                    <span class="indicator-progress" v-else>
                      Please wait...
                      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProjectCard from '@/components/ProjectCard.vue'

export default {
	components: {
		ProjectCard
	},
  data() {
    return {
      model: {
        positions: []
      },
      roles: [],
      positions: [],
      request: {
        full_name: null,
        role_id: null,
        phone_number: null,
        positions: [],
        address: null,
        email: null,
        // password: null,
        // password_confirmation: null,
        join_date: null,
        // skill: null,
        avatar: null,
        nik: null
      },
      loading: false,
      maxFileSize: 2,
      allowedExtension: ['jpg', 'jpeg', 'png'],
			joinDatepickerOptions: {
        maxDate: this.$moment().format('YYYY-MM-DD'),
        format: 'YYYY-MM-DD',
        useCurrent: true,
      },
			table: {
        data: [],
        meta: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
					total: 0,
        },
        params: {
          limit: 10,
          sortby: 'id.DESC',
        }
      },
      loggedUser: JSON.parse(localStorage.getItem('user'))
    }
  },
  computed: {
    requestBody: function() {
      let _ = this
      return {
        full_name: _.request.full_name,
        role_id: _.request.role_id,
        phone_number: _.request.phone_number,
        positions: _.request.positions,
        address: _.request.address,
        email: _.request.email,
        join_date: _.request.join_date,
        avatar: _.request.avatar,
        nik: _.request.nik,
      }
    }
  },
  methods: {
    show() {
      let _ = this
      _.axios.get('/v1/user/' + _.$route.params.id)
      .then( resp => {
        _.model = resp.data.data
        _.request = {
          ..._.model,
          full_name: _.model.full_name,
          positions: _.model.positions.map(x => x.id)
        }
      })
      .catch( err => {
        _.$swalToast.fire({
          icon: 'error',
          title: `Oops! Something went wrong (${err.response.status})`,
          text: err.response.data.errors
        })
      })
    },
    async handleUpload(event){
      let _ = this
      let file = event.target.files[0]
      const isUploaded = await _.upload( file, _.allowedExtension, _.maxFileSize, 'avatar' )

      if (isUploaded) {
        _.request.avatar = _.$apiBaseUrl + '/storage/' + isUploaded
      }
    },
    getRole() {
      let _ = this
      _.axios.get('/v1/role')
        .then( resp => {
          _.roles = resp.data.data
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    getPosition() {
      let _ = this
      _.axios.get('/v1/position')
        .then( resp => {
          _.positions = resp.data.data
        })
        .catch( err => {
          _.$swalToast.fire({
            icon: 'error',
            title: `Oops! Something went wrong (${err.response.status})`,
            text: err.response.data.errors
          })
        })
    },
    async updateUser() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      _.loading = true
      _.axios.put('/v1/user/' + _.$route.params.id, _.requestBody)
        .then(() => {
          _.loading = false
          _.hideModal()
          _.show()
          _.$swalToast.fire({
            icon: 'success',
            title: 'Successfully edit user',
            text: `You have successfully edit user`
          })
          _.$root.$emit('updateContact')
        })
        .catch( err => {
          if ( typeof err.response.data.errors === 'object' && err.response.data.errors !== null ) {
            Object.keys(err.response.data.errors).forEach(elm => {
              _.$refs[elm].applyResult({
                errors: [err.response.data.errors[elm][0]],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.$swalToast.fire({
              icon: 'error',
              title: `Oops! Something went wrong (${err.response.status})`,
              text: err.response.data.errors
            })
          }
          _.loading = false
        })
    },
    deleteUser() {
      let _ = this
      _.$swal.fire({
        title: 'You are about to delete this user',
        text: 'Do you really want to delete this user? This process cannot be undone.',
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: 'No, cancel',
        confirmButtonText: 'Yes, sure',
        focusCancel: true
        }).then((result) => {
          if (result.value) {
            _.axios.delete(`/v1/user/${_.$route.params.id}`)
              .then(() => {
                _.$swalToast.fire({
                  icon: 'success',
                  title: 'Successfully delete user',
                  text: 'You have successfully delete this user'
                })
                
                _.$root.$emit('updateContact')
                _.$router.push('/user-management/user')
              })
              .catch(err=>{
                _.$swalToast.fire({
                  icon: 'error',
                  title: `Oops! Something went wrong (${err.response.status})`,
                  text: err.response.data.errors
                })
              })
          }
      })
    },
    async reset() {
      let _ = this
      $('#kt_modal_add_user_scroll').animate({ scrollTop: 0 }, 'slow');
      
      _.request = {
        ..._.model,
        full_name: _.model.first_name + ' ' + _.model.last_name,
        positions: _.model.positions.map(x => x.position_id)
      }
      await this.$refs.observer.reset()
    },
		getProject( page = 1 ) {
      let _ = this
      _.axios.get('/v1/project'
        + '?page=' + page
        + '&limit=' + _.table.params.limit
        + '&sortBy=' + _.table.params.sortby
        + '&user_id=' + _.$route.params.id
      )
      .then( resp => {
        _.table.data = resp.data.data
        _.table.meta = resp.data.meta
      })
      .catch( err => {
        _.$swalToast.fire({
          icon: 'error',
          title: `Oops! Something went wrong (${err.response.status})`,
          text: err.response.data.errors
        })
      })
    },
    changePage(page) {
      let _ = this
			if (page > _.table.meta.last_page) {
				page = _.table.meta.last_page;
			}
			_.table.meta.current_page = page;
			_.getProject(page)
		}
  },
  mounted() {
    let _ = this
    _.hideModal()
    _.show()
    _.getRole()
    _.getPosition()
		_.getProject()

    setTimeout(() => {
      KTMenu.createInstances();
      KTScroll.createInstances();
    }, 500);

    $(function () {
      $('[data-bs-toggle="popover"]').popover()
    })
  },
}
</script>